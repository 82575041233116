// Packages
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

// Redux

// Components
import {
  KiteAlert,
  KiteButton,
  KiteCard,
  KiteIcon,
  KiteLoader,
  KiteModal,
  KiteTabs,
} from '@kite/react-kite';
import { SortableTable, Toast } from '@kite/react-kite-plus';
import {
  AddTnModal,
  AutobuildPill,
  ButtonLink,
  EstimateLocationPanel,
  RateCenterSearchModal,
  SalesTeamContainer,
  UcProgressButton,
  RcProgressButton,
  ENEProgressButton,
  CloneEstimateModal,
} from 'components';

// Utils
import {
  TableCategoryIconMap,
  isEstimateAutobuild,
  parseSfAddressData,
  getSfOppById,
  getSfLocations,
  initSalesforceLogin,
  formatTeamMember,
  env,
} from 'utils';

// Hooks
import {
  useAnalytics,
  useArchiveEstimate,
  useGenerateProductTotals,
  useGetActiveDirectory,
  useGetEstimateDetails,
  useGetEstimateScenarios,
  useLocalStorage,
  useQueryData,
  useUpdateEstimate,
  useGetSfInfo,
  useTrackOpenState,
  useTrackNavClick,
  useGetSubmissions,
} from 'hooks';

// Types
import { AxiosError } from 'axios';
import {
  ISaleforceLocalStorage,
  ISalesforceOpportunity,
  ISalesforceLocation,
} from 'types/Salesforce';
import {
  IEstimateUpdate,
  ILocation,
  IScenario,
  TTableCategory,
  TToast,
} from 'types';

// Styles
import './EstimateDetailPage.scss';
import MNEProgressButton from 'components/MneProgressButton';

const EstimateDetailPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  // Hooks Data
  const { trackPageView, trackSelectAction } = useAnalytics();
  const { trackNavClick } = useTrackNavClick();
  const { trackOpenState } = useTrackOpenState();
  const { estimateId = '' } = useParams();
  const { pathname } = useLocation();
  const {
    isAdmin,
    scenarioId,
    updateScenarioId,
    scenarioTabLocationIds,
    updateLocationId,
  } = useQueryData();
  const { generateProductTotals } = useGenerateProductTotals();

  const [sfLocalStorage] = useLocalStorage<ISaleforceLocalStorage | ''>(
    'jid',
    ''
  );

  // React Queries/Mutations
  const { updateEstimate } = useUpdateEstimate();
  const { archiveEstimate } = useArchiveEstimate();
  const { estimateData, estimateLoading, error } =
    useGetEstimateDetails(estimateId);
  const { data: estimateScenarios } = useGetEstimateScenarios({
    params: { estimateId },
  });
  const { data: activeDirectoryData } = useGetActiveDirectory({
    pid: estimateData?.createdBy,
  });

  // Use States
  const [detailCategory, setDetailCategory] = useState('');
  const [tnModalOpen, setTnModalOpen] = useState(false);
  // Gives the TN Modal context of which location TNs are being added to
  const [currTnLocationId, setCurrTnLocationId] = useState('');
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [toast, setToast] = useState<TToast>(null);
  const [rateCenterModalIsOpen, setRateCenterModalIsOpen] = useState(false);
  const [currRcLocationId, setCurrRcLocationId] = useState('');
  const isLocal = window.location.host.split('.')[0];
  const [onRefresh, setOnRefresh] = useState(
    !(isLocal.includes('develop') || isLocal.includes('local'))
  );
  const [isOpenSf, setIsOpenSf] = useState(false);

  const [salesforceCall, setSalesforceCall] = useState(false);
  const [showEstimateModal, setShowEstimateModal] = useState(false);
  const onSfError = useCallback((err: AxiosError) => {
    setRefreshLoading(false);
    setOnRefresh(false);
    setToast({
      type: 'alert',
      title: 'Error',
      text: err?.message || 'Unable to refresh. Please try again later.',
    });
  }, []);

  useMemo(() => {
    if (!estimateLoading && !estimateData?.serviceLocationRegion) {
      setSalesforceCall(true);
    } else if (
      !estimateLoading &&
      !estimateData?.serviceLocationRegion?.includes('National')
    ) {
      setIsOpenSf(true);
      setSalesforceCall(false);
    } else if (
      !estimateLoading &&
      estimateData?.serviceLocationRegion?.includes('National')
    ) {
      setOnRefresh(false);
    }
  }, [estimateLoading, estimateData]);

  const { data: sfOppData, isLoading: isSfOppLoading } =
    useGetSfInfo<ISalesforceOpportunity>({
      opportunityId: estimateData?.sfOpportunityId,
      enabled:
        refreshLoading || (!estimateLoading && onRefresh && salesforceCall),
      apiCall: getSfOppById,
      onError: onSfError,
    });

  const findExistingLocation = useCallback(
    (location: ISalesforceLocation) => {
      if (!estimateData?.locations) return null;
      return estimateData.locations.find(
        (el) => el.sfLocationId === location.serviceLocationId
      );
    },
    [estimateData]
  );

  const onSfLocationSuccess = useCallback(
    (res: ISalesforceLocation[]) => {
      if (!sfOppData || !estimateData) return setRefreshLoading(false);
      setOnRefresh(false);
      const isRegional = res.some(
        (r) => r.serviceLocationRegion && r.serviceLocationRegion !== 'National'
      );
      if (isRegional) {
        setSalesforceCall(false);
        setIsOpenSf(true);
      }
      const updatedLocations = res.reduce((acc: ILocation[], l) => {
        const existingLocation = findExistingLocation(l);
        if (existingLocation) {
          const { street, city, state, postalCode } = parseSfAddressData(
            l.shippingAddress
          );
          acc.push({
            ...existingLocation,
            address1: street,
            city,
            state,
            zipcode: postalCode,
            sfBillingSystem: l.billingSystem,
            sfServiceAreaNumber: l.serviceAreaNumber,
            sfLocationId: l.serviceLocationId,
          });
        }
        return acc;
      }, []);

      const updatedEstimate: IEstimateUpdate = {
        ...estimateData,
        serviceLocationRegion: isRegional ? 'Regional' : 'National',
        status: sfOppData.stageName,
        lastRefreshed: dayjs().toISOString(),
        locations: updatedLocations,
        salesTeam: {
          ...estimateData.salesTeam,
          [sfOppData.ownerPID]: 'Owner',
        },
      };

      const scenarioLocationIds = estimateData.scenarios
        .map((s) => s.locations.map((sl) => sl.id))
        .flat();

      const missingLocation = scenarioLocationIds.filter((sl) => {
        return updatedLocations.every((ul) => ul.id !== sl);
      });

      missingLocation.forEach((m) => {
        const estLocations = estimateData.locations.find((l) => l.id === m);
        if (estLocations) {
          updatedEstimate.locations.push(estLocations);
        }
      });
      // eliminating submissions from payload to avoid huge payload size
      const filterPayload = (updatedEstimate: any, propertiesToIgnore: any) => {
        const filteredPayload = { ...updatedEstimate };
        propertiesToIgnore.forEach((prop: any) => {
          delete filteredPayload[prop];
        });
        return filteredPayload;
      };

      const propertiesToIgnore = ['submissions'];
      const filteredEstWithoutSub = filterPayload(
        updatedEstimate,
        propertiesToIgnore
      );

      updateEstimate(filteredEstWithoutSub);
      setRefreshLoading(false);
    },
    [estimateData, sfOppData, updateEstimate, findExistingLocation]
  );

  const { isLoading: isSfLocationLoading } = useGetSfInfo<
    ISalesforceLocation[]
  >({
    billingId: sfOppData?.billingAccountId,
    enabled:
      (refreshLoading && !!sfOppData) ||
      (!!sfOppData?.billingAccountId && onRefresh && salesforceCall),
    apiCall: getSfLocations,
    onSuccess: onSfLocationSuccess,
    onError: onSfError,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const tableTabs = useMemo(() => {
    const locations = `Locations (${estimateData?.locations?.length || 0})`;
    const scenarios = `Scenarios (${estimateData?.scenarios?.length || 0})`;

    setDetailCategory(locations);

    return [locations, scenarios];
  }, [estimateData]);

  const estimatelocationId = estimateData?.locations?.[0].id;
  const reviewPageUrl = `/review-uc/${estimateId}/${estimatelocationId}`;

  const salesforceButtons = useMemo(() => {
    return (
      <div className="estimate-detail-page__buttons">
        <ButtonLink
          to={`/view-estimate-products/${estimateId}/review`}
          state={{ fromDomain: pathname }}
          className="estimate-detail-page__detail-link"
          type="outline"
          linkContent="View Estimate Summary"
        />
        {/* {estimateData?.pushedToSf && (
          <ButtonLink
            to={`/update-salesforce/${estimateId}/results`}
            state={{ fromDomain: pathname }}
            className="estimate-detail-page__detail-link"
            type="outline"
            linkContent="View Salesforce Results"
          />
        )} */}
        <ButtonLink
          onClick={(e) => {
            e.preventDefault();
            window.open(reviewPageUrl, '_blank');
          }}
          to={reviewPageUrl}
          state={{ fromDomain: pathname }}
          className="estimate-detail-page__detail-link custom-button"
          type="outline"
          linkContent="Review Page"
        />
      </div>
    );
  }, [estimateId, pathname, reviewPageUrl]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const onSelectTab = useCallback(
    (category: string) => {
      setDetailCategory(category);
      trackSelectAction(
        `Estimate Details: Select ${category.split(' ')[0]} Tab`,
        {
          opType: 'buttonClick',
        }
      );
    },
    [trackSelectAction]
  );

  const toggleTnModal = useCallback(
    (locationId: string) => {
      setCurrTnLocationId(locationId);
      setTnModalOpen(!tnModalOpen);
      trackOpenState({
        name: 'Estimate Details TN Modal',
        isOpen: tnModalOpen,
      });
    },
    [tnModalOpen, trackOpenState]
  );

  const toggleRateCenterModal = useCallback(
    (locationId: string) => {
      setCurrRcLocationId(locationId);
      setRateCenterModalIsOpen(!rateCenterModalIsOpen);
      trackOpenState({
        name: 'Estimate Details Rate Center Modal',
        isOpen: rateCenterModalIsOpen,
      });
    },
    [rateCenterModalIsOpen, trackOpenState]
  );

  const onEstimateRefresh = useCallback(async () => {
    if (!sfLocalStorage) {
      await initSalesforceLogin({ state: { location: pathname } });
    } else {
      // Setting this to true triggers the sfQuery hooks to run
      setRefreshLoading(true);
    }
    trackSelectAction('Estimate SF Refresh/Sync', { opType: 'buttonClick' });
  }, [setRefreshLoading, sfLocalStorage, pathname, trackSelectAction]);

  const toggleArchiveModal = useCallback(() => {
    setShowArchiveModal(!showArchiveModal);
    trackOpenState({
      name: 'Estimate Details Archive Modal',
      isOpen: showArchiveModal,
    });
  }, [showArchiveModal, trackOpenState]);

  const handleArchive = useCallback(() => {
    if (estimateData) {
      archiveEstimate(estimateData);
      toggleArchiveModal();
      trackSelectAction(`Estimate Archived: ${estimateData.id}`, {
        opType: 'buttonClick',
      });
    }
  }, [archiveEstimate, estimateData, toggleArchiveModal, trackSelectAction]);

  const handleBuilderClick = useCallback(() => {
    if (!scenarioId && estimateData?.scenarios.length) {
      const newScenarioId = estimateData.scenarios[0].id;
      const newLocationId = scenarioTabLocationIds[newScenarioId];
      updateScenarioId(estimateData.scenarios[0].id);
      newLocationId && updateLocationId(newLocationId);
    }
  }, [
    estimateData,
    scenarioId,
    scenarioTabLocationIds,
    updateLocationId,
    updateScenarioId,
  ]);

  // =============================================
  // Render Methods
  // =============================================
  const getScenarioProductCategories = useCallback((scenario: IScenario) => {
    const categories = Object.keys(TableCategoryIconMap);
    const productCategories = categories.reduce(
      (acc: JSX.Element[], category) => {
        const categoryProduct = scenario?.selections?.find(
          (p) => p.familyCategory === category
        );

        if (categoryProduct) {
          acc.push(
            <KiteIcon
              key={`${category} icon`}
              margin="0 0 0 8px"
              name={TableCategoryIconMap[category as TTableCategory]}
            />
          );
        }
        return acc;
      },
      []
    );

    return productCategories;
  }, []);

  const toggleCloneEstimateModal = useCallback(() => {
    setShowEstimateModal(!showEstimateModal);
  }, [showEstimateModal]);

  const generateScenarioTableData = useCallback(() => {
    const tableColumns = [
      {
        label: 'Name',
        sortKey: 'name',
      },
      {
        label: 'Active Locations',
        sortKey: 'activeLocations',
      },
      {
        label: 'Included Products',
        sortKey: 'includedProducts',
      },
      {
        label: 'Solution MRC',
        sortKey: 'solutionMrc',
      },
      {
        label: 'Solution NRC',
        sortKey: 'solutionNrc',
      },
    ];

    const tableData =
      estimateScenarios?.map((scenario) => {
        const scenarioTotals = generateProductTotals({});
        const productCategories = getScenarioProductCategories(scenario);

        return {
          name: scenario.name,
          activeLocations: scenario.locations?.length || 0,
          includedProducts: productCategories,
          solutionMrc: scenarioTotals.solutionMrc,
          solutionNrc: scenarioTotals.solutionNrc,
        };
      }) || [];

    return (
      <SortableTable
        className="estimate-detail-page__sortable-table"
        loading={!tableData}
        columns={tableColumns}
        tableData={tableData}
        hoverableRows={false}
        stripedRows={true}
      />
    );
  }, [estimateScenarios, generateProductTotals, getScenarioProductCategories]);

  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId },
  });

  const renderPageContent = useCallback(() => {
    if (detailCategory.includes('Locations')) {
      return estimateData?.locations.map((location) => (
        <EstimateLocationPanel
          key={`${location.id} location card`}
          location={location}
          toggleAddTnModal={toggleTnModal}
          toggleRateCenterModal={toggleRateCenterModal}
          allSubmissions={allSubmissions}
        />
      ));
    } else {
      return generateScenarioTableData();
    }
  }, [
    estimateData,
    detailCategory,
    allSubmissions,
    generateScenarioTableData,
    toggleTnModal,
    toggleRateCenterModal,
  ]);

  const renderEstimateDetails = useCallback(() => {
    if (!estimateData) return null;
    const teamMember = formatTeamMember({ activeDirectoryData });
    const createdByName = teamMember?.fullName ?? estimateData.createdBy;

    const estimateDetails = {
      'Created By': createdByName,
      'Created On': dayjs(estimateData.createdAt).format('MMM D, YYYY h:mm A'),
      'Last Updated': dayjs(estimateData.updatedAt).format(
        'MMM D, YYYY h:mm A'
      ),
    };

    const estimateDetailRows = Object.entries(estimateDetails).map(
      ([title, value]) => {
        return (
          <tr key={title}>
            <td>{title}: </td>
            <td>{value}</td>
          </tr>
        );
      }
    );

    return (
      <KiteCard className="estimate-detail-page__detail">
        <section className="estimate-detail-page__detail-section">
          <div>
            <span className="estimate-detail-page__detail-title">{`${
              estimateData.customerName
            } New Build${estimateData.archived ? ' (Archived)' : ''}`}</span>
            <h2>{estimateData.customerName}</h2>
          </div>
          <div>
            <AutobuildPill isAutobuild={isEstimateAutobuild(estimateData)} />
          </div>
        </section>
        <section>
          <table className="estimate-detail-page__detail-table">
            <tbody>{estimateDetailRows}</tbody>
          </table>
        </section>
      </KiteCard>
    );
  }, [estimateData, activeDirectoryData]);

  const renderSalesforceDetails = useCallback(() => {
    if (!estimateData) return null;
    return (
      <KiteCard className="estimate-detail-page__detail">
        <div className="estimate-detail-page__detail-buttons">
          <span className="estimate-detail-page__detail-title">Estimate</span>
          <KiteButton
            type="standalone-link"
            leftIcon="refresh"
            size="small"
            onClick={onEstimateRefresh}
            loading={refreshLoading || isSfOppLoading || isSfLocationLoading}
          >
            Refresh
          </KiteButton>
        </div>
        <section>
          <table className="estimate-detail-page__detail-table">
            <tbody>
              <tr>
                <td>Opportunity ID:</td>
                <td>
                  {React.createElement(
                    'a',
                    {
                      href: `${env.sfUrl}/${estimateData.sfOpportunityId}`,
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      onClick: trackNavClick(
                        'Navigate to Salesforce Opportunity'
                      ),
                    },
                    estimateData.sfOpportunityId
                  )}
                </td>
              </tr>
              <tr>
                <td>Last Refreshed:</td>
                <td>
                  {dayjs(estimateData.lastRefreshed).format(
                    'MMM D, YYYY h:mm A'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {salesforceButtons}
      </KiteCard>
    );
  }, [
    estimateData,
    salesforceButtons,
    refreshLoading,
    onEstimateRefresh,
    trackNavClick,
    isSfOppLoading,
    isSfLocationLoading,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('EstimateDetailPage'), [trackPageView]);
  if (isOpenSf)
    return (
      <KiteModal
        modalId="sandBoxModal"
        className="sandbox-modal"
        canShow={isOpenSf}
        showCloseButton={false}
      >
        <KiteAlert
          type="alert"
          description="SPT is not available for Regional Opportunties. Please continue in Salesforce"
        />
        <section>
          <table className="estimate-detail-page__detail-table">
            <tbody>
              <span className="estimate-detail-page__detail-title">
                Salesforce
              </span>
              <tr>
                <td>Opportunity ID:</td>
                <td>
                  {React.createElement(
                    'a',
                    {
                      href: `${env.sfUrl}/${estimateData?.sfOpportunityId}`,
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      onClick: trackNavClick(
                        'Navigate to Salesforce Opportunity'
                      ),
                    },
                    estimateData?.sfOpportunityId
                  )}
                </td>
                <div className="sptdashboard">
                  <td>SPT Dashboard:</td>
                  <td>
                    {React.createElement(
                      'a',
                      {
                        href: '/dashbaord',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        onClick: trackNavClick(
                          'Navigate to Salesforce Opportunity'
                        ),
                      },
                      'Dashboard'
                    )}
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
        </section>
      </KiteModal>
    );
  // =============================================
  // Early Return
  // =============================================
  if (error)
    return (
      <div className="estimate-detail-page-error">
        <span>{error}</span>
      </div>
    );
  else if (estimateLoading || onRefresh)
    return (
      <KiteLoader
        loaderTitle="Loading Solution Pricing Tool"
        secondaryMessage="Estimate Detail Page"
      />
    );

  // =============================================
  // Return
  // =============================================
  // "uniqueKey" prop on Toast throws React key error if not set to "text"
  return (
    <div className="estimate-detail-page">
      <Toast newToast={toast} uniqueKey="text" />
      <div className="estimate-detail-page__wrapper--details">
        {renderEstimateDetails()}
        {renderSalesforceDetails()}
      </div>
      <SalesTeamContainer />

      <div className="estimate-detail-page__wrapper--navigation">
        <KiteTabs
          currentTab={detailCategory}
          onSelectTab={onSelectTab}
          tabs={tableTabs}
        />
        <div className="estimate-detail-page__wrapper-buttons">
          <CloneEstimateModal
            canShow={showEstimateModal}
            onToggle={toggleCloneEstimateModal}
            sourceEstimate={estimateData!}
          />

          <ButtonLink
            to={`/dashboard/edit-estimate/${estimateData?.sfOpportunityId}/${estimateId}`}
            linkContent="Edit Estimate Locations"
            type="outline"
          />
          <UcProgressButton type="primary" />
          <RcProgressButton type="primary" />
          <MNEProgressButton type="primary" />
          <ENEProgressButton type="primary" />
          <ButtonLink
            to={`/estimate-builder/${estimateId}`}
            state={{ fromDomain: pathname }}
            linkContent="Open Estimate Builder"
            onClick={handleBuilderClick}
          />
        </div>
      </div>
      <div className="estimate-detail-page__content">{renderPageContent()}</div>
      {isAdmin && (
        <>
          <KiteButton
            className="estimate-detail-page__archive-btn"
            leftIcon="folder"
            maxWidth="min-content"
            minWidth="min-content"
            type="outline"
            onClick={toggleArchiveModal}
          >
            Archive Estimate
          </KiteButton>
          <KiteModal
            canShow={showArchiveModal}
            onHide={toggleArchiveModal}
            title="Archive Estimate"
            ctaCopy="Archive"
            ctaAction={handleArchive}
            secondaryCtaCopy="Cancel"
            secondaryCtaAction={toggleArchiveModal}
          >
            <KiteAlert
              description="Archiving an estimate will remove it from the dashboard. Are you sure you want to continue?"
              id="archive-alert"
              level="page"
              type="alert"
            />
          </KiteModal>
        </>
      )}
      <AddTnModal
        canShow={tnModalOpen}
        onClose={toggleTnModal}
        locationId={currTnLocationId}
      />
      <RateCenterSearchModal
        canShow={rateCenterModalIsOpen}
        onClose={toggleRateCenterModal}
        locationId={currRcLocationId}
      />
    </div>
  );
};

export default EstimateDetailPage;
