// External Packages
import React, { useCallback, useMemo } from 'react';
import { View } from '@react-pdf/renderer';

// Utils
import {
  generateViewComponent,
  formatPrice,
  _3MonthsPromoCheck,
  inFootprintIswId,
  outOfFootprintIswId,
} from 'utils';

// Types
import {
  ILocationSelection,
  TableValues,
  IGenerateProductTotals,
  IProductFamily,
  TTermLengthMonths,
  IPromo,
  TEipTermLengthMonths,
} from 'types';

// Styles
import { styles } from './PdfDocumentStyles';

interface IPdfProductRow {
  selection: ILocationSelection;
  productFamily: IProductFamily;
  currentTerm: TTermLengthMonths;
  currentEipTerm: TEipTermLengthMonths;
  appliedPromos: IPromo[];
  locationSelections: ILocationSelection[];
  generateProductTotals: ({
    selections,
    locationId,
    category,
    subCategory,
    format,
  }: IGenerateProductTotals) => TableValues<number | string>;
}

export const PdfProductRow = ({
  selection,
  productFamily,
  currentTerm,
  currentEipTerm,
  generateProductTotals,
  appliedPromos,
  locationSelections,
}: IPdfProductRow) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const familyName = productFamily.name;

  const cellValues = generateProductTotals({
    term: currentTerm,
    eipTerm: currentEipTerm,
    selections: [selection],
    format: false,
  }) as TableValues<number>;

  const isPromoProduct = useCallback(
    (promo: IPromo) => {
      if (promo.id === 'd4cf26a3-5785-427a-905a-7705dc00df6b') {
        return _3MonthsPromoCheck({
          promo,
          currentSelections: locationSelections,
          selection,
        });
      }
      const { includedFamilyIds, includedProductIds, excludedProductIds } =
        promo;

      if (excludedProductIds.includes(selection.productId)) {
        return false;
      }

      if (includedFamilyIds.includes(productFamily.id)) {
        return true;
      }

      if (includedProductIds.includes(selection.productId)) {
        return true;
      }
    },
    [selection, productFamily.id, locationSelections]
  );

  const cellText = useMemo(() => {
    const { name: productName, quantity, eipTerm, productId } = selection;
    const promoStar = appliedPromos.find((promo) => isPromoProduct(promo))
      ? '*'
      : '';

    let perEachCost;
    let additionalCost = ' ';
    if (eipTerm) {
      perEachCost = formatPrice(cellValues.rateNrc / quantity);

      if (['12', '24', '36'].includes(eipTerm)) {
        const dividedCost = cellValues.rateNrc / parseInt(eipTerm, 10);
        additionalCost = `EIP Rate - (${formatPrice(dividedCost)}/Month)`;
      }
    } else {
      perEachCost = formatPrice(cellValues.solutionMrc / quantity);
    }
    if (
      productFamily &&
      (productFamily.name.toLowerCase() === 'demarc extension' ||
        productFamily.name.toLowerCase() === 'demarc extension promo')
    ) {
      return `${productFamily.name}`;
    }

    let cellText = `${familyName}: ${productName} ${promoStar}`;

    if (eipTerm) {
      if (productId === inFootprintIswId || productId === outOfFootprintIswId) {
        cellText = `Inside Wiring Service`;
        if (eipTerm !== 'One-time Payment') {
          const ipCost = (cellValues.rateNrc / parseInt(eipTerm, 10)).toFixed(
            2
          );

          cellText = `Inside Wiring Service ; IP Rate - ($${ipCost}/Month)`;
        }
      } else {
        cellText = `${quantity} x ${productName} ${promoStar} (${perEachCost} ea); ${additionalCost}`;
      }
    } else if (productFamily.type === 'number') {
      cellText =
        perEachCost !== '-'
          ? `${productName} (${perEachCost} ea) ${promoStar}`
          : `${productName} ${promoStar}`;
    }
    // If product name is long, split into two lines using ;
    if (cellText.length > 40) {
      cellText = cellText.replace(':', ':;');
    }
    return cellText;
  }, [
    appliedPromos,
    cellValues.solutionMrc,
    cellValues.rateNrc,
    isPromoProduct,
    selection,
    productFamily.type,
    familyName,
  ]);

  // =============================================
  // Render Methods
  // =============================================
  const productCells = Object.entries(cellValues).map(([key, value]) => {
    return generateViewComponent(formatPrice(value), 'tableCell', key);
  });

  // =============================================
  // Early Return
  // =============================================
  if (
    !familyName ||
    familyName === 'Location' ||
    familyName === 'Bandwidth Options'
  )
    return null;

  // =============================================
  // Return
  // =============================================
  return (
    <View style={styles.productRow}>
      {generateViewComponent(cellText, 'productCell', 'product name')}
      {productCells}
    </View>
  );
};
