import { IRequestParams } from 'types';

interface IQueryKeyParams extends IRequestParams {
  locationId?: string;
  estimateId?: string;
  scenarioId?: string;
  location?: number;
  featured?: boolean;
  published?: boolean;
  opportunityId?: string;
  billingId?: string;
  pid?: string;
}

export const queryKeys = (params: IQueryKeyParams = {}) => {
  const { estimateId, locationId, opportunityId, pid } = params;

  return {
    all: {
      adminHistory: ['allAdminHistories'],
      bundles: ['allBundles'],
      offers: ['allOffers'],
      estimates: ['allEstimates', { archived: false }],
      estimateScenarios: ['estimateScenarios'],
      featureFlags: ['featureFlags'],
      fields: ['allFormFields'],
      forms: ['allForms'],
      locationSelections: ['allLocationSelections'],
      productFamilies: ['allProductFamilies'],
      products: ['allProducts'],
      profileCategories: ['profileCategories'],
      profiles: ['allProfiles'],
      rules: ['allRules'],
      serviceCodes: ['serviceCodes'],
      submissions: ['allSubmissions'],
      scenarioPromos: ['allScenarioPromos'],
      promos: ['allPromos'],
      failedProducts: ['failedProducts', opportunityId],
      reports: ['allReports', { archived: false }],
      zipCodes: ['allZipCodes'],
      copyHistories: ['allCopyHistories'],
      cloneHistories: ['allCloneHistories'],
    },
    details: {
      estimate: ['estimateDetails', estimateId],
      estimateBySfId: ['estimateBySfId', opportunityId],
      phoneNumbers: ['getPhoneNumbers', locationId],
      distros: ['distros', estimateId],
      location: ['locationById', locationId],
      locationBySfId: ['locationBySfIdId', params],
      salesforce: ['salesforce', params],
      activeDirectory: ['activeDirectory', pid],
    },
    filter: {
      adminHistory: ['adminHistories', params],
      submissionHistory: ['submissionHistory', estimateId],
      bundleSelections: ['bundleSelections', params],
      bundles: ['bundles', params],
      offers: ['offers', params],
      estimates: ['estimates', params],
      estimateScenarios: ['scenarios', params],
      fields: ['formFields', params],
      forms: ['forms', params],
      locationSelections: ['locationSelections', params],
      productFamilies: ['productFamilies', params],
      products: ['products', params],
      rules: ['rules', params],
      submissions: ['submissions', params],
      scenarioPromos: ['scenario-promos', params],
      profiles: ['profiles', params],
      reports: ['reports', params],
      zipCodes: ['zipCodes', params],
      copyHistories: ['copyHistories', params],
      cloneHistories: ['cloneHistories', params],
    },
  };
};
