// External Packages
import React, { ReactNode } from 'react';
import { Page as _Page, Image, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

// Utils

// Styles
import { styles } from './PdfDocumentStyles';

interface IPdfPageWrapper {
  children: ReactNode;
  customerName: string;
  locationName?: string;
  quoteName: string;
}

function componentWithChildren<Props>(Component: React.ComponentType<Props>) {
  return Component as React.ComponentType<Props & { children: ReactNode }>;
}
const Page = componentWithChildren(_Page);

export const PdfPageWrapper = ({
  children,
  customerName,
  locationName,
  quoteName,
}: IPdfPageWrapper) => {
  const headerInformation = () => {
    const text = [];
    customerName && text.push(customerName);
    locationName && text.push(locationName);

    if (locationName) {
      return (
        <View>
          <Text style={styles.headerLeft}>{`${text.join(': ')}`}</Text>
        </View>
      );
    }
    return (
      <View style={styles.headerMainWrapper}>
        <Text style={styles.headerMain}>{customerName}</Text>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Header */}
      {headerInformation()}

      <View style={styles.subHeaderWrapper}>
        <Text style={styles.subHeaderBackground}></Text>
        <Text style={styles.subHeader}>{quoteName}</Text>
      </View>

      <View style={styles.internalUseWrapper}>
        <Text style={styles.internalUse}>** FOR INTERNAL USE ONLY **</Text>
      </View>

      {children}

      {/* Footer */}
      <View style={styles.footerWrapper} fixed>
        <View style={styles.footerSection}>
          <View>
            <Text style={styles.footerTextUpper}>{`Created: ${dayjs().format(
              'MMMM DD, YYYY [at] hh:mm A zzz'
            )}`}</Text>
          </View>
          <View
            render={({ pageNumber }) => (
              <Text style={styles.footerTextUpper}>{`Page ${pageNumber}`}</Text>
            )}
          />
        </View>
        <View style={styles.footerSection} fixed>
          <Text style={styles.footerText}>Charter Communications</Text>
          <Text style={styles.footerText}>INTERNAL USE ONLY</Text>
          <Text style={styles.footerText}>CONFIDENTIAL</Text>
        </View>
      </View>

      {/* Watermark */}
      <Image style={styles.image} src="/images/Spectrum_Business_Logo_R_Black.jpg" />
      <View style={styles.waterMark}>
        <Text style={styles.waterMarkText1}>USE</Text>
        <Text style={styles.waterMarkText2}>ONLY</Text>
      </View>
    </Page>
  );
};
