// Packages
import React, { useEffect, useMemo, useCallback, useState } from 'react';

// Redux

// Components
import { KiteAlert, KiteButton, KiteModal } from '@kite/react-kite';
import { EstimateReviewSection } from 'components';

// Hooks
import {
  useAnalytics,
  useQueryData,
  useUpdateEstimate,
  useGetEstimateDetails,
  useGetEstimateScenarios,
  // usePostFailedProducts,
} from 'hooks';
import { useParams } from 'react-router-dom';

// Utils
// import { addProductsToSfOpp } from 'utils';

// Types
import { defaultSfAlert } from 'types/Salesforce';

// Styles
import './SalesforceReviewPage.scss';

const SalesforceReviewPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId = '' } = useParams();
  const { trackPageView } = useAnalytics();
  // const navigate = useNavigate();
  const { scenarioId, estimateScenarios, currentEstimate } = useQueryData();
  const { updateEstimate } = useUpdateEstimate();

  // Ensure react query has estimate details
  const { estimateData } = useGetEstimateDetails(estimateId);
  // const { postFailedProducts } = usePostFailedProducts();
  useGetEstimateScenarios({
    params: { estimateId },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isUpdating, setIsUpdating] = useState(false);
  const [sfAlert, setSfAlert] = useState(defaultSfAlert);
  // const [sfLocalStorage] = useLocalStorage<ISaleforceLocalStorage | ''>(
  //   'jid',
  //   ''
  // );

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const handleAlertClose = useCallback(() => setSfAlert(defaultSfAlert), []);

  const estimateAlert = useMemo(() => {
    if (!sfAlert.show) return null;

    return (
      <KiteAlert
        description={sfAlert.description}
        level="page"
        onClose={handleAlertClose}
        title={sfAlert.title}
        type={sfAlert.type || 'alert'}
        useDark={false}
      />
    );
  }, [sfAlert, handleAlertClose]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleModal = useCallback(
    () => setIsModalOpen(!isModalOpen),
    [isModalOpen]
  );

  const pushedScenario = useMemo(() => {
    if (estimateScenarios.length === 1) {
      return estimateScenarios[0];
    }
    else {
      return estimateScenarios?.find(
        (s) => s.id === currentEstimate?.pushedScenarioId
      );
    }
  }, [estimateScenarios, currentEstimate]);

  const selectedScenario = useMemo(() => {
    return estimateScenarios?.find((s) => s.id === scenarioId);
  }, [estimateScenarios, scenarioId]);

  /* 
  const submitToSalesforce = useCallback(async () => {
    try {
      if (!estimateData) return;

      const results: IProductResponse = await addProductsToSfOpp({
        opportunityId: estimateData.sfOpportunityId,
        scenarioId,
      });

      if (results.data.failed.length) {
        postFailedProducts(results.data.failed);
      }

      updateEstimate({
        ...estimateData,
        pushedToSf: true,
        pushedScenarioId: scenarioId,
      });
      setIsModalOpen(false);
      navigate(`/update-salesforce/${estimateId}/results`);
    } catch (err: any) {
      // set fail message
      const description = err.toString().includes('403')
        ? 'User has insufficient permissions in Salesforce'
        : err.toString().includes('500')
        ? 'No records were found for the selected products.'
        : 'Unable to update Salesforce. Please try again later.';
      setSfAlert({
        show: true,
        description,
        title: 'Error',
        type: 'alert',
      });
    }
  }, [
    estimateData,
    estimateId,
    scenarioId,
    updateEstimate,
    navigate,
    postFailedProducts,
  ]);
  */

  const handleUpdateSalesforce = useCallback(async () => {
    // setIsUpdating(true);

    /* if (!sfLocalStorage) {
       toggleModal();
       await initSalesforceLogin({ state: { location: pathname } });
     } else {
       await submitToSalesforce();
     }
     setIsUpdating(false);*/
    if (!estimateData) return;
    // eliminating submissions from payload to avoid huge payload size
    const filterPayload = (estimateData: any, propertiesToIgnore: any) => {
      const filteredPayload = { ...estimateData };
      propertiesToIgnore.forEach((prop: any) => {
        delete filteredPayload[prop];
      });
      return filteredPayload;
    };

    const propertiesToIgnore = ['submissions'];
    const filteredEstWithoutSub = filterPayload(estimateData, propertiesToIgnore);
    updateEstimate({
      ...filteredEstWithoutSub,
      pushedToSf: false,
      pushedScenarioId: scenarioId,
    });
    setIsModalOpen(false);
  }, [scenarioId, estimateData, updateEstimate]);
  // =============================================
  // Render Methods
  // =============================================
  const sfMessage = useMemo(() => {
    if (selectedScenario?.name) {
      return (
        <p>
          Please note that clicking on primary scenario button will make{' '}
          {selectedScenario?.name} as primary.
        </p>
      );
    }
  }, [selectedScenario]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => trackPageView('UpdateSalesforce'), [trackPageView]);

  // =============================================
  // Early Return
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="salesforce-review-page">
      <div className="salesforce-review-page__contents">
        <h1>View Estimate Products</h1>
        {pushedScenario?.name && (
          <KiteAlert
            level="page"
            className="salesforce-alert"
            description={`${pushedScenario?.name} is marked as primary Scenario.`}
            type="info"
          />
        )}
        <EstimateReviewSection />

        <div className="salesforce-review-page__buttons">
          {/* {estimateData?.pushedToSf && (
            <ButtonLink
              className="salesforce-review-page__button-link"
              to={`/update-salesforce/${estimateId}/results`}
              state={{ fromDomain: pathname }}
              type="outline"
              linkContent="View Salesforce Results"
            />
          )} */}
          <KiteButton onClick={toggleModal} size="xl">
            Primary Scenario
          </KiteButton>
        </div>
      </div>

      <KiteModal
        title="Mark Scenario as primary"
        ctaAction={handleUpdateSalesforce}
        ctaCopy="Primary Scenario"
        disableBackgroundClose={false}
        onHide={toggleModal}
        secondaryCtaAction={toggleModal}
        secondaryCtaCopy="Cancel"
        showCloseButton={true}
        canShow={isModalOpen}
      // ctaLoading={isUpdating}
      >
        {estimateAlert}
        {sfMessage}
      </KiteModal>
    </div>
  );
};

export default SalesforceReviewPage;
