// External Packages
import React, { ReactNode } from 'react';
import { Page as _Page, Image } from '@react-pdf/renderer';

// Components

// Utils

// Styles
import { styles } from '../PdfComponents';
import ResponsibilityMatrixPage1 from '../../../images/SE-UC-GD006_v6-Responsibility-Matrix-Guide-1.png';
import ResponsibilityMatrixPage2 from '../../../images/SE-UC-GD006_v6-Responsibility-Matrix-Guide-2.png';

// Types

function componentWithChildren<Props>(Component: React.ComponentType<Props>) {
  return Component as React.ComponentType<Props & { children: ReactNode }>;
}
const Page = componentWithChildren(_Page);

//Required only incase of Ring Central Estimate
const CustomerResponsibilityMatrixPdf = () => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <>
      <Page size="A4" style={styles.page}>
        <Image style={styles.fullScreenImage} src={ResponsibilityMatrixPage1} />
      </Page>
      <Page size="A4" style={styles.page}>
        <Image style={styles.fullScreenImage} src={ResponsibilityMatrixPage2} />
      </Page>
    </>
  );
};

export default CustomerResponsibilityMatrixPdf;
