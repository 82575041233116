// External Packages
import React, { useState, useEffect } from 'react';

// Components
import { KiteLoaderOverlay } from '@kite/react-kite';
import {
  KeyCloak,
  LoginForm,
  EssoLogin,
} from './components/loginAuthorization';
import Routes from './routes/Routes';

// Hooks
import { useAnalytics, useApiAnalytics, useLocalStorage } from 'hooks';
import { useClearCache } from 'react-clear-cache';
import { useDispatch } from 'react-redux';

// Redux
import { storeHeaderLinks } from 'redux/headerLinkDataSlice';

// Utils
import { env, getPartnerlinkToken, postChannelPartnersLogin } from 'utils';
import { setSaleforceAxiosDefaults } from 'utils/api/salesforceRequests';

// Types
import { ISaleforceLocalStorage } from 'types/Salesforce';

// Styles
import './App.scss';

const App = () => {
  const { trackPageView, trackLoginStart, trackLoginStop } = useAnalytics();
  const startApiAnalytics = useApiAnalytics();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const dispatch = useDispatch();

  // =============================================
  // State
  // =============================================
  const [useKeyCloak, setUseKeyCloak] = useState<boolean | string | null>(
    false
  );
  const [charterEmployee, setCharterEmployee] = useState<
    boolean | string | null
  >(false);
  const [loginState, setLoginState] = useState<string>('');
  const [loginError, setLoginError] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [tokenLoginLoading, setTokenLoginLoading] = useState<boolean>(true);
  const [versionCheck, setVersionCheck] = useState(false);

  const [sfLocalStorage] = useLocalStorage<ISaleforceLocalStorage | ''>(
    'jid',
    ''
  );

  // =============================================
  // Use Effect
  // =============================================
  useEffect(() => {
    // Sets interceptors to track api calls in quantum
    if (env.environment !== 'test') {
      startApiAnalytics();
    }
  }, [startApiAnalytics]);

  useEffect(() => {
    // Clear cache on page load
    (async () => {
      try {
        if (!isLatestVersion) {
          await emptyCacheStorage();
        }
        setVersionCheck(true);
      } catch (error) {
        setVersionCheck(true);
      }
    })();

    // Set SF Access Token in Axios
    if (sfLocalStorage) {
      setSaleforceAxiosDefaults({
        accessToken: sfLocalStorage.accessToken,
        refreshToken: sfLocalStorage.refreshToken,
        issuedAt: sfLocalStorage.issuedAt,
      });
    }

    // Set local storage to render KeyCloak component if user selects ESSO
    const localUseKeyCloak = localStorage.getItem('useKeyCloak');
    setUseKeyCloak(localUseKeyCloak);
    localStorage.setItem('useKeyCloak', 'false');

    // Render KeyCloak component if user has logged in before with ESSO
    const localCharterEmployee = localStorage.getItem('charterEmployee');
    setCharterEmployee(localCharterEmployee);

    // Check channel partners refresh token if no Charter local storage is found
    if (localUseKeyCloak !== 'true' || localCharterEmployee !== 'true') {
      (async () => {
        const data = await getPartnerlinkToken();
        if (data.error === 'Login') {
          setTokenLoginLoading(false);
        } else {
          setLoginState('SUCCESS');
          setTokenLoginLoading(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => trackPageView('Login'), [trackPageView]);

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleSubmit = async (email: string, password: string) => {
    setLoginLoading(true);

    trackLoginStart('manualAuth');
    const data = await postChannelPartnersLogin(email, password);

    if (!data || data.error) {
      trackLoginStop(false);
      setLoginState('ERROR');
      setLoginError(true);
      setLoginLoading(false);
      setTokenLoginLoading(false);
    } else if (data.username) {
      trackLoginStop(false, {
        userToken: data.username,
        accountGUID: data.username,
        accountNumber: data.username,
      });
      setLoginState('SUCCESS');
      setLoginError(false);
      setLoginLoading(false);
      setTokenLoginLoading(false);
    }
  };

  if (!versionCheck) {
    return <KiteLoaderOverlay />;
  }

  let display;
  // Check to see if user has used ESSO before or is coming back from KeyCloak
  if (useKeyCloak === 'true' || charterEmployee === 'true') {
    display = <KeyCloak />;
  } else if (loginState === 'SUCCESS' && !tokenLoginLoading) {
    dispatch(
      storeHeaderLinks([
        {
          label: 'Contact Support',
          url: 'mailto:DL-Solution-Pricing-Tool-CP-Support@charter.com',
        },
        {
          label: 'SPT Business Rules',
          url: 'https://cache.amp.vg/partnerlink.spectrum.com/file/bx3crp73wdqdf/Enterprise_Solution_Pricing_Tool__SPT__business_rules.pdf',
        },
        {
          label: 'Logout',
          url: 'Logout',
        },
      ])
    );
    display = (
      <Routes
        userName="Menu"
        pId={''}
        isPartnerLinkUser={true}
        userEmail={''}
      />
    );
    // } else if (tokenLoginLoading) {
    //   return (
    //     <div className="display-loader">
    //       <KiteLoader loaderTitle="Loading Solution Pricing Tool" />
    //     </div>
    //   );
  } else {
    display = (
      <div className="login-page">
        <h1 className="kite-h1 login-page__title">Solution Pricing Tool</h1>
        <div className="login-page__card-container">
          <EssoLogin
            onEssoClick={() => {
              setUseKeyCloak('true');
              localStorage.setItem('useKeyCloak', 'true');
            }}
          />
          <LoginForm
            onSubmit={handleSubmit}
            loginLoading={loginLoading}
            loginError={loginError}
          />
        </div>
      </div>
    );
  }

  // =============================================
  // Return
  // =============================================
  return <div className="App">{display}</div>;
};

export default App;
