import { IForm, MForm } from 'types';

export const TableCategoryIconMap = {
  Data: 'internet',
  Trunks: 'phone-office',
  'UC Products': 'phone',
  'RingCentral Products': 'phone-f',
  MNE: 'shield',
  ENE: 'shield-half-f',
  'SBB 1-19 Pub/Priv': 'tv',
};

export const NavigationIconMap = {
  ...TableCategoryIconMap,
  Promos: 'lightning',
  PromosActive: 'lightning-f',
  Bundles: 'grid',
  Offer: 'network-alt',
  'Business Rules': 'notes',
  'Add Products': 'plus-circle',
  'UC Profiles': 'phone',
  'UC Addons': 'plus-circle',
  'RC Addons': 'plus-circle-f',
  'Admin Histories': 'account',
};

export const ProductCategoriesMap = {
  ...TableCategoryIconMap,
  NE: null,
};

export const UcQuestionIconsMap: { [formName: IForm['name']]: string } = {
  'General Discovery': 'info-circle-f',
  General: 'info-circle-f',
  Intro: 'internet',
  Discovery: 'info-circle',
  'SE Discovery': 'lightbulb',
  Workflow: 'checkmark',
  'LAN/WAN': 'network-alt',
  'MDF/IDF': 'network',
  'Network Draft': 'map',
  'Contact Info': 'person',
  'SME Form': 'info-circle',
  'Discovery Notes/Questions': 'network',
  'Opportunity Type': 'info-circle-f',
  'ENE General': 'info-circle-f',
  'MNE Network Draft': 'map',
  'ENE Discovery Questions/Notes': 'network',
  'MNE Discovery Questions/Notes': 'network',
  'MNE Hospitality': 'network',
  'MNE Campus Living': 'bank',
  'UC Discovery Notes/Questions': 'network',
};

export const RcQuestionIconsMap: { [formName: IForm['name']]: string } = {
  'General Discovery': 'info-circle-f',
  General: 'info-circle-f',
  Intro: 'internet',
  Discovery: 'info-circle',
  'SE Discovery': 'lightbulb',
  Workflow: 'checkmark',
  'LAN/WAN': 'network-alt',
  'MDF/IDF': 'network',
  'Network Draft': 'map',
  'Contact Info': 'person',
  'SME Form': 'info-circle',
};

export const MneQuestionIconsMap: { [formName: MForm['name']]: string } = {
  General: 'info-circle-f',
  'Opportunity Type': 'info-circle-f',
  'ENE General': 'info-circle-f',
  'Network Draft': 'map',
  'Device Management': 'internet',
  'MNE Questions': 'info-circle-f',
  Devices: 'lightbulb',
  Notes: 'info-circle',
  'Enhanced Wiring': 'network',
  Wiring: 'network',
  'WAN/LAN': 'network-alt',
  'MDF/IDF': 'network',
  WiFi: 'wifi',
  FireWall: 'shield',
  Floorplans: 'map-point',
  'Non-Campus Living End-Points': 'bank',
  'Coverage Area': 'map',
};
