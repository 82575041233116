// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteSelect } from '@kite/react-kite';

// Hooks

// Utils

// Types
import { IUCQInputProps } from 'types';

// Styles
import '../UCQFieldStyles.scss';

/** Custom input for 'select' inputType on UCQ Field */
const UCQSelect = ({
  fieldInput,
  onFieldChange,
  isDisabled,
}: IUCQInputProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { options, id, inputName, value } = fieldInput;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const selectOptions = useMemo(() => {
    return (
      fieldInput.options?.map((o) => (
        <option key={o} value={o}>
          {o}
        </option>
      )) || []
    );
  }, [fieldInput.options]);

  const [selectedOption, setSelectedOption] = useState(value || '');

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setSelectedOption(value);
      onFieldChange(id, value);
    },
    [id, onFieldChange]
  );

  useEffect(() => {
    setSelectedOption(value || '');
  }, [value]);

  // =============================================
  // Return
  // =============================================
  return (
    <KiteSelect
      id={id}
      name={inputName}
      value={selectedOption}
      onChange={handleChange}
      maxWidth="none"
      placeholder={selectedOption ? '' : 'Make Selection'}
      disabled={isDisabled}
    >
      {selectOptions}
    </KiteSelect>
  );
};

export default UCQSelect;
