import { TProductList } from 'types';

export type TDisableConfigFamily =
  | 'DFI Speed Discretion'
  | 'DFI Static IP'
  | 'Ethernet Speed Discretion'
  | 'Enterprise Internet Switch'
  | 'Static IP'
  | 'Managed WiFi'
  | 'Wireless Internet'
  | 'Wireless Backup'
  | 'MRS'
  | 'Demarc Extension'
  | 'Demarc Extension Promo'
  | 'Cloud Connect Single Discretion'
  | 'Cloud Connect Dual Discretion'
  | 'Trunk PRI'
  | 'Trunk SIP'
  | 'MNE Network Speed'
  | 'ENE Network Speed'
  | 'Virtual FortiGate'
  | 'MNE Virtual MX'
  | 'Insights Licensing'
  | 'SD-WAN Plus Licensing'
  | 'Wireless Internet Device Selection'
  | 'MNE Advanced Analytics'
  | 'MNE Device Management'
  | 'ENE Device Management';

type DisabledOptions = 'On' | 'Off' | 'LowSpeed' | 'HighSpeed';

type TDisabledConfig = {
  [Property in TDisableConfigFamily]: {
    relationship?: 'Or' | 'And';
    rules: {
      dependentFamily: TProductList;
      status: DisabledOptions[];
    }[];
  };
};

export const disabledRules: TDisabledConfig = {
  'DFI Speed Discretion': {
    rules: [{ dependentFamily: 'DFI Speed', status: ['Off'] }],
  },
  'DFI Static IP': {
    rules: [{ dependentFamily: 'DFI Speed', status: ['Off'] }],
  },
  'Ethernet Speed Discretion': {
    rules: [
      { dependentFamily: 'Ethernet Speed', status: ['Off', 'HighSpeed'] },
    ],
  },
  'Enterprise Internet Switch': {
    rules: [{ dependentFamily: 'Enterprise Internet', status: ['Off'] }],
  },
  'Static IP': {
    rules: [
      // { dependentFamily: 'Enterprise Internet', status: ['Off'] },
      { dependentFamily: 'MNE Network Speed', status: ['On'] },
      { dependentFamily: 'ENE Network Speed', status: ['On'] },
    ],
  },
  'Managed WiFi': {
    rules: [
      {
        dependentFamily: 'DFI Speed',
        status: ['Off'],
      },
      {
        dependentFamily: 'MNE Network Speed',
        status: ['On'],
      },
      {
        dependentFamily: 'ENE Network Speed',
        status: ['On'],
      },
    ],
  },
  'Wireless Internet': {
    rules: [{ dependentFamily: 'Wireless Backup', status: ['On'] }],
  },
  'Wireless Backup': {
    rules: [
      { dependentFamily: 'DFI Speed', status: ['Off', 'LowSpeed'] },
      { dependentFamily: 'MNE Network Speed', status: ['On'] },
      { dependentFamily: 'ENE Network Speed', status: ['On'] },
    ],
  },
  MRS: {
    rules: [
      { dependentFamily: 'DFI Speed', status: ['Off', 'HighSpeed'] },
      { dependentFamily: 'Ethernet Speed', status: ['HighSpeed'] },
      { dependentFamily: 'MNE Network Speed', status: ['On'] },
      { dependentFamily: 'ENE Network Speed', status: ['On'] },
    ],
  },

  'Demarc Extension': {
    relationship: 'And',
    rules: [
      { dependentFamily: 'DFI Speed', status: ['Off'] },
      { dependentFamily: 'Ethernet Speed', status: ['Off'] },
    ],
  },
  'Demarc Extension Promo': {
    relationship: 'And',
    rules: [{ dependentFamily: 'Demarc Extension', status: ['Off'] }],
  },
  'Cloud Connect Single Discretion': {
    rules: [{ dependentFamily: 'Cloud Connect Single', status: ['Off'] }],
  },
  'Cloud Connect Dual Discretion': {
    rules: [{ dependentFamily: 'Cloud Connect Dual', status: ['Off'] }],
  },
  'Trunk PRI': { rules: [{ dependentFamily: 'DFI Speed', status: ['Off'] }] },
  'Trunk SIP': { rules: [{ dependentFamily: 'DFI Speed', status: ['Off'] }] },
  'MNE Network Speed': {
    rules: [
      { dependentFamily: 'Managed WiFi', status: ['On'] },
      { dependentFamily: 'ENE Network Speed', status: ['On'] },
      { dependentFamily: 'ENE Device Management', status: ['On'] },
    ],
  },
  'ENE Network Speed': {
    rules: [
      { dependentFamily: 'Managed WiFi', status: ['On'] },
      { dependentFamily: 'MNE Network Speed', status: ['On'] },
      { dependentFamily: 'MNE Device Management', status: ['On'] },
    ],
  },
  'MNE Virtual MX': {
    rules: [{ dependentFamily: 'MNE Network Speed', status: ['Off'] }],
  },
  'Insights Licensing': {
    rules: [
      { dependentFamily: 'MNE Network Speed', status: ['Off'] },
      { dependentFamily: 'SD-WAN Plus Licensing', status: ['On'] },
    ],
  },
  'SD-WAN Plus Licensing': {
    rules: [
      { dependentFamily: 'MNE Network Speed', status: ['Off'] },
      { dependentFamily: 'Insights Licensing', status: ['On'] },
    ],
  },
  'MNE Advanced Analytics': {
    rules: [{ dependentFamily: 'MNE Network Speed', status: ['Off'] }],
  },
  'Virtual FortiGate': {
    rules: [
      { dependentFamily: 'MNE Network Speed', status: ['On'] },
      { dependentFamily: 'MNE WiFi', status: ['On'] },
      { dependentFamily: 'MNE Switches', status: ['On'] },
      { dependentFamily: 'MNE Cameras', status: ['On'] },
      { dependentFamily: 'MNE Remote Workers', status: ['On'] },
      { dependentFamily: 'MNE Sensors', status: ['On'] },
    ],
  },
  'MNE Device Management': {
    rules: [
      { dependentFamily: 'ENE Device Management', status: ['On'] },
      { dependentFamily: 'ENE Network Speed', status: ['On'] },
    ],
  },
  'ENE Device Management': {
    rules: [
      { dependentFamily: 'MNE Device Management', status: ['On'] },
      { dependentFamily: 'MNE Network Speed', status: ['On'] },
    ],
  },
  'Wireless Internet Device Selection': {
    rules: [
      { dependentFamily: 'Wireless Internet Service Plan', status: ['Off'] },
    ],
  },
};
