// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux

// Components
import { KiteLoader } from '@kite/react-kite';
import {
  UCQForm,
  UCQFormNav,
  UCQHeader,
  UCQReminders,
} from 'components/ucQuestions';

// Hooks
import { useParams } from 'react-router-dom';
import { useGetFields, useGetSubmissions, useUcqApproval } from 'hooks';

// Utils
import { nameToUrlParam, UCQFORMS } from 'utils';

// Types
import { IEstimate, IForm, IUCQHeaderProps } from 'types';

// Styles
import './UCQLayout.scss';

export interface IUCQLayoutProps {
  /** Form data from query */
  forms?: IForm[];
  /** Estimate data from query */
  estimate?: IEstimate;
  /** Determines if form data is currently loading */
  formsLoading: boolean;
}

// const defaultShareText = {
//   label: 'Share With Team',
//   icon: 'share-f',
// };

/** Layout for UC Questions page */

const UCQLayout = ({ forms = [], estimate, formsLoading }: IUCQLayoutProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  // const [helpHtml, setHelpHtml] = useState('');

  const tabOptions = useMemo(
    () => Array.from(new Set(forms.map((form) => form.type))),
    [forms]
  );

  // const [shareText, setShareText] = useState(defaultShareText);

  // initial tab is being set in useEffect below
  const [tab, setTab] = useState('');

  const {
    formName = 'general-discovery',
    locationId = 'general',
    estimateId,
  } = useParams();

  const submissionParams = useMemo(() => {
    if (estimate && locationId) {
      return {
        locationId: locationId === 'general' ? undefined : locationId,
        estimateId: estimate.id,
      };
    }
  }, [estimate, locationId]);

  const { data: currentSubmissions, isLoading: currentSubmissionsLoading } =
    useGetSubmissions({ params: submissionParams });

  // Need consistent allSubmissions to handle reminders badge logic for SME
  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId },
  });

  const { data: allFields } = useGetFields({ params: { type: UCQFORMS } });

  const { requiresSparcPreContract } = useUcqApproval({
    submissions: allSubmissions,
    fields: allFields,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const filteredForms = useMemo(
    () => forms.filter((form) => form.type === tab),
    [forms, tab]
  );

  const currentFormId = useMemo(
    () => forms?.find((f) => nameToUrlParam(f.name) === formName)?.id || '',
    [formName, forms]
  );

  const currentForm = useMemo(
    () => forms?.find((form) => form?.id === currentFormId),
    [currentFormId, forms]
  );

  const ucqFormIdsList = useMemo(() => forms?.map((f) => f.id), [forms]);

  const recentSubmission = useMemo(() => {
    if (currentSubmissions && currentSubmissions.length) {
      const filteredCurrentSubmissions = currentSubmissions.filter(
        (item) => item.formId && ucqFormIdsList.includes(item.formId)
      );

      return filteredCurrentSubmissions.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      })[0];
    }
  }, [currentSubmissions, ucqFormIdsList]);

  const lastUpdatedBy: IUCQHeaderProps['lastUpdatedBy'] = useMemo(() => {
    if (recentSubmission) {
      const { editedBy: pid, updatedAt: dateUpdated } = recentSubmission;
      return { pid, dateUpdated };
    }

    return {
      pid: '',
      dateUpdated: '',
    };
  }, [recentSubmission]);

  const isLoading = formsLoading || currentSubmissionsLoading;

  const isMdfIdf = formName === 'network-draft';

  // =============================================
  // Interaction Handlers
  // =============================================
  const handleFocusChange = useCallback((htmlString: string) => {
    // setHelpHtml(htmlString);
  }, []);

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (currentForm) {
      setTab(currentForm.type);
    } else {
      setTab(tabOptions[0]);
    }
  }, [currentForm, tabOptions]);

  useEffect(() => {
    const focusHandler = () => {
      // setShareText(defaultShareText);
    };
    window.addEventListener('focus', focusHandler);
    return () => {
      window.removeEventListener('focus', focusHandler);
    };
  }, []);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="ucq-layout">
      <div className="ucq-layout__upper">
        <UCQHeader
          opptyName={estimate?.name || ''}
          locations={estimate?.locations || []}
          lastUpdatedBy={lastUpdatedBy}
        />
        <div className="ucq-layout__upper-sub">
          <h2>UC Questions</h2>
          <div></div>

          <UCQReminders
            estimate={estimate}
            requiresSparcPreContract={requiresSparcPreContract}
          />
        </div>
      </div>
      <div
        className="ucq-layout__content"
        style={isMdfIdf ? { gridTemplateColumns: 'min-content 1fr' } : {}}
      >
        {isLoading && <KiteLoader className="ucq-layout__form-loader" />}
        {currentForm && currentSubmissions && (
          <>
            <UCQFormNav
              forms={filteredForms}
              submissions={currentSubmissions}
            />
            <UCQForm
              form={currentForm}
              formSubmissions={currentSubmissions}
              onFocusChange={handleFocusChange}
              allSubmissions={allSubmissions}
              estimate={estimate}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UCQLayout;
