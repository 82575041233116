// Packages
import React, { useCallback, useMemo, useState } from 'react';

// Redux

// Components
import {
  ProgressBar,
  MNEHistoryDrawer,
  MneEneBadgeStatus,
  MneHospitalityBadgeStatus,
} from 'components';
import { KiteButton, KiteSelect, KiteLoader } from '@kite/react-kite';

// Hooks
import { useGetEstimateDetails, useGetSubmissions, useGetOffers } from 'hooks';
import useMNELocationCompletePercent from 'hooks/MNEuseLocationCompletePercent';
import { useParams } from 'react-router-dom';

// Utils

// Types

// Styles
import './MneReviewLocationHeader.scss';
import {
  campusLivingFieldId,
  ENEFORMS,
  hospitalityId,
  MNEFORMS,
} from 'utils/defaultsAndConstants/constants';
import { MForm, IOfferData } from 'types';
import { useGenerateISWBudget } from 'hooks/useGenerateISWBudget';
import { TextArea } from '@kite/react-kite-plus';

export interface IMneReviewLocationHeaderProps {
  opptyName: string;
  locationName: string;
  hasBadges?: boolean;
  hasHistory?: boolean;
  hasProgress?: boolean;
  isMNE?: boolean;
  forms: MForm[];
}

/** Location header on the read only page  */

const MneReviewLocationHeader = ({
  opptyName,
  locationName,
  hasBadges,
  hasHistory,
  hasProgress,
  isMNE,
  forms,
}: IMneReviewLocationHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { estimateId, locationId = '' } = useParams();
  const [selectedDeviceName, setSelectedDeviceName] = useState<string>('');

  const { estimateData } = useGetEstimateDetails(estimateId);

  const submissionParams = useMemo(() => {
    if (estimateData && locationId) {
      return {
        locationId: locationId === 'general' ? undefined : locationId,
        estimateId: estimateData.id,
      };
    }
  }, [estimateData, locationId]);

  const { data: currentSubmissions } = useGetSubmissions({
    params: submissionParams,
  });

  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId },
  });

  //zip,cost
  const iswBudget = useGenerateISWBudget({
    submissions: currentSubmissions,
    type: isMNE ? 'MNE' : 'ENE',
    estimateData: estimateData!,
    currentLocationId: locationId,
  });

  const { data: deviceOffers, isLoading: offersLoading } = useGetOffers({
    endpoint: 'device-offer-mapper',
    params: {},
  });

  const filteredDeviceOffers = useMemo(
    () =>
      deviceOffers?.filter(
        (offer: IOfferData) => offer.type === (isMNE ? 'MNE' : 'ENE')
      ),
    [deviceOffers, isMNE]
  );

  const groupedDeviceOffers = useMemo(() => {
    return (
      filteredDeviceOffers?.reduce((acc, offer) => {
        if (!acc[offer.deviceName]) {
          acc[offer.deviceName] = [];
        }
        acc[offer.deviceName].push(offer);
        return acc;
      }, {} as Record<string, IOfferData[]>) || {}
    );
  }, [filteredDeviceOffers]);

  const hospitality = allSubmissions?.find((f) => f.fieldId === hospitalityId);

  const campusLiving = allSubmissions?.find(
    (p) => p.fieldId === campusLivingFieldId
  );

  const formId = isMNE
    ? hospitality?.answer
      ? '751f9001-3062-43e8-9782-e18634b55a20'
      : campusLiving?.answer
      ? 'c6f45363-a1d6-479c-8559-37830748e515'
      : '2da13a40-238e-484b-8f9a-425956d7ff94'
    : 'dc9ad13c-90e7-45ea-8100-d93672c3c2eb';

  const currentFormSubmissions = useMemo(
    () => currentSubmissions?.filter((sub) => sub.formId === formId),
    [currentSubmissions, formId]
  );

  const { completePercent } = useMNELocationCompletePercent({
    submissions: currentFormSubmissions || [],
    locationId,
    questionType: isMNE ? MNEFORMS : ENEFORMS,
    currentFormId: formId,
    forms: forms?.filter((f) => !f.name.includes('General')),
  });

  const [historyDrawerIsOpen, setHistoryDrawerIsOpen] = useState(false);
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleHistoryDrawerIsOpen = useCallback(() => {
    setHistoryDrawerIsOpen(!historyDrawerIsOpen);
  }, [historyDrawerIsOpen]);

  const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDeviceName(event.target.value);
  };

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-review-location-header">
      <div className="mne-review-location-header__top">
        <div className="container">
          <p className="mne-review-location-header__top__oppty-name">
            {opptyName}
          </p>
        </div>
        {hasHistory && (
          <KiteButton onClick={toggleHistoryDrawerIsOpen} type="outline">
            History
          </KiteButton>
        )}
      </div>
      <div className="mne-review-location-header__lower">
        <div className="mne-review-location-header__lower-left">
          <h1 className="mne-review-location-header__lower__title">
            {locationName}
            <div className="mne-review-location-header__lower__isw">
              {' '}
              Wiring Budget:{' '}
              <span className="mne-review-location-header__lower__isw-value">
                ${iswBudget}
              </span>
            </div>
          </h1>

          {completePercent > 0 && (
            <div className="mne-review-location-header__device-container">
              <KiteSelect
                id="device-select"
                label={`${isMNE ? 'MNE' : 'ENE'} Device`}
                name="devices"
                value={selectedDeviceName}
                onChange={handleDeviceChange}
                className="device-dropdown"
              >
                <option value="">Select a device</option>
                {Object.keys(groupedDeviceOffers).map((deviceName) => (
                  <option key={deviceName} value={deviceName}>
                    {deviceName}
                  </option>
                ))}
              </KiteSelect>
              {selectedDeviceName && (
                <div className="mne-review-location-header__device-offers">
                  <div className="mne-review-location-header__device-offers-title">
                    {`Salesforce Offer`}
                  </div>
                  {offersLoading ? (
                    <KiteLoader size="small" />
                  ) : (
                    <TextArea
                      id="device-offers-textarea"
                      name="deviceOffers"
                      placeholder="No offer available"
                      value={
                        groupedDeviceOffers[selectedDeviceName]
                          ?.map((offer: IOfferData) => offer.offerName)
                          .join('\n') || ''
                      }
                      height="45px"
                      width="90%"
                      rows="1"
                      inputProps={{ className: 'custom-offer-textbox' }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="mne-review-location-header__lower-right">
          {hasProgress && (
            <ProgressBar
              percentage={completePercent || 0}
              title={isMNE ? 'MNE Question Progress' : 'ENE Question Progress'}
            />
          )}
        </div>
      </div>
      {hospitality?.answer && isMNE ? (
        <MneHospitalityBadgeStatus
          submissions={currentSubmissions}
          isHospitality={true}
        />
      ) : campusLiving?.answer && isMNE ? (
        <MneHospitalityBadgeStatus
          submissions={currentSubmissions}
          isHospitality={false}
        />
      ) : (
        <MneEneBadgeStatus
          submissions={currentSubmissions}
          type={isMNE ? 'MNE' : 'ENE'}
          disableBadge={true}
        />
      )}
      {historyDrawerIsOpen && (
        <MNEHistoryDrawer
          isOpen={historyDrawerIsOpen}
          onClose={toggleHistoryDrawerIsOpen}
          questionType={isMNE ? MNEFORMS : ENEFORMS}
        />
      )}
    </div>
  );
};

export default MneReviewLocationHeader;
