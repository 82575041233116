import { TableValues } from 'types';

export const formatPrice = (price: number | string) => {
  if (price === '-') return '-';
  if (!price || price === '0') return '-';
  if (price === 'ICB') return 'ICB';

  const priceValue =
    typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2);

  let [dollars, cents] = priceValue.split('.');
  const negative = dollars.startsWith('-');
  if (negative) {
    dollars = dollars.slice(1);
  }
  const localeString = parseInt(dollars).toLocaleString();
  return `${negative ? '-' : ''}$${localeString}.${cents}`;
};

export const formatOutputValues = (outputValues: TableValues<number>) => {
  const formattedOutputs = Object.entries(outputValues).reduce(
    (acc: TableValues<string>, [key, value]) => {
      acc[key as keyof TableValues<string>] = formatPrice(value);
      return acc;
    },
    { rateMrc: '', rateNrc: '', solutionMrc: '', solutionNrc: '' }
  );

  return formattedOutputs;
};
