// Packages
import React, { useState, useMemo, useCallback, useEffect } from 'react';

// Redux

// Components
import { KiteIcon, KiteLink, KiteButton } from '@kite/react-kite';
import { LabelledInput } from 'components';

// Hooks
import { useAnalytics, useQueryData, useUpdateEstimate } from 'hooks';

// Utils
import webexLogo from '../../images/WebexLogo.png';

// Types

// Styles
import './WebexSpaceCard.scss';

export interface IWebexSpaceCardProps {
  className?: string;
}

/** Card that provides the link to a webex space if it exists on the estimate. Otherwise, it presents an input field where a user can input a webex space Id for a group chat. */

const WebexSpaceCard = ({ className = '' }: IWebexSpaceCardProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { currentEstimate } = useQueryData();

  const webexLink = currentEstimate?.webexSpaceUrl || '';

  const [webexLinkInputValue, setWebexLinkInputValue] =
    useState<string>(webexLink);

  const [linkError, setLinkError] = useState('');

  const [editWebexLink, setEditWebexLink] = useState(false);

  const { updateEstimate } = useUpdateEstimate();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  // cool variable name
  const webexRegex = useMemo(
    () => /^webexteams:\/\/im\?space=[\d | \w][\d | \w | -]{1,}$/,
    []
  );

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleEdit = useCallback(() => {
    setEditWebexLink(!editWebexLink);
    setWebexLinkInputValue(webexLink);
  }, [editWebexLink, webexLink]);

  const handleClick = useCallback(() => {
    if (!webexLinkInputValue.match(webexRegex) && !linkError) {
      setLinkError('Please enter a valid webex link');
      return;
    }

    if (linkError) {
      toggleEdit();
      setLinkError('');
      setWebexLinkInputValue(webexLink);
      return;
    }

    if (currentEstimate && webexLinkInputValue !== webexLink) {
      // eliminating submissions from payload to avoid huge payload size
      const filterPayload = (currentEstimate: any, propertiesToIgnore: any) => {
        const filteredPayload = { ...currentEstimate };
        propertiesToIgnore.forEach((prop: any) => {
          delete filteredPayload[prop];
        });
        return filteredPayload;
      };

      const propertiesToIgnore = ['submissions'];
      const filteredEstWithoutSub = filterPayload(currentEstimate, propertiesToIgnore);
      updateEstimate({
        ...filteredEstWithoutSub,
        webexSpaceUrl: webexLinkInputValue,
      });
      trackSelectAction(`Webex Space Link Updated`, {
        opType: 'inputFieldUpdate',
      });
    }

    setLinkError('');
    setEditWebexLink(false);
  }, [
    webexLinkInputValue,
    webexRegex,
    linkError,
    currentEstimate,
    webexLink,
    toggleEdit,
    updateEstimate,
    trackSelectAction,
  ]);

  // =============================================
  // Render Methods
  // =============================================
  const webexImg = useMemo(
    () => (
      <div className="webex-space-card__chat-card-info">
        <img
          src={webexLogo}
          alt="webexLogo"
          className="webex-space-card__chat-card-info-avatar"
        />
      </div>
    ),
    []
  );

  const webexTeamChat = useMemo(() => {
    return (
      <div className="webex-space-card__chat-card">
        {webexImg}
        <KiteLink
          className="webex-space-card__chat-card-link"
          href={webexLink}
          aria-label="Chat with team on webex space"
        >
          Webex Space Link
        </KiteLink>
        <KiteButton
          type="standalone-link"
          minWidth="min-content"
          maxWidth="min-content"
          onClick={toggleEdit}
        >
          <KiteIcon name="edit" />
        </KiteButton>
      </div>
    );
  }, [toggleEdit, webexImg, webexLink]);

  const webexInput = useMemo(() => {
    return !webexLinkInputValue && !editWebexLink ? (
      <div className="webex-space-card__chat-card">
        {webexImg}
        <KiteButton
          className="webex-space-card__input-card-button "
          minWidth="min-content"
          maxWidth="min-content"
          onClick={handleClick}
          type="outline"
        >
          Add Webex Space Link
        </KiteButton>
      </div>
    ) : (
      <div className="webex-space-card__input-card">
        <LabelledInput
          label="Webex Space Link"
          type="text"
          initValue={webexLinkInputValue}
          onChange={setWebexLinkInputValue}
          error={linkError}
        />
        <KiteButton
          className="webex-space-card__input-card-button "
          minWidth="min-content"
          maxWidth="min-content"
          onClick={handleClick}
        >
          {!linkError && webexLinkInputValue !== webexLink
            ? 'Update Link'
            : 'Cancel'}
        </KiteButton>
      </div>
    );
  }, [
    webexLink,
    editWebexLink,
    webexImg,
    handleClick,
    webexLinkInputValue,
    linkError,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    if (linkError && webexLinkInputValue.match(webexRegex)) {
      setLinkError('');
    }

    if (!webexLinkInputValue.match(webexRegex) && !linkError) {
      setLinkError('Please enter a valid webex link');
    }
  }, [linkError, webexLinkInputValue, webexRegex]);

  // =============================================
  // Final Return
  // =============================================
  return (
    <div className={`webex-space-card ${className}`}>
      {!!webexLink && !editWebexLink ? webexTeamChat : webexInput}
    </div>
  );
};

export default WebexSpaceCard;
