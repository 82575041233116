// Packages
import React, { useState, useMemo, useCallback } from 'react';

// Redux

// Components
import { KiteButton, KiteCard, KiteIcon } from '@kite/react-kite';
import { SalesTeamCard, WebexSpaceCard, EditSalesTeamModal } from 'components';

// Hooks
import { useQueryData, useTrackOpenState, useUpdateEstimate } from 'hooks';

// Utils

// Types
import { IEstimate } from 'types';

// Styles
import './SalesTeamContainer.scss';

export interface ISalesTeamContainerProps {
  className?: string;
}

/** Shows active members of a sales team with links to individual emails and webex chats, as well as a group webex space for the team.*/

const SalesTeamContainer = ({ className = '' }: ISalesTeamContainerProps) => {
  // =============================================
  // State/Refs
  // =============================================
  const { trackOpenState } = useTrackOpenState();
  const { currentEstimate } = useQueryData();
  const { updateEstimate } = useUpdateEstimate();

  const salesTeamData: IEstimate['salesTeam'] = useMemo(() => {
    if (currentEstimate && currentEstimate.salesTeam) {
      return currentEstimate.salesTeam;
    }
    return {};
  }, [currentEstimate]);

  const [showEditTeamModal, setShowEditTeamModal] = useState(false);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================
  const toggleEditTeam = useCallback(() => {
    setShowEditTeamModal(!showEditTeamModal);
    trackOpenState({
      name: 'Edit Sales Team Modal',
      isOpen: showEditTeamModal,
    });
  }, [showEditTeamModal, trackOpenState]);

  const saveTeam = useCallback(
    (salesTeam: IEstimate['salesTeam']) => {
      if (currentEstimate) {
        // eliminating submissions from payload to avoid huge payload size
        const filterPayload = (currentEstimate: any, propertiesToIgnore: any) => {
          const filteredPayload = { ...currentEstimate };
          propertiesToIgnore.forEach((prop: any) => {
            delete filteredPayload[prop];
          });
          return filteredPayload;
        };

        const propertiesToIgnore = ['submissions'];
        const filteredEstWithoutSub = filterPayload(currentEstimate, propertiesToIgnore);

        updateEstimate({ ...filteredEstWithoutSub, salesTeam });
      }
    },
    [currentEstimate, updateEstimate]
  );

  // =============================================
  // Render Methods
  // =============================================
  const salesTeamCards = useMemo(() => {
    return Object.entries(salesTeamData).map(([pid, role]) => (
      <SalesTeamCard key={pid} pid={pid} role={role} />
    ));
  }, [salesTeamData]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <KiteCard className={`sales-team-container ${className}`}>
      <div className="sales-team-container__header">
        <p>SALES TEAM</p>
        <KiteButton
          type="standalone-link"
          minWidth="min-content"
          maxWidth="min-content"
          onClick={toggleEditTeam}
        >
          <KiteIcon name="edit" />
        </KiteButton>
      </div>
      <div className="sales-team-container__cards">
        {salesTeamCards}
        <WebexSpaceCard />
      </div>
      <EditSalesTeamModal
        salesTeamData={salesTeamData}
        canShow={showEditTeamModal}
        toggleOpen={toggleEditTeam}
        onSave={saveTeam}
      />
    </KiteCard>
  );
};

export default SalesTeamContainer;
