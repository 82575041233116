import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { userIdData } from 'redux/userIDSlice';
import { productFocus } from 'redux/productFocusSlice';
import { partnerLinkUser } from 'redux/partnerLinkUserSlice';
import {
  currentSelectionIds,
  setBundleTerm,
  setDuplicate,
  setLocationId,
  setScenarioId,
} from 'redux/currentSelectionIds';
import { isAdmin as isAdminSlice } from 'redux/isAdminSlice';

import {
  IBundle,
  IEstimate,
  ILocation,
  ILocationSelection,
  IProduct,
  IProductFamily,
  IProfile,
  IProfileCategory,
  IScenario,
  IServiceCodes,
  TProductFocus,
  IScenarioTabLocationIds,
  TTermLengthMonths,
  TEipTermLengthMonths,
  IPromo,
  IScenarioPromo,
  IOfferData,
} from 'types';
import { useQueryClient } from 'react-query';

import { collatorSort, filterPartnerLinkProducts, queryKeys } from 'utils';
import { useCallback, useEffect, useRef } from 'react';
import { tabLocationIds, updateTabLocationIds } from 'redux/scenarioTabSlice';
import { userEmailData } from 'redux/userEmailSlice';

interface IQueryDataState {
  currentEstimate: IEstimate | undefined;
  allProducts: IProduct[];
  allProductFamilies: IProductFamily[];
  allEstimates: IEstimate[];
  allProfileCategories: IProfileCategory[];
  allProfiles: IProfile[];
  allServiceCodes: IServiceCodes[];
  estimateScenarios: IScenario[];
  allBundles: IBundle[];
  currentScenario: IScenario | undefined;
  currentSelections: ILocationSelection[];
  allSelections: ILocationSelection[];
  currentLocation: ILocation | undefined;
  currentBundle: IBundle | undefined;
  currentTerm: TTermLengthMonths;
  currentEipTerm: TEipTermLengthMonths;
  allPromos: IPromo[];
  currentScenarioPromos: IScenarioPromo[] | undefined;
  duplicateScenario?: { id: string; isDuplicate: boolean } | undefined;
  isScenarioDuplicate: (scenarioId: string, isDuplicate: boolean) => void;
  allLocations: ILocation[];
}

export interface IQueryData extends IQueryDataState {
  updateBundleTerm: (term: TTermLengthMonths) => void;
  userId: string;
  userEmail: string;
  /** Redux state used to focus on rows in product table when making product selections */
  currentProductFocus: TProductFocus;
  isPartnerLinkUser: boolean;
  /** Selected scenarioId in redux */
  scenarioId: string;
  /** Selected locationId in redux */
  locationId: string;
  /** Key value pair of scenarioId -> locationId that is used to recall selected locationId when switching scenario tabs */
  scenarioTabLocationIds: IScenarioTabLocationIds;
  /** Updates the selected scenarioId in redux */
  updateScenarioId: (id: string) => void;
  /** Updates the selected locationId in redux, effect in useQueryData will keep it aligned with `:locationId` url param */
  updateLocationId: (id: string) => void;
  /** Updates the scenario tab locationIds in reudx */
  updateScenarioTabs: (scenarioId: string, locationId: string) => void;
  isSaved: boolean;
  bundleId?: string;
  loadEmptyBundle: () => Partial<IBundle>;
  isAdmin: boolean;
}

export const useQueryData = (): IQueryData => {
  const queryClient = useQueryClient();
  const {
    estimateId = '',
    bundleId,
    locationId: locationIdParam,
  } = useParams();
  const userId = useSelector(userIdData);
  const userEmail = useSelector(userEmailData);
  const currentProductFocus = useSelector(productFocus);
  const isPartnerLinkUser = useSelector(partnerLinkUser);
  const isAdmin = useSelector(isAdminSlice);
  const selectionIds = useSelector(currentSelectionIds);
  const { scenarioId, locationId, bundleTerm, isDuplicate } = selectionIds;
  const scenarioTabLocationIds = useSelector(tabLocationIds);
  const dispatch = useDispatch();

  // Query Client Saved State, used for autosave badge
  const isSaved = useRef(true);
  isSaved.current = !queryClient.isMutating();

  queryClient.defaultMutationOptions({
    onError: () => {
      isSaved.current = false;
    },
  });

  const sortSelections = useCallback(
    (selections: ILocationSelection[], allProfiles: IProfile[]) => {
      return selections.sort((a, b) => {
        const { profileId: profileIdA, familyCategory: categoryA } = a;
        const { profileId: profileIdB, familyCategory: categoryB } = b;

        // Leave product order but sort profile selections by profile name and after other UC Products
        const isBothProfile = profileIdA && profileIdB;

        if (isBothProfile) {
          const profileA = allProfiles.find((p) => p.id === profileIdA);
          const profileB = allProfiles.find((p) => p.id === profileIdB);
          if (profileA && profileB) {
            return collatorSort(profileA.name, profileB.name);
          }
        } else if (profileIdA && categoryB === 'UC Products') {
          return 1;
        } else if (profileIdB && categoryA === 'UC Products') {
          return -1;
        }

        return 0;
      });
    },
    []
  );

  const updateBundleTerm = useCallback(
    (term: TTermLengthMonths) => {
      dispatch(setBundleTerm(term));
    },
    [dispatch]
  );
  const updateScenarioId = useCallback(
    (id: string) => dispatch(setScenarioId(id)),
    [dispatch]
  );
  const updateLocationId = useCallback(
    (id: string) => dispatch(setLocationId(id)),
    [dispatch]
  );

  const updateScenarioTabs = useCallback(
    (scenarioId: string, locationId: string) => {
      dispatch(updateTabLocationIds({ [scenarioId]: locationId }));
    },
    [dispatch]
  );

  const isScenarioDuplicate = useCallback(
    (scenarioId: string, isDuplicate: boolean) => {
      dispatch(setDuplicate({ id: scenarioId, isDuplicate }));
    },
    [dispatch]
  );

  const loadEmptyBundle = useCallback(() => {
    const bundleKey = queryKeys().all.bundles;
    const emptyBundle: Partial<IBundle> = {
      id: 'new-bundle',
      name: 'New Bundle',
      selections:
        filterPartnerLinkProducts(
          queryClient.getQueryData<IBundle[]>(queryKeys().all.bundles) || [],
          isPartnerLinkUser
        )?.find((b) => b.id === 'new-bundle')?.selections || [],
      featured: false,
      published: false,
      description: '',
    };
    queryClient.setQueryData(bundleKey, [
      ...(queryClient.getQueryData<IBundle[]>(bundleKey) || []),
      emptyBundle,
    ]);
    return emptyBundle;
  }, [isPartnerLinkUser, queryClient]);

  const getQueryData = useCallback((): IQueryDataState => {
    const queryData = {
      allSelections:
        queryClient
          .getQueryData<IScenario[]>(
            queryKeys({ estimateId }).filter.estimateScenarios
          )
          ?.find((s) => s.id === scenarioId)?.selections || [],
      isScenarioDuplicate: isScenarioDuplicate,
      duplicateScenario:
        isDuplicate?.id === scenarioId ? isDuplicate : undefined,
      currentEstimate: queryClient.getQueryData<IEstimate>(
        queryKeys({ estimateId }).details.estimate
      ),
      allLocations:
        queryClient.getQueryData<IEstimate>(
          queryKeys({ estimateId }).details.estimate
        )?.locations || [],
      allProducts:
        queryClient.getQueryData<IProduct[]>(queryKeys().all.products) || [],
      allProductFamilies:
        queryClient.getQueryData<IProductFamily[]>(
          queryKeys().all.productFamilies
        ) || [],
      allEstimates:
        queryClient.getQueryData<IEstimate[]>(queryKeys().all.estimates) || [],
      allProfileCategories:
        queryClient.getQueryData<IProfileCategory[]>(
          queryKeys().all.profileCategories
        ) || [],
      allProfiles:
        queryClient.getQueryData<IProfile[]>(
          queryKeys({ scenarioId }).filter.profiles
        ) ||
        queryClient.getQueryData<IProfile[]>(queryKeys().all.profiles) ||
        [],
      allServiceCodes:
        queryClient.getQueryData<IServiceCodes[]>(
          queryKeys().all.serviceCodes
        ) || [],
      allPromos:
        queryClient.getQueryData<IPromo[]>(queryKeys().all.promos) || [],
      allOffers:
        queryClient.getQueryData<IOfferData[]>(queryKeys().all.offers) || [],
      estimateScenarios:
        queryClient.getQueryData<IScenario[]>(
          queryKeys({ estimateId }).filter.estimateScenarios
        ) || [],
      allBundles:
        queryClient
          .getQueryData<IBundle[]>(queryKeys().all.bundles)
          ?.map((b) => {
            if (isPartnerLinkUser) {
              return {
                ...b,
                selections: b.selections.filter(
                  (p) => p.familyCategory !== 'SBB 1-19 Pub/Priv'
                ),
              };
            }
            return b;
          }) || [],
      currentScenario: queryClient
        .getQueryData<IScenario[]>(
          queryKeys({ estimateId }).filter.estimateScenarios
        )
        ?.find((s) => s.id === scenarioId),
      currentTerm: bundleId
        ? bundleTerm
        : queryClient
            .getQueryData<IScenario[]>(
              queryKeys({ estimateId }).filter.estimateScenarios
            )
            ?.find((s) => s.id === scenarioId)?.term || '36',
      currentEipTerm:
        queryClient
          .getQueryData<IScenario[]>(
            queryKeys({ estimateId }).filter.estimateScenarios
          )
          ?.find((s) => s.id === scenarioId)
          ?.selections.filter((s) => s.locationId === locationId)
          .find((p) => p.eipTerm)?.eipTerm || 'Purchase',
      currentSelections: sortSelections(
        bundleId
          ? filterPartnerLinkProducts(
              queryClient.getQueryData<IBundle[]>(queryKeys().all.bundles) ||
                [],
              isPartnerLinkUser
            )?.find((b) => b.id === bundleId)?.selections || []
          : queryClient
              .getQueryData<IScenario[]>(
                queryKeys({ estimateId }).filter.estimateScenarios
              )
              ?.find((s) => s.id === scenarioId)
              ?.selections.filter((s) => s.locationId === locationId) || [],
        queryClient.getQueryData<IProfile[]>(
          queryKeys({ scenarioId }).filter.profiles
        ) ||
          queryClient.getQueryData<IProfile[]>(queryKeys().all.profiles) ||
          []
      ),
      currentLocation: queryClient
        .getQueryData<IEstimate>(queryKeys({ estimateId }).details.estimate)
        ?.locations?.find((l) => l.id === locationId),
      currentBundle: filterPartnerLinkProducts(
        queryClient.getQueryData<IBundle[]>(queryKeys().all.bundles) || [],
        isPartnerLinkUser
      )?.find((b) => b.id === bundleId),
      currentScenarioPromos: queryClient
        .getQueryData<IScenario[]>(
          queryKeys({ estimateId }).filter.estimateScenarios
        )
        ?.find((s) => s.id === scenarioId)
        ?.promos?.filter((sp) => {
          const promo = queryClient
            .getQueryData<IPromo[]>(queryKeys().all.promos)
            ?.find((p) => p.id === sp.promoId);
          if (promo?.isLocationSpecific) {
            return sp.locationId === locationId;
          }
          return true;
        }),
    };
    return queryData;
  }, [
    bundleId,
    bundleTerm,
    estimateId,
    isPartnerLinkUser,
    locationId,
    queryClient,
    scenarioId,
    sortSelections,
    isDuplicate,
    isScenarioDuplicate,
  ]);

  useEffect(() => {
    /** Make sure redux locationId always matches param (redux locationId is needed for SF push) */
    if (locationIdParam && locationId !== locationIdParam) {
      !locationIdParam.match(/^\w+$/) && updateLocationId(locationIdParam);
    }
  }, [locationId, locationIdParam, updateLocationId]);

  return {
    ...getQueryData(),
    updateBundleTerm,
    userId,
    userEmail,
    currentProductFocus,
    isPartnerLinkUser,

    scenarioId,
    locationId,
    scenarioTabLocationIds,
    updateScenarioId,
    updateLocationId,
    updateScenarioTabs,
    bundleId,
    isSaved: isSaved.current,
    loadEmptyBundle,
    isAdmin,
  };
};
