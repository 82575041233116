// External packages
import React, { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
// Redux

// Components

import { SortableTable } from '@kite/react-kite-plus';

// Utils

// Hooks

// Types
import { IAdminHistory } from 'types';

// Styles
import './AdminHistoriesPage.scss';
import { useGetAdminHistories } from 'hooks/apiHooks/adminHistories';
import { IAdminHistoryTableRow } from 'types/AdminHistory';
import { p } from 'msw/lib/glossary-dc3fd077';
import { KiteLoader } from '@kite/react-kite';

const AdminHistoriesPage = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { data: adminHistories, isLoading: allAdminHitoryLoading } =
    useGetAdminHistories();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const columns = useMemo(
    () => [
      {
        label: 'Updated By',
        sortEnabled: true,
        sortKey: 'updatedBy',
      },
      {
        label: 'Updated At',
        sortEnabled: true,
        sortKey: 'updatedAt',
      },
      {
        label: 'Activity',
        sortEnabled: true,
        sortKey: 'activity',
      },
    ],
    []
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  const formatTableData = useCallback(
    (adminHistory: IAdminHistory): IAdminHistoryTableRow => {
      return {
        id: adminHistory.id,
        updatedBy: adminHistory.updatedBy,
        updatedAt: dayjs(adminHistory.updatedAt).format('HH:mm, DD-MM-YYYY'),
        activity: adminHistory.activity,
      };
    },
    []
  );

  // Create table rows from adminHistories
  const featuredRows = useMemo(() => {
    if (!adminHistories || !adminHistories.length) return [];
    return adminHistories.map((history) => formatTableData(history));
  }, [adminHistories, formatTableData]);

  // =============================================
  // Early Return
  // =============================================
  if (allAdminHitoryLoading) {
    return <KiteLoader />;
  }

  // =============================================
  // Return
  // =============================================
  return (
    <div className="admin-histories">
      <div className="admin-histories-header">
        <h2 className="kite-h2">Admin Histories</h2>
      </div>
      {featuredRows ? (
        <div>
          <SortableTable
            loading={allAdminHitoryLoading}
            headerTitle="Records"
            columns={columns}
            tableData={featuredRows}
          />
        </div>
      ) : (
        <p>No records to show</p>
      )}
    </div>
  );
};

export default AdminHistoriesPage;
