import { useCallback } from 'react';
import { ILocationSelection } from 'types';
import { NONE } from 'utils';

const mneHierrachyRules = [
  {
    ruleName: 'Network Speed',
  },
  { ruleName: 'Quantity Check' },
  { ruleName: 'Enterprise Internet', exception: ['MNE WiFi'] },
  { ruleName: 'RC Seats', exception: ['MNE Switches'] },
  { ruleName: 'MNE Remote Workers', exception: ['MNE Remote Workers'] },
];

export const useMNEDeviceCountCheck = ({
  currentSelections,
}: {
  currentSelections: ILocationSelection[];
}) => {
  const findSelectedDevice = useCallback(
    (selections: ILocationSelection[], familyName: string) => {
      return selections.some(
        (selection) =>
          selection.familyName === familyName && selection.productId !== NONE
      );
    },
    []
  );

  // Check for exceptional cases for mne devices
  const exceptionMNEDevicesCheck = (
    devices: ILocationSelection[],
    device: ILocationSelection
  ) => {
    if (device.familyName === 'MNE WiFi') {
      return findSelectedDevice(devices, 'Enterprise Internet');
    }
    if (device.familyName === 'MNE Switches') {
      return devices.some(
        (selection) =>
          selection.familyName === 'RC Seats' &&
          selection.quantity >= 5 &&
          selection.productId !== NONE
      );
    }
    return false;
  };

  //Check if the selections meets the quantity rule
  const isMNEDeviceQuantityValid = useCallback(
    (selections: ILocationSelection[], locationId?: string) => {
      const networkSpeed = selections.some((selection) => {
        return (
          selection.familyName.includes('Network Speed') &&
          selection.productId !== NONE
        );
      });

      if (
        selections.every(
          (selection) =>
            selection.familyName === 'MNE Remote Workers' &&
            selection.productId !== NONE
        )
      ) {
        return true;
      }

      if (networkSpeed) {
        return true;
      }

      // Exclude the non mne and exceptional devices from mne devices quantity calculations
      const mneDevices = selections.filter(
        (selection) =>
          selection.familyCategory === 'MNE' &&
          selection.productId !== NONE &&
          selection.familyName !== 'MNE Remote Workers' &&
          selection.name !== 'Indoor Antenna' &&
          selection.name !== 'Outdoor Antenna'
      );

      //Check if the selections only contains non mne devices
      const nonMNEDevices = selections.some(
        (selection) =>
          selection.familyCategory !== 'MNE' && selection.productId !== NONE
      );

      if (nonMNEDevices && mneDevices.length === 0) {
        return true;
      }

      const mneDevicesCount = mneDevices.reduce(
        (total, selection) => total + selection.quantity,
        0
      );

      const containsSensors = findSelectedDevice(mneDevices, 'MNE Sensors');

      if (mneDevicesCount < 2) return false;
      if (containsSensors && mneDevicesCount < 3) return false;

      return true;
    },
    []
  );

  const isMNEDevicesValid = useCallback(
    (selections: ILocationSelection[], selection: ILocationSelection) => {
      //merge both exceptionRule and quanityCheck functions
      return (
        isMNEDeviceQuantityValid(selections) ||
        exceptionMNEDevicesCheck(selections, selection)
      );
    },
    []
  );

  const groupedDevicesByLocation = (selections: ILocationSelection[]) => {
    return selections.reduce((acc, selection) => {
      const locationId = selection.locationId || '';
      if (!acc[locationId]) {
        acc[locationId] = [];
      }
      acc[locationId].push(selection);
      return acc;
    }, {} as { [key: string]: ILocationSelection[] });
  };

  // Update removeDevice based on rules
  const updateMNERemoveDeviceStatus = useCallback(
    (selections: ILocationSelection[]) => {
      const groupedDevices = groupedDevicesByLocation(selections);

      return Object.values(groupedDevices).flatMap((devices) => {
        return devices.map((device) => {
          const { familyName, familyCategory } = device;

          const checkForMNE =
            familyCategory === 'MNE' && familyName !== 'MNE Remote Workers';
          return {
            ...device,
            removeDevice: checkForMNE
              ? !isMNEDevicesValid(devices, device)
              : false,
          };
        });
      });
    },
    []
  );

  return {
    isMNEDevicesValid,
    updateMNERemoveDeviceStatus,
    groupedDevicesByLocation,
    exceptionMNEDevicesCheck,
    isMNEDeviceQuantityValid,
  };
};

export default useMNEDeviceCountCheck;
