// External Packages
import React, { useCallback, useEffect, useMemo } from 'react';

// React Router
import {
  Navigate,
  useRoutes,
  useNavigate,
  useLocation,
} from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import { updatePartnerLinkUser } from 'redux/partnerLinkUserSlice';
import { storeUserID } from 'redux/userIDSlice';
import { storeIsAdmin } from 'redux/isAdminSlice';
import { storeUserName } from 'redux/userNameSlice';

// Pages
import {
  Bundles,
  Admin,
  DataPage,
  MnePage,
  SbbPage,
  EnePage,
  BundlesDashboard,
  BundleEditor,
  BusinessRulesPage,
  EstimateDashboard,
  EstimateDetailPage,
  UcAddonsPage,
  RcAddonsPage,
  UcProfilesPage,
  TrunksPage,
  PromoPage,
  UcQuestionsPage,
  RcQuestionsPage,
  ProductPricing,
  SalesforceLogin,
  UcReviewPage,
  RcReviewPage,
  SfFieldValRedirect,
  SalesforceReviewPage,
  SalesforceResultsPage,
  AddProductPrices,
  EstimateFormPage,
  ReportDashboard,
  MNEQuestionsPage,
  ENEQuestionsPage,
  AdminHistoriesPage,
} from 'pages';

// Components
import { KiteLoader } from '@kite/react-kite';
import {
  MainLayout,
  EstimateBuilderLayout,
  ConfiguratorDefault,
} from 'components';

// Utils

// Hooks
import { useGetInitialData } from 'hooks';

// Types
import { IAuthData } from 'types';
import OfferDashboard from 'pages/OfferDashboard';
import { storeUserEmail } from 'redux/userEmailSlice';

const Routes = ({
  userName,
  userEmail,
  pId,
  isPartnerLinkUser,
  isAdmin = false,
}: IAuthData) => {
  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    featureFlags: { pqeFlag },
  } = useGetInitialData();

  const { search, pathname } = useLocation();

  const defaultPath = useMemo(() => {
    let rootPath = 'dashboard';
    if (!pqeFlag || isPartnerLinkUser) {
      rootPath = 'estimate-builder/sandbox';
    }
    return pathname === '/' ? rootPath + search : pathname + search;
  }, [search, pathname, pqeFlag, isPartnerLinkUser]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    dispatch(storeUserID(pId));
  }, [dispatch, pId]);

  useEffect(() => {
    dispatch(updatePartnerLinkUser(isPartnerLinkUser));
  }, [isPartnerLinkUser, dispatch]);

  useEffect(() => {
    dispatch(storeIsAdmin(isAdmin));
  }, [dispatch, isAdmin]);

  useEffect(() => {
    dispatch(storeUserName(userName));
  }, [dispatch, userName]);

  useEffect(() => {
    dispatch(storeUserEmail(userEmail));
  }, [dispatch, userEmail]);

  // =============================================
  // Routes
  // =============================================
  const redirect = useCallback(
    (toPath?: string) => {
      if (pathname === toPath) {
        return {};
      }
      return {
        path: '*',
        element: <Navigate replace to={toPath || '.'} />,
      };
    },
    [pathname]
  );

  const dashboardRouting = useMemo(
    () => ({
      path: 'dashboard',
      children: [
        {
          index: true,
          element: <EstimateDashboard />,
        },
        {
          path: 'edit-estimate/:opportunityId',
          element: <EstimateFormPage />,
        },
        {
          path: 'edit-estimate/:opportunityId/:estimateId',
          element: <EstimateFormPage />,
        },
        {
          path: ':estimateId',
          element: <EstimateDetailPage />,
        },
        redirect(),
      ],
    }),
    [redirect]
  );

  const ucQuestionRouting = useMemo(
    () => ({
      path: 'uc-questions',
      children: [
        { index: true, element: <Navigate replace to="/dashboard" /> },
        {
          path: ':estimateId/:locationId/:formName',
          element: <UcQuestionsPage />,
        },
        redirect(),
      ],
    }),
    [redirect]
  );

  const rcQuestionRouting = useMemo(
    () => ({
      path: 'rc-questions',
      children: [
        { index: true, element: <Navigate replace to="/dashboard" /> },
        {
          path: ':estimateId/:locationId/:formName',
          element: <RcQuestionsPage />,
        },
        redirect(),
      ],
    }),
    [redirect]
  );

  const mneQuestionRouting = useMemo(
    () => ({
      path: 'mne-questions',
      children: [
        { index: true, element: <Navigate replace to="/dashboard" /> },
        {
          path: ':estimateId/:locationId/:formName',
          element: <MNEQuestionsPage />,
        },
        redirect(),
      ],
    }),
    [redirect]
  );

  const eneQuestionRouting = useMemo(
    () => ({
      path: 'ene-questions',
      children: [
        { index: true, element: <Navigate replace to="/dashboard" /> },
        {
          path: ':estimateId/:locationId/:formName',
          element: <ENEQuestionsPage />,
        },
        redirect(),
      ],
    }),
    [redirect]
  );

  const adminRouting = useMemo(
    () => ({
      path: 'admin',
      element: isAdmin ? <Admin /> : <Navigate to={defaultPath} />,
      children: [
        {
          path: 'product-pricing/:productCategory/:productName',
          element: <ProductPricing />,
        },
        {
          path: 'bundles',
          element: <BundlesDashboard />,
        },
        {
          path: 'bundles/editor',
          element: <BundleEditor />,
          children: [
            { path: 'data/:bundleId', element: <DataPage /> },
            { path: 'uc-products/:bundleId', element: <UcProfilesPage /> },
            { path: 'uc-addons/:bundleId', element: <UcAddonsPage /> },
            { path: 'rc-addons/:bundleId', element: <RcAddonsPage /> },
            { path: 'trunks/:bundleId', element: <TrunksPage /> },
            { path: 'mne/:bundleId', element: <MnePage /> },
            { path: 'ene/:bundleId', element: <EnePage /> },
            { path: 'sbb/:bundleId', element: <SbbPage /> },
          ],
        },
        {
          path: 'bundles/editor/:path/:bundleId',
          element: <BundleEditor />,
        },
        {
          path: 'business-rules',
          element: <BusinessRulesPage />,
        },
        {
          path: 'add-products',
          element: <AddProductPrices />,
        },
        {
          path: 'offer',
          element: <OfferDashboard />,
        },
        {
          path: 'admin-histories',
          element: <AdminHistoriesPage />,
        },
        redirect(),
      ],
    }),
    [redirect, isAdmin, defaultPath]
  );

  const configuratorPages = useMemo(
    () => [
      { index: true, element: <ConfiguratorDefault /> },
      {
        path: 'data',
        element: <DataPage />,
      },
      {
        path: 'trunks',
        element: <TrunksPage />,
      },
      {
        path: 'uc-profiles',
        element: <UcProfilesPage />,
      },
      {
        path: 'uc-addons',
        element: <UcAddonsPage />,
      },
      {
        path: 'mne',
        element: <MnePage />,
      },
      {
        path: 'ene',
        element: <EnePage />,
      },
      {
        path: 'rc-addons',
        element: <RcAddonsPage />,
      },
      {
        path: 'sbb',
        element: !isPartnerLinkUser ? (
          <SbbPage />
        ) : (
          <Navigate to={defaultPath} />
        ),
      },
      {
        path: 'bundles',
        element: <Bundles />,
      },
      {
        path: 'promos',
        element: <PromoPage />,
      },
      redirect(),
    ],
    [redirect, defaultPath, isPartnerLinkUser]
  );

  const estimateBuilderRouting = useMemo(
    () => ({
      path: 'estimate-builder',
      element: <EstimateBuilderLayout />,
      children: [
        {
          path: ':estimateId',
          children: configuratorPages,
        },
        redirect(),
      ],
    }),
    [configuratorPages, redirect]
  );

  const ReportsRouting = useMemo(
    () => ({
      path: 'reports',
      element: <ReportDashboard />,
    }),
    []
  );

  const salesforceRouting = useMemo(
    () => ({
      path: 'update-salesforce',
      children: [
        { path: ':estimateId/review', element: <SalesforceReviewPage /> },
        { path: ':estimateId/results', element: <SalesforceResultsPage /> },
        redirect(),
      ],
    }),
    [redirect]
  );

  const estimateRouting = useMemo(
    () => ({
      path: 'view-estimate-products',
      children: [
        { path: ':estimateId/review', element: <SalesforceReviewPage /> },
        { path: ':estimateId/results', element: <SalesforceResultsPage /> },
        redirect(),
      ],
    }),
    [redirect]
  );

  const salesforceLoginRouting = useMemo(
    () => ({
      path: 'salesforce-login',
      children: [
        {
          index: true,
          element: <SalesforceLogin />,
        },
        redirect(),
      ],
    }),
    [redirect]
  );

  const reviewUcRouting = useMemo(
    () => ({
      path: 'review-uc',
      children: [
        {
          index: true,
          element: <Navigate to="/dashboard" replace />,
        },
        { path: ':estimateId/:locationId', element: <UcReviewPage /> },
        redirect(),
      ],
    }),
    [redirect]
  );

  const reviewRcRouting = useMemo(
    () => ({
      path: 'review-rc',
      children: [
        {
          index: true,
          element: <Navigate to="/dashboard" replace />,
        },
        { path: ':estimateId/:locationId', element: <RcReviewPage /> },
        redirect(),
      ],
    }),
    [redirect]
  );

  const sfFieldValRedirectRouting = useMemo(
    () => ({
      path: 'salesforce-opportunity',
      element: <SfFieldValRedirect />,
      children: [redirect()],
    }),
    [redirect]
  );

  const routeChildren = useMemo(() => {
    if (pqeFlag && !isPartnerLinkUser) {
      return [
        dashboardRouting,
        ucQuestionRouting,
        rcQuestionRouting,
        mneQuestionRouting,
        eneQuestionRouting,
        adminRouting,
        estimateBuilderRouting,
        salesforceRouting,
        estimateRouting,
        salesforceLoginRouting,
        reviewUcRouting,
        reviewRcRouting,
        sfFieldValRedirectRouting,
        ReportsRouting,
        redirect('/dashboard'),
      ];
    }

    return [
      adminRouting,
      estimateBuilderRouting,
      redirect('/estimate-builder/sandbox'),
    ];
  }, [
    pqeFlag,
    adminRouting,
    estimateBuilderRouting,
    dashboardRouting,
    ucQuestionRouting,
    rcQuestionRouting,
    mneQuestionRouting,
    eneQuestionRouting,
    salesforceRouting,
    estimateRouting,
    salesforceLoginRouting,
    reviewUcRouting,
    reviewRcRouting,
    sfFieldValRedirectRouting,
    isPartnerLinkUser,
    ReportsRouting,
    redirect,
  ]);

  // =============================================
  // Return
  // =============================================
  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: routeChildren,
    },
  ]);

  useEffect(() => {
    // force navigate to default path if default path changes (based on pqeFlag)
    navigate(defaultPath, { replace: true });
  }, [navigate, defaultPath]);

  if (isLoading) {
    return (
      <div className="display-loader">
        <KiteLoader loaderTitle="Loading Solution Pricing Tool" />
      </div>
    );
  }

  return routes;
};

export default Routes;
