// Packages
import React, { useMemo } from 'react';

// Components
import { AddPortPill, AutobuildPill } from 'components';

// Hooks
import { useQueryData, useSelectedSolutionCheck } from 'hooks';

// Utils

// Types
import { ISubmission } from 'types';

// Styles
import './MneHospitalityBadgeStatus.scss';
import { deviceConfig } from 'utils';
import {
  mneCampusLivingPowerFields,
  mneHospitalityPowerFields,
} from 'utils/defaultsAndConstants/constants';

export interface IMneHospitalityBadgeStatusProps {
  submissions?: ISubmission[];
  isHospitality?: boolean;
}

const MneHospitalityBadgeStatus = ({
  submissions = [],
  isHospitality,
}: IMneHospitalityBadgeStatusProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================

  const { currentEstimate, currentLocation } = useQueryData();

  const { solutionBadgeLabel } = useSelectedSolutionCheck({
    currentLocation,
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  const isLocationAutobuild = useMemo(() => {
    return (
      currentEstimate?.locations.find((e) => e.id === currentLocation?.id)
        ?.isAutobuild || false
    );
  }, [currentEstimate, currentLocation]);

  const sparcname = isLocationAutobuild ? 'No DRT' : 'DRT Required';

  const totalPowerRequired = useMemo(() => {
    const { cameraFieldId, phoneFieldId, byoEndPointsId } =
      mneHospitalityPowerFields;
    const {
      cameraFieldIdCampusLiving,
      phoneFieldIdCampusLiving,
      byoEndPointsIdCampusLiving,
    } = mneCampusLivingPowerFields;
    const cameraSubmission = submissions.find(
      (s) =>
        s.fieldId ===
        (isHospitality ? cameraFieldId : cameraFieldIdCampusLiving)
    );
    let totalPower = 0;
    if (cameraSubmission?.answer) {
      const answers = JSON.parse(cameraSubmission?.answer);
      answers.forEach((a: string[]) => {
        if (a[1] && a[0]) {
          totalPower += Number(a[1]) * (deviceConfig[a[0]]!.power || 0);
        }
      });
    }

    const phonesSubmission = submissions.find(
      (s) =>
        s.fieldId === (isHospitality ? phoneFieldId : phoneFieldIdCampusLiving)
    );
    if (phonesSubmission?.answer) {
      const answers = JSON.parse(phonesSubmission?.answer);
      answers.forEach((a: string[]) => {
        if (a[1] && a[0]) {
          totalPower += Number(a[1]) * (deviceConfig[a[0]]!.power || 0);
        }
      });
    }

    const byoEndPointsSubmission = submissions.find(
      (s) =>
        s.fieldId ===
        (isHospitality ? byoEndPointsId : byoEndPointsIdCampusLiving)
    );
    if (byoEndPointsSubmission?.answer) {
      totalPower += Number(byoEndPointsSubmission.answer) || 0;
    }
    return totalPower;
  }, [submissions, isHospitality]);

  // =============================================
  // Interaction Handlers
  // =============================================

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-hospitality-badges">
      <AutobuildPill isAutobuild={isLocationAutobuild} />
      <AddPortPill name={sparcname} isOkay={isLocationAutobuild} />
      <AddPortPill
        name={"Total Power Req'd"}
        isOkay={true}
        value={totalPowerRequired}
        unit="Watts"
      />
      <AddPortPill
        name={solutionBadgeLabel}
        isOkay={solutionBadgeLabel !== 'Solution Not Selected'}
      />
      <b>ISW Pricing : See IRR</b>
    </div>
  );
};

export default MneHospitalityBadgeStatus;
