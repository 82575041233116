import { useMutation, useQueryClient } from 'react-query';
import { IAdminHistory } from 'types';
import { IPostAdminHistories } from 'types/AdminHistory';
import { adminHistoryRequest, queryKeys } from 'utils';

export const usePostAdminHistories = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();
  const allAdminHistoriesKey = queryKeys().all.adminHistory;

  const {
    mutate: postAdminHistory,
    isLoading: postAdminHistoryLoading,
    error: postAdminHistoryError,
    data: postAdminHistoryRes,
  } = useMutation(
    //changed from IAdminHistories to post
    (newAdminHistory: IPostAdminHistories) =>
      adminHistoryRequest({
        method: 'POST',
        endpoint: 'admin-histories',
        data: {
          ...newAdminHistory,
        },
      }),
    {
      onMutate: async (newAdminHistory: Partial<IAdminHistory>) => {
        await queryClient.cancelQueries(allAdminHistoriesKey);
        const prevAdminHistories =
          queryClient.getQueryData<IAdminHistory[]>(allAdminHistoriesKey) || [];

        queryClient.setQueryData(allAdminHistoriesKey, newAdminHistory);
        return { prevAdminHistories };
      },
      onError: async (err, _, context) => {
        console.log(err);
        await queryClient.cancelQueries(allAdminHistoriesKey);
        queryClient.setQueryData(
          allAdminHistoriesKey,
          context?.prevAdminHistories
        );
        if (onError) {
          onError();
        }
      },
      onSuccess: (res: IAdminHistory) => {
        if (onSuccess) {
          console.log('Data saved successfully');
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(allAdminHistoriesKey);
      },
    }
  );

  return {
    postAdminHistory,
    postAdminHistoryLoading,
    postAdminHistoryError,
    postAdminHistoryRes,
  };
};
