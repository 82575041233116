import {
  IDetailedAddress,
  IDistro,
  TSbbAddonNotesStars,
  TSbbLocationOptions,
  TRcNotesStars,
  TTermLengthMonths,
} from 'types';

export const NONE = 'None';
export const imageUrl =
  'https://cdn.spectrumflow.net/cwe/solution-pricing-tool/product-images';
export const UCQFORMS = 'AE - MSS - SE';
export const RCQFORMS = 'General, Ring Central';
export const MNEFORMS =
  'MNE General, MNE Questions, MNE Hospitality, MNE Campus Living';
export const ENEFORMS = 'ENE General, ENE Questions';
export const CPWFORMS = 'CPW Quote';

export const noLabelForms = [
  '2da13a40-238e-484b-8f9a-425956d7ff94',
  'a036b6d9-1ffc-4273-a368-8427ac3b3c44',
  'dc9ad13c-90e7-45ea-8100-d93672c3c2eb',
  '310d5e7c-e4d5-4a0d-bd5c-2c3c8dfc6812',
  '751f9001-3062-43e8-9782-e18634b55a20',
  'c6f45363-a1d6-479c-8559-37830748e515',
];

export const ultraHighSpeeds = [
  '20G',
  '30G',
  '40G',
  '50G',
  '60G',
  '70G',
  '80G',
  '90G',
  '100G',
];

export const invalidNumInputChars = ['+', '-', '.', 'e'];

export const EnterpriseIntSpeedAlert =
  'The following zip codes have the following speeds available: 96713 (100x10M only). Enterprise Internet pricing remains the same regardless of Term length.';

export const sbbAddonNotesConfiguration: {
  [Property in TSbbLocationOptions]: { [key: string]: TSbbAddonNotesStars };
} = {
  Public: {
    'Music Choice': '**',
  },
  Private: {
    'Music Choice': '**',
  },
  'Mixed Public / Private': {
    'HBO Multiplex': '*',
    'Cinemax Multiplex': '*',
    'Showtime Multiplex': '*',
    'STARZ Multiplex': '*',
    TMC: '*',
    Encore: '*',
    EPIX: '*',
    'Music Choice': '**',
  },
};

export const sbbAddonNotes: { [Property in TSbbAddonNotesStars]: string[] } = {
  '*': ['* Premium channels will not be available on Public outlets.'],
  '**': [
    '** A client with the Music Choice programming add-in is not permitted to:',
    '(i) charge a cover charge or admission fee to any Service Location(s) at the time the TV Service is being displayed or are to be displayed; or',
    '(ii) permit dancing, skating or other similar forms of entertainment or physical activity in conjunction with the performance of the TV Service',
  ],
};

export const rcNotes: { [Property in TRcNotesStars]: string[] } = {
  '*': [
    'Additional custom professional services for Onboarding, Training, Network Assessment, or Services / Labor maybe requested. If a request for additional custom professional service(s) is (are) requested, an additional quote will be provided incremental to this estimate.',
  ],
};

export const sbbStepperValues = [
  {
    label: 'Location',
    status: 'initial',
    kiteIcon: 'home-f',
  },
  {
    label: 'Package',
    status: 'initial',
    kiteIcon: 'person',
  },
  {
    label: 'Add-Ons',
    status: 'initial',
    kiteIcon: 'plus',
  },
  {
    label: 'Equipment',
    status: 'initial',
    kiteIcon: 'model',
  },
];

export const networkEdgeStepperValues = {
  MNE: [
    {
      label: 'Network',
      status: 'initial',
      kiteIcon: 'internet',
    },
    {
      label: 'WiFi',
      status: 'initial',
      kiteIcon: 'wifi',
    },
    {
      label: 'Cameras',
      status: 'initial',
      kiteIcon: 'camera',
    },
    {
      label: 'Switches',
      status: 'initial',
      kiteIcon: 'outlet',
    },
    {
      label: 'Remote Workers',
      status: 'initial',
      kiteIcon: 'person',
    },
    {
      label: 'Sensors',
      status: 'initial',
      kiteIcon: 'person',
    },
    {
      label: 'Device Management',
      status: 'initial',
      kiteIcon: 'internet',
    },
  ],
  ENE: [
    {
      label: 'Network',
      status: 'initial',
      kiteIcon: 'internet',
    },
    {
      label: 'Switches',
      status: 'initial',
      kiteIcon: 'outlet',
    },
    {
      label: 'WiFi',
      status: 'initial',
      kiteIcon: 'wifi',
    },
    {
      label: 'Device Management',
      status: 'initial',
      kiteIcon: 'internet',
    },
  ],
};

// Installation Charge - New Site product for UC profiles
export const installProductId = 'dc9a80aa-1e19-45dd-a3b8-e01ef07c5e5a';

export const connectorTypes: NonNullable<IDistro['connectorType']>[] = [
  'RJ-45',
  'FC',
  'LC',
  'SC',
  'ST',
];

export const interconnectTypes: NonNullable<IDistro['interconnectType']>[] = [
  'CAT3',
  'CAT5e',
  'CAT6',
  'MM Fiber',
  'SM Fiber',
  'Spectrum Provided Interconnect',
  'Single Building',
];

export const interconnectResponsibilityTypes: NonNullable<
  IDistro['interconnectResponsibility']
>[] = ['Customer Provided', 'Spectrum Provided'];

export const conduitTypes: NonNullable<IDistro['conduit']>[] = ['Yes', 'No'];

export const addressInputDetails: {
  value: keyof IDetailedAddress;
  label: string;
}[] = [
  {
    value: 'address1',
    label: 'Address Line 1',
  },
  {
    value: 'address2',
    label: 'Address Line 2 (optional)',
  },
  {
    value: 'city',
    label: 'City',
  },
  {
    value: 'state',
    label: 'State',
  },
  {
    value: 'zipcode',
    label: 'Zipcode',
  },
];

export const blankAddress: IDetailedAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  name: '',
};

export const insightsMap = (mneSelections: string | undefined) => {
  switch (mneSelections) {
    case '20 Mbps':
    case '30 Mbps':
    case '50 Mbps':
    case '100 Mbps':
    case '200 Mbps':
      return 'Managed Network Insights - S';
    case '500 Mbps':
    case '1 Gbps':
      return 'Managed Network Insights - M';
    default:
      return '';
  }
};

export const sdWanMap = (mneSelections: string | undefined) => {
  switch (mneSelections) {
    case '20 Mbps':
      return 'SD-WAN Plus 20Mbps';
    case '30 Mbps':
      return 'SD-WAN Plus 30Mbps';
    case '50 Mbps':
      return 'SD-WAN Plus 50Mbps';
    case '100 Mbps':
      return 'SD-WAN Plus 100Mbps';
    case '200 Mbps':
      return 'SD-WAN Plus 200Mbps';
    case '500 Mbps':
      return 'SD-WAN Plus 500Mbps';
    case '1 Gbps':
      return 'SD-WAN Plus 1Gbps';
    default:
      return '';
  }
};

export const display$0Price = [
  'Per Phone Shipping Fee',
  'Per Phone Activation Fee',
];

export const rcProductFamilies = [
  'RC Seats',
  'RC Add Ons',
  'RC Phones / Devices',
  'RC Additional NRC',
];

export const rcAdditionalNRCProducts = [
  'On Site Professional Install',
  'Inside Wiring',
];

export const rcHelpText = [
  'Prices quoted are valid for 90 days and are subject to change and do not include any taxes, fees, and/or surcharges which may apply.THIS QUOTE SHALL NOT CONSTITUTE A BINDING CONTRACT BETWEEN SPECTRUM BUSINESS AND CUSTOMER.  THE PURCHASE OF SPECTRUM BUSINESS SERVICES SHALL BE GOVERNED BY THE TERMS AND CONDITIONS OF A SEPARATE SERVICE AGREEMENT TO BE ENTERED INTO BY SPECTRUM BUSINESS AND CUSTOMER.',
  'If total number of devices (phones+BYOD) exceeds total number of seats (Advanced+Ultra+Ltd Ext), additional devices cannot be activated.',
  'Equipment may only be returned within 30 days of shipping the phone;  restocking fees may apply.',
  'Advanced / Ultra Seats come with unlimited usage that covers outbound calling in US-50, Puerto Rico, and Canada.',
  'Charges for Toll Free Usage, International Usage, SMS/MMS Usage, and TCR Fees are available at https://enterprise.spectrum.com/services/voice/unified-communications/unified-communications-with-ringcentral.html',
  'Must have both MNE / ENE Edge Service & Mgd Switches to be eligible for Inside Wiring or Onsite Prof. Install.  Additional charges may apply.',
  'If selected, Equipment Installment Plan is presented as an NRC on this form and Service Order.  Invoice will apply monthly charges for selected period.',
];

export const eipTermMap = (term: TTermLengthMonths) => {
  switch (term) {
    case '12':
      return '12';
    case '24':
      return '24';
    case '36':
    case '48':
    case '60':
    case '84':
      return '36';
    default:
      return 'Purchase';
  }
};

export const iswEipTermMap = (term: TTermLengthMonths) => {
  switch (term) {
    case '12':
      return '12';
    case '24':
      return '24';
    case '36':
      return '36';
    case '48':
      return '48';
    case '60':
      return '60';
    case '84':
      return '84';
    default:
      return 'One-time Payment';
  }
};

export const licenseTypeOptions = ['Co-Terminous', 'Per Device'];

export const licenseLevelOptions = [
  'Enterprise',
  'Advanced Security',
  'Insight',
  'SD-WAN+',
  'Thousand Eyes',
];
export const eneLicenseTypeOptions = [
  'Fortimanager(ICB Reqd)',
  'Fortiportal(ICB Reqd)',
  'Fortigate Cloud',
  'Direct Access(ICB Reqd)',
  'FortiLan(ICB Reqd)',
];

export const eneLicenseLevelOptions = [
  'Enterprise',
  'SMB(ICB Reqd)',
  'UTP',
  'ATP(ICB Reqd)',
];

// For MNE Power/Ports Utilization
export const mneSwitchFieldId = '5845d174-a43f-44c3-bb38-e70539a09560';
export const mneEdgeFieldId = '5bd1128e-fcf6-424f-8b5b-c531010bcfac';
export const mneAccessPointCameraPhoneFieldIds = [
  '4f3c8c52-de64-401f-a9a1-c0d0f541c7c4', // mneAccessPointFieldId
  '06c2cc92-8e27-439a-b6fd-d23848e97e40', // mneCameraFieldId
  '03561c29-3efb-4fbd-9895-e815a6cbbf6b', // mnePhoneFieldId
];
const mneAdditionalPortsRequiredFieldId =
  'd91773cd-ebbd-416c-8a1d-cf9d5e279e5c';
const mneByoSwitchWattsFieldId = '7c0fa72f-d3be-4190-adda-262cc2d37ba2';
const mneByoSwitchPortsFieldId = 'c5363eed-1f90-4e7f-aaac-dce3823ffa26';
const mneByoEndPointsFieldId = '00279652-0f4e-44de-a687-73fbff0e96dd';
const mnePoeRequiredForUcRcFiedId = '7520da77-a6f1-40dd-8abb-6339f8a3e1fe';
export const mneTermFieldId = '5b0b3e7e-9266-4a67-814f-9fa8d799205a';
export const eneTermFieldId = '4552f9d2-e58b-48cb-9f0c-3db26a69d067';
export const mneDeviceMountsAbove24FtFiedId =
  'de453da5-45c2-412b-88c4-3b7be8b3fb41';

export const mnePowerPortFields = {
  accessPointCameraPhoneFieldIds: mneAccessPointCameraPhoneFieldIds,
  additionalPortsRequiredFieldId: mneAdditionalPortsRequiredFieldId,
  byoEndPointsFieldId: mneByoEndPointsFieldId,
  byoSwitchPortsFieldId: mneByoSwitchPortsFieldId,
  byoSwitchWattsFieldId: mneByoSwitchWattsFieldId,
  poeRequiredForUcRcFiedId: mnePoeRequiredForUcRcFiedId,
  switchFieldId: mneSwitchFieldId,
};

// For ENE Power/Ports Utilization
export const eneSwitchFieldId = '8e6bff5f-2f52-48dc-b293-4ecaadecd620';
export const eneAccessPointPhoneFieldIds = [
  'a3c3f305-4590-495a-b1b0-cf8eba468aa8', // eneAccessPointFieldId
  '2050d1ca-9490-4b4d-971c-a5bd49aabbe2', // enePhoneFieldId
];
const eneAdditionalPortsRequiredFieldId =
  'b31c4bdb-b146-4bfc-9a87-0f6c36cdfbdc';
const eneByoSwitchWattsFieldId = '5af1f96a-98c2-448a-bd6c-27066517ce33';
const eneByoSwitchPortsFieldId = '7edfca41-3908-4fee-b809-0d0ca66efbea';
const eneByoEndPointsFieldId = '7dd65731-cea2-4d07-9fd7-10f6d93e536e';
const enePoeNeededForPhonesFiedId = '20599c06-4478-47db-93a2-828b1a25b5db';

export const enePowerPortFields = {
  accessPointCameraPhoneFieldIds: eneAccessPointPhoneFieldIds,
  additionalPortsRequiredFieldId: eneAdditionalPortsRequiredFieldId,
  byoEndPointsFieldId: eneByoEndPointsFieldId,
  byoSwitchPortsFieldId: eneByoSwitchPortsFieldId,
  byoSwitchWattsFieldId: eneByoSwitchWattsFieldId,
  poeRequiredForUcRcFiedId: enePoeNeededForPhonesFiedId,
  switchFieldId: eneSwitchFieldId,
};

/** FOR MNE INSIDE WIRING **/
const mneClientProvidedDropFiedId = '21c92e19-9cc2-4f3e-8db7-5553a2a96c8e';
export const mneDeviceMountsFieldId = 'a549c35c-d2bf-4adc-aa0f-ea69837b31b7';
const mneDropsFieldIDsISW = [
  '4f3c8c52-de64-401f-a9a1-c0d0f541c7c4', // accessPointFieldId
  '06c2cc92-8e27-439a-b6fd-d23848e97e40', // cameraFieldId
];

export const mneFormIDs = [
  '2da13a40-238e-484b-8f9a-425956d7ff94', // mne discovery form Id
  '751f9001-3062-43e8-9782-e18634b55a20', // mne hospitality
  'a036b6d9-1ffc-4273-a368-8427ac3b3c44', // mne opportunity type
];

export const eneFormIDs = [
  'dc9ad13c-90e7-45ea-8100-d93672c3c2eb', // ene discovery form Id
  '310d5e7c-e4d5-4a0d-bd5c-2c3c8dfc6812', // ene general
];
export const mneSEEthernetDrop = 'f7785bbd-b06f-4ce3-b6ba-2b460532b128';
export const eneSEEthernetDrop = '26add76c-a6b4-4ac8-ba11-877dc41223fb';
export const mneFirewallFieldId = 'd8478da9-4936-4639-8d65-113b03c0541a';
const mnePhoneFieldId = '03561c29-3efb-4fbd-9895-e815a6cbbf6b';
const mneAddPhoneDropsFieldId = '1b53b30e-9f23-4f44-9a89-654857978d26';
export const hospitalityId = 'e58dd169-e734-4346-8acc-f16ce83c5091';
export const campusLivingFieldId = '5bc4c1f5-21bb-48ab-b4f3-da735ea81c63';
export const regularMNEId = '3d7fdb44-b65a-4d6e-aadc-88e5739a2ebe';
export const mneOnNetFieldId = 'fc8c2c17-b48d-4d88-8be6-380362c5b861';
export const generalSectionId = '8845fc91-006c-4b9f-a9b9-7a4f83976f31';
export const mneGuestRoomId = 'a56c719d-65ec-4af5-a73c-f617c0344f2f';
export const guestRoomDesignId = '391826c1-5db8-483f-bdcc-86ffb6563a9e';
export const installNRCId = '706bc43b-96a3-4f73-9737-d9a118964c69';
export const mneISWFields = {
  clientProvidedDropFiedId: mneClientProvidedDropFiedId,
  additionalPortsRequiredFieldId: mneAdditionalPortsRequiredFieldId,
  dropsFieldIDsISW: mneDropsFieldIDsISW,
  phoneFieldId: mnePhoneFieldId,
  addPhoneDropsFieldId: mneAddPhoneDropsFieldId,
  onNetFieldId: mneOnNetFieldId,
  deviceMountsFieldId: mneDeviceMountsFieldId,
  deviceMountsAbove24FtFieldId: mneDeviceMountsAbove24FtFiedId,
};
export const EIProductIds = [
  '2895b856-492c-41e5-9c76-b7cdf8d39429',
  '4193c98c-7e99-420f-b677-d8ca75594881',
  '13ef58dc-ba1d-4b3b-a291-e4f6a54d9a47',
  '6126a76c-5127-49bf-99c0-0678415eb2a6',
];
export const staticIpProductIds = [
  '2b0792a3-6588-4506-985a-7f5311e77d1c',
  '03e21cdb-9cd5-4a6c-a525-29db48bcb03f',
  '306af855-bba1-462a-a316-6a2f4800e415',
  'd0c0e549-5e7f-40a9-b09e-858b31df4ba6',
];

/** FOR ENE INSIDE WIRING **/
const eneClientProvidedDropFiedId = 'd8266317-44d0-495e-a0a7-590aa1f1e3b7';
export const eneDeviceMountsFieldId = 'b2196909-c98a-4b29-b9a4-ee48fdea2e85';
const eneDropsFieldIDsISW = [
  'a3c3f305-4590-495a-b1b0-cf8eba468aa8', // accessPointFieldId
];
const enePhoneFieldId = '2050d1ca-9490-4b4d-971c-a5bd49aabbe2';
const eneAddPhoneDropsFieldId = 'fa01f69f-545e-4453-bdb0-3f8acc1aa2fa';
export const eneOnNetFieldId = '312c5b95-7f0e-46e5-b1f8-5e17f5ca7f8d';
export const eneDeviceMountsAbove24FtFieldId =
  'fc5704ad-2a36-4dd7-a23a-3bbe254762d8';

export const eneISWFields = {
  clientProvidedDropFiedId: eneClientProvidedDropFiedId,
  additionalPortsRequiredFieldId: eneAdditionalPortsRequiredFieldId,
  deviceMountsFieldId: eneDeviceMountsFieldId,
  dropsFieldIDsISW: eneDropsFieldIDsISW,
  phoneFieldId: enePhoneFieldId,
  addPhoneDropsFieldId: eneAddPhoneDropsFieldId,
  onNetFieldId: eneOnNetFieldId,
  deviceMountsAbove24FtFieldId: eneDeviceMountsAbove24FtFieldId,
};

export const EstimateBuilderOffNetRateCard: { [quantity in number]: number } = {
  1: 1500,
  2: 1500,
  3: 1500,
  4: 1800,
  5: 2250,
  6: 2700,
  7: 2800,
  8: 3200,
  9: 3600,
  10: 4000,
  11: 4400,
  12: 4800,
};

export const getISWOffNetPricing = (quantity: number) => {
  if (quantity <= 12) return EstimateBuilderOffNetRateCard[quantity] || 0;
  return quantity * 375;
};

export const deviceManagementSelectQuantityFieldIds = [
  '4e3bb497-97dc-44a7-b9e4-2090d9787718', //mne AP
  '176eedf1-baa9-49ba-ab7e-2add4c8b0071', //mneCamera
  '95f0a062-8f7c-41f6-b9d9-c666dc199715', //mneSensor
  '7da95391-34f7-43de-b0ab-2d58c9a4ba03', //eneAP
];

export const mneSensorsFieldId = 'd15a81e7-5679-40c8-b263-e969e609d9e3';
export const mneAccessPoint = '4f3c8c52-de64-401f-a9a1-c0d0f541c7c4';
export const mnePOENeeded = '0faa6355-7a2b-44f8-aca9-78576e78cabf';
export const totalWattsPhones = '5101ba55-42f9-4251-8c7c-21794274e128';
// RC EIP and UC ISW IP
export const rcIswId = '3b27fb28-2012-4d29-974a-07455faba58b';
export const rcPhonesAndDevicesId = 'a75524a5-f60a-4753-8e39-aa91e17558e8';
export const professionalServicesId = 'c3f4f93f-d1bf-4424-95c2-fcba6cf9c65c';
export const outOfFootprintIswId = 'fdafdad6-72c3-447b-923a-8f7b5e2efc72';
export const inFootprintIswId = '91cf1d90-3989-48ee-85b7-10c91da31953';
export const AlgoRemoteProductId = 'd6693d1e-d8e4-41e1-8ce6-c8b2492cdb87';
export const AlgoOnSiteProductId = '09b9209a-a3f2-4ba5-aec1-f06b4886bd8a';

export const mneAccessPointCameraFieldIds = [
  '1bdabfe4-3c5d-48fc-8d5c-75c5fb20fd7e',
  '57e82466-6239-4be4-996e-c40709736fc9',
  '0097500d-234d-4881-969b-4d6d102454b9',
  'a80ff1a1-0fec-42c5-ba97-a88cde5f2dd4',
  '4d5a234a-ae4d-4621-8ba8-015ff164189f',
  'cfd4484d-fe75-458f-b14a-1f9972df4bb4',
  '71891589-0cfe-44f3-a41b-3aff9e2e5d69',
  'efdb5054-8a1c-4316-b268-ac5513348c96',
  'c1484bcc-06ba-420d-ae6a-8f4f5c343074',
  '13e117b0-9ff4-465b-8b0d-c8c8a166d4b4',
  'ec2ed319-8a7a-4198-8f21-72914c3d7794',
  'ee86c5e9-0a65-4467-a851-08baaa4cc52f',
  'a0a88810-ad69-4da8-b349-12dc4395ad12',
  '0edd5546-0da3-4cc5-8122-9f65aac5887a',
  '59703068-4c60-4a9c-9bec-76ca4f1d408b',
  'df13b80b-8ce0-49e2-8030-191be8c84b04',
];

export const rcSeats = [
  '3d231d9a-f84e-45a3-b265-f2f423b79be7',
  'e1feca1d-9fef-4ec8-ae91-a2122858dd75',
];

// Used for Copy Responses History
export const getFormNameByFormId: { [key: string]: string } = {
  '6494e74e-8fc1-4a65-817f-5bdb0e8bae85': 'UC Discovery Questions/Notes',
  'eda40c7f-891e-49b4-97c2-de4912cf5259': 'RC Discovery Questions/Notes',
  '2da13a40-238e-484b-8f9a-425956d7ff94': 'MNE Discovery Questions/Notes',
  '751f9001-3062-43e8-9782-e18634b55a20': 'MNE Hospitality',
  'dc9ad13c-90e7-45ea-8100-d93672c3c2eb': 'ENE Discovery Questions/Notes',
};

// Used for Copy Responses History
export const getFormIdByFormType: { [key: string]: string } = {
  UC: '6494e74e-8fc1-4a65-817f-5bdb0e8bae85',
  RC: 'eda40c7f-891e-49b4-97c2-de4912cf5259',
  MNE: '2da13a40-238e-484b-8f9a-425956d7ff94',
  MNEH: '751f9001-3062-43e8-9782-e18634b55a20',
  ENE: 'dc9ad13c-90e7-45ea-8100-d93672c3c2eb',
};

export const advancedSeatId = '3d231d9a-f84e-45a3-b265-f2f423b79be7';
export const ultraSeatId = 'e1feca1d-9fef-4ec8-ae91-a2122858dd75';
export const businessAnalyticsPro12MonthsId =
  '53de813c-31ce-4297-aa54-f3dd0edff530';
export const businessAnalyticsPro24MonthsId =
  '7ecb19cb-3ace-49e6-92c9-b504be636d53';
export const businessAnalyticsPro36MonthsId =
  '4c445640-49e4-438f-b358-07627d49e674';
export const businessAnalyticsProPricing = {
  '12': {
    '1-99': 100,
    '100-399': 1000,
    '400-2499': 3000,
    '2500-4999': 10000,
    '5000+': 15000,
  },
  '24': {
    '1-99': 150,
    '100-399': 1500,
    '400-2499': 4500,
    '2500-4999': 15000,
    '5000+': 22500,
  },
  '36': {
    '1-99': 200,
    '100-399': 2000,
    '400-2499': 6000,
    '2500-4999': 20000,
    '5000+': 30000,
  },
};

// Used for UCQHistory Drawer
export const UCQHistoryForms = [
  '433d3a6c-91de-45f2-98f3-4fa89504b6a4', //General Discovery
  '6494e74e-8fc1-4a65-817f-5bdb0e8bae85', //UC Discovery Questions/Notes
];

// Used for RCQHistory Drawer
export const RCQHistoryForms = [
  'eda40c7f-891e-49b4-97c2-de4912cf5259', //Discovery Questions/Notes
  '3fa85f64-5717-4562-b3fc-2c963f66afa6', //General
];

// Used for MNEQHistory Drawer
export const MNEQHistoryForms = [
  '2da13a40-238e-484b-8f9a-425956d7ff94', //MNE Discovery Questions/Notes
  'a036b6d9-1ffc-4273-a368-8427ac3b3c44', //MNE Opportunity Type
  '751f9001-3062-43e8-9782-e18634b55a20', //MNE Hospitality
  'c6f45363-a1d6-479c-8559-37830748e515', //MNE Campus Living
];

// Used for ENEQHistory Drawer
export const ENEQHistoryForms = [
  '310d5e7c-e4d5-4a0d-bd5c-2c3c8dfc6812', //ENE Opportunity Type
  'dc9ad13c-90e7-45ea-8100-d93672c3c2eb', //ENE Discovery Questions/Notes
];

// For MNEHospitality Power Utilization
export const mneHospitalityPowerFields = {
  cameraFieldId: '70c890cb-6f0b-40d7-9b0c-dd2c05d2851b',
  phoneFieldId: '2b1379cd-fd02-417b-a8a7-c26de80e9ae9',
  byoEndPointsId: 'a41cf351-0e56-422a-a25f-9aaa1fdd8398',
};

//For MNE Campus Living Power Utilization
export const mneCampusLivingPowerFields = {
  cameraFieldIdCampusLiving: '30f5f356-093b-4c7c-868f-bf21d9feb396',
  phoneFieldIdCampusLiving: '4d8aa272-4eea-4b92-a49b-20c20501f483',
  byoEndPointsIdCampusLiving: '3a918d92-9149-4ba7-9df7-e38a01a0b00a',
};

// Used for Field Config
export const wpnRequiredFieldId = '7b599f1a-e62c-4974-a150-7ece09ecfbfe';
export const pmsVendorFieldId = 'df10a4ec-143b-4512-845b-4355c64adb05';
export const pmsTypeFieldId = 'a39d4a8d-92c1-4069-b915-9c81df765f49';

export const advancedEdgeFeaturesFieldId =
  '0d072cbe-c487-4fa0-b04f-e9f6e6e91f2f';

export const enterpriseInternetIds = [
  '2895b856-492c-41e5-9c76-b7cdf8d39429',
  '4193c98c-7e99-420f-b677-d8ca75594881',
  '13ef58dc-ba1d-4b3b-a291-e4f6a54d9a47',
  '6126a76c-5127-49bf-99c0-0678415eb2a6',
];

//MNE Campus Living
export const generalSectionCompleteCLFieldId =
  '979442b4-1d26-4d7c-b704-aab16c605c14';
export const coverageSectionCompleteCLFieldId =
  '16963d01-7bd5-4be8-b234-b37203549652';
export const wpnRequiredCLFieldId = '2b6a5dbc-24ca-4d55-989a-45da15148072';
export const idpVendorCLFieldId = 'd0d7aeb9-88de-4283-b7d0-e18d85148ded';
export const idpVendorUrlCLFieldId = '4fb53aef-51e1-44d7-8616-9d8905888c75';
export const noOfBedsCLFieldId = '251fe8cd-9a6e-40f9-a35d-5ba64430fc51';
export const noOfRoomsCLFieldId = 'f62abace-48eb-4a59-b6d7-f2943386e1a8';
export const installCLNRCId = '3acf1f13-ed53-49fb-abf3-d6c9258da5a7';

// Used for MNE Hospitality Coverage Fields
export const mneHospitalityCoverageFields = {
  installNRCId,
  mneGuestRoomId,
  guestRoomDesignId,
};

// Used for MNE Campus Living Coverage Fields
export const mneCampusLivingCoverageFields = {
  installNRCId: installCLNRCId,
  mneGuestRoomId: noOfRoomsCLFieldId,
  guestRoomDesignId: '7ebe25d2-3515-47e6-b4ff-a084600b0f16',
};

export const labelsToShowAsNotes = [
  'b61f1c16-096a-4763-80c6-97d1986a3ad9', //nonHospitalityEndPointsFieldId
  'ba1b4ab9-1532-4c52-896a-2f6ef7b8f563', //roomBedsRequiredLabelFieldId
  '7d59ac4a-4047-48e8-a98c-6a7bba77cee7', //nonCampusLivingEndPointsFieldId
];
