import { queryKeys } from 'utils';

import { IAdminHistory } from 'types';

import { useGenerateQuery } from '../useGenerateQuery';
import {
  adminHistoryRequest,
  IAdminHistoryRequest,
} from 'utils/api/adminHistoryRequest';

export const useGetAdminHistories = (
  requestConfig: IAdminHistoryRequest = {}
) => {
  const { params } = requestConfig;
  const { data, isLoading, error } = useGenerateQuery<IAdminHistory[]>({
    queryKey: params
      ? queryKeys(params).filter.adminHistory
      : queryKeys().all.adminHistory,
    apiCall: adminHistoryRequest,
    requestConfig,
    opts: { staleTime: Infinity },
  });

  return { data, isLoading, error };
};
