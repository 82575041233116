import { RootState } from './store';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export const userEmailSlice = createSlice<string, SliceCaseReducers<string>>({
  name: 'userEmailData',
  initialState: '',
  reducers: {
    storeUserEmail: (_, action) => {
      return action.payload;
    },
  },
});

// Actions
export const { storeUserEmail } = userEmailSlice.actions;

// State
export const userEmailData = (state: RootState): string => state.userEmailData;

// Reducer
export default userEmailSlice.reducer;
