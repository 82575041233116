// Packages
import React, { useCallback, useMemo, useState } from 'react';

// Components
import { KiteButton, KiteCard, KiteSelect } from '@kite/react-kite';
import { SalesTeamCard, AutobuildPill, AutosaveBadge } from 'components';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAnalytics,
  useQueryData,
  useTrackOpenState,
  useGetSubmissions,
} from 'hooks';

// Utils
import { formatLocationAddress, isEstimateAutobuild } from 'utils';

// Types
import { ILocation } from 'types';

// Styles
import './MNEHeader.scss';
import MNEHistoryDrawer from '../MNEHistoryDrawer';
import {
  campusLivingFieldId,
  hospitalityId,
} from 'utils/defaultsAndConstants/constants';

interface IMNEHeaderLocationData {
  name: string;
  id: string;
  address: string;
  isAutobuild: boolean;
}

export interface IMNEHeaderProps {
  // Name of the currently linked salesforce opportunity
  opptyName: string;
  // Locations within currently linked opportunity
  locations: ILocation[];
  // User info for team member who last updated UC Questions form
  lastUpdatedBy?: { pid: string; dateUpdated: string };

  questionType: string;
}

/** Upper header area for UC Questions form layout, allows switching between locations and displays last updated by card */

const MNEHeader = ({
  opptyName,
  locations,
  lastUpdatedBy,
  questionType,
}: IMNEHeaderProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { trackSelectAction } = useAnalytics();
  const { trackOpenState } = useTrackOpenState();
  const navigate = useNavigate();
  const { currentEstimate, updateLocationId } = useQueryData();
  const { estimateId, locationId = 'general', formName } = useParams();

  const [historyDrawerIsOpen, setHistoryDrawerIsOpen] = useState(false);
  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId },
  });

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const updatedByData = useMemo(() => {
    return {
      pid: lastUpdatedBy?.pid,
      role: currentEstimate?.salesTeam[lastUpdatedBy?.pid || ''],
      dateUpdated: lastUpdatedBy?.dateUpdated,
    };
  }, [currentEstimate, lastUpdatedBy]);

  const locationData: IMNEHeaderLocationData[] = useMemo(() => {
    const options = locations.map((l) => ({
      name: l.name,
      id: l.id,
      isAutobuild: l.isAutobuild,
      address: formatLocationAddress(l),
    }));
    const general = {
      name: 'General',
      id: 'mne-opportunity-type',
      address: '',
      isAutobuild: true,
    };
    return [general, ...options];
  }, [locations]);

  const isAutobuild = isEstimateAutobuild(currentEstimate);

  const currentAddress = useMemo(() => {
    return locationData.find((l) => l.id === locationId)?.address || '';
  }, [locationData, locationId]);

  // =============================================
  // Interaction Handlers
  // =============================================

  const onLocationChange = useCallback(
    (id: string) => {
      if (id === 'mne-opportunity-type') {
        updateLocationId('');
        navigate(`/mne-questions/${estimateId}/general/mne-opportunity-type`, {
          replace: true,
        });
      } else {
        updateLocationId(id);
        const hospitality: any = allSubmissions?.find(
          (p) => p.fieldId === hospitalityId
        );

        const campusLiving: any = allSubmissions?.find(
          (p) => p.fieldId === campusLivingFieldId
        );

        const formPath = formName === 'mne-opportunity-type' || formName === 'network-draft'
          ? hospitality?.answer
            ? 'mne-hospitality'
            : campusLiving?.answer
              ? 'mne-campus-living'
              : 'mne-discovery-questions-notes'
          : formName;
        navigate(
          `/mne-questions/${estimateId}/${id}/${formPath}`,
          { replace: true }
        );
      }

      trackSelectAction(`MNE Questions Selected Location Id: ${id}`, {
        opType: 'dropDown',
      });
    },
    [
      estimateId,
      formName,
      navigate,
      trackSelectAction,
      updateLocationId,
      allSubmissions,
    ]
  );

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      onLocationChange(value);
    },
    [onLocationChange]
  );

  const toggleHistoryDrawer = useCallback(() => {
    setHistoryDrawerIsOpen(!historyDrawerIsOpen);
    trackOpenState({ name: 'History Drawer', isOpen: historyDrawerIsOpen });
  }, [historyDrawerIsOpen, trackOpenState]);

  // =============================================
  // Render Methods
  // =============================================
  const locationOptions = useMemo(() => {
    return locationData.map((opt) => (
      <option key={opt.id} value={opt.id}>
        {opt.name}
      </option>
    ));
  }, [locationData]);

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="mne-header">
      <div className="mne-header__upper">
        <div className="mne-header__upper-name">
          <h1>{!opptyName ? 'No Name Provided' : opptyName}</h1>
          <AutobuildPill isAutobuild={isAutobuild} />
          <AutosaveBadge />
        </div>
      </div>
      <div className="mne-header__lower">
        <div className="mne-header__lower-section">
          <span className="mne-header__label">Location</span>
          <KiteSelect
            id="currentLocationId"
            name="current location id"
            value={locationId}
            onChange={handleSelect}
            maxWidth="100%"
          >
            {locationOptions}
          </KiteSelect>
        </div>
        <div
          className="mne-header__lower-section mne-header__lower-section--address"
          style={{ visibility: !currentAddress ? 'hidden' : 'visible' }}
        >
          <span className="mne-header__label">Address</span>
          <KiteCard>
            <span>{currentAddress}</span>
          </KiteCard>
        </div>
        <div className="mne-header__lower-section">
          <div className="mne-header__lower-section__label-container">
            <span className="mne-header__label">Last Updated</span>
            <KiteButton
              className="mne-header__see-all"
              type="standalone-link"
              onClick={toggleHistoryDrawer}
            >
              See All
            </KiteButton>
          </div>
          <SalesTeamCard
            pid={updatedByData.pid}
            role={updatedByData.role}
            dateUpdated={updatedByData.dateUpdated}
            isUCQ
          />
        </div>
      </div>
      {historyDrawerIsOpen && (
        <MNEHistoryDrawer
          isOpen={historyDrawerIsOpen}
          onClose={toggleHistoryDrawer}
          questionType={questionType}
        />
      )}
    </div>
  );
};

export default MNEHeader;
