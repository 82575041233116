import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { KiteAlert, KiteTooltip } from '@kite/react-kite';
import {
  IField,
  IFieldValues,
  ISubmission,
  IFieldInput,
  MForm,
  ILocation,
  IPostCopyCloneHistory,
  IEstimate,
} from 'types';
import {
  UCQSelect,
  UCQDate,
  UCQUpload,
  UCQBasicInput,
  MNECheckbox,
  MNESelectQuantity,
  MNEMultipleSelection,
  MNESelectEdge,
  MNESelectSwitch,
} from 'components';
import { UCQDesignFlowLayout } from 'components/ucQuestions';
import {
  useAnalytics,
  useDeleteSubmissions,
  useFormRules,
  useGetFields,
  useMNEQuestionsTotal,
  useQueryData,
  useUpdateSubmissions,
  useGetSubmissions,
  useCopySubmissions,
  useUpdateLocation,
  usePostCopyCloneHistory,
} from 'hooks';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useParams } from 'react-router-dom';
import { collatorSort, fieldConfig } from 'utils';
import { usePostLocations } from './apiHooks/estimates/usePostLocations';
import {
  noLabelForms,
  hospitalityId,
  regularMNEId,
  generalSectionId,
  MNEFORMS,
  ENEFORMS,
  mneSEEthernetDrop,
  eneSEEthernetDrop,
  advancedEdgeFeaturesFieldId,
  campusLivingFieldId,
  coverageSectionCompleteCLFieldId,
  generalSectionCompleteCLFieldId,
  wpnRequiredCLFieldId,
  idpVendorCLFieldId,
  idpVendorUrlCLFieldId,
  noOfBedsCLFieldId,
  noOfRoomsCLFieldId,
  mneHospitalityCoverageFields,
  mneCampusLivingCoverageFields,
  labelsToShowAsNotes,
} from 'utils/defaultsAndConstants/constants';
interface IMNEUseRenderFieldsParams {
  fields: IField[];
  submissions: ISubmission[];
  formId?: string;
  isCpw?: boolean;
  formIds?: string[];
  forms: MForm[];
  questionType: string;
}

export const MNEuseRenderFields = ({
  fields,
  submissions,
  formId,
  isCpw,
  formIds,
  forms,
  questionType,
}: IMNEUseRenderFieldsParams) => {
  // Updates on fields/submissions changes
  const initFieldValues = useMemo(
    () =>
      fields?.reduce((acc: IFieldValues, field) => {
        const submission = submissions?.find(
          (sub) => sub.fieldId === field.id
        )?.answer;
        acc[field.id] = submission || null;
        return acc;
      }, {}),
    [fields, submissions]
  );

  const { estimateId = '', locationId = '' } = useParams();
  const { userId, currentLocation, currentEstimate } = useQueryData();
  const { trackSelectAction } = useAnalytics();
  const { data: allFields } = useGetFields();
  const { data: allSubmissions } = useGetSubmissions({
    params: { estimateId: estimateId },
  });

  /* * * * * * * *
   *             *
   * STATE/HOOKS *
   *             *
   * * * * * * * */

  // Key-value pairs of fieldId + current value

  const [fieldValues, setFieldValues] = useState(initFieldValues);

  // Id of currently focused field
  const [currentFocusId, setCurrentFocusId] = useState<string>('');

  // Flat array of all form rules

  const formRules = useMemo(() => fields?.map((f) => f.rules).flat(), [fields]);

  // Return from rule engine
  // ruleKeys is array of all fieldIds that rely on a rule
  // ruleMap is key-value pairs of fieldId + canShow (boolean)
  const { ruleMap, ruleKeys } = useFormRules({ fieldValues, formRules });

  // Get required field Ids and submissions based on current submissions + form
  const { completePercent, requiredSubmissions, requiredFieldIds } =
    useMNEQuestionsTotal({ submissions, formId, formIds, questionType });

  /* * * * * *
   *         *
   * HELPERS *
   *         *
   * * * * * */

  // Use requiredFieldIds to get fieldValues only for required fields
  const requiredFieldValues = useMemo(
    () =>
      Object.entries(fieldValues).filter(([fieldId, value]) => {
        if (new Set(requiredFieldIds).has(fieldId) && value) {
          return true;
        }
        return false;
      }),
    [fieldValues, requiredFieldIds]
  );

  // Compares initFieldValues w/ current fieldValues to determine if form state has changed
  const checkIsDirty = useCallback(
    (fieldValues: IFieldValues) =>
      Object.entries(initFieldValues).some(
        ([fieldId, value]) => fieldValues[fieldId] !== value
      ),
    [initFieldValues]
  );

  // Get array of all fields that are different from original value
  // Returns as [fieldId, value][]
  const getFieldsToUpdate = useCallback(
    (fieldValues: IFieldValues) => {
      const isDirty = checkIsDirty(fieldValues);
      if (!isDirty) {
        return [];
      }

      return Object.entries(fieldValues).filter(
        ([fieldId, value]) => initFieldValues[fieldId] !== value
      );
    },
    [checkIsDirty, initFieldValues]
  );

  const { updateSubmissions, updateSubmissionsError } = useUpdateSubmissions({
    estimateId,
    locationId,
    fields,
  });

  const { copySubmissions, copySubmissionsError } = useCopySubmissions({
    estimateId,
  });

  const { postCopyCloneHistory } = usePostCopyCloneHistory();

  const { deleteSubmissions } = useDeleteSubmissions({
    estimateId,
    locationId,
  });

  const { updateLocation } = useUpdateLocation(estimateId);

  const { updateMultipleLocations } = usePostLocations(estimateId);

  const getApiLocationId = useCallback(
    () => (locationId === 'general' ? null : locationId),
    [locationId]
  );

  const isFirewallIncludedWithOrderFieldId = fields.find(
    (f) => f.inputName === 'isFirewallIncluded'
  )?.id;

  const findCurrentSubmission = useCallback(
    (submissions: any[] | undefined, fieldId: string) => {
      return submissions?.find(
        (s: { fieldId: any; locationId: any }) =>
          s.fieldId === fieldId && s.locationId === currentLocation?.id
      );
    },
    [currentLocation]
  );

  var isEnableGenSecComplete = useCallback(
    (
      submissions: any[] | undefined,
      allSubmissions: any[] | undefined,
      wpnRequiredCLFieldId: string,
      idpVendorCLFieldId: string,
      idpVendorUrlCLFieldId: string
    ) => {
      // Retrieve necessary values using findCurrentSubmission
      const wpnRequired = findCurrentSubmission(
        submissions,
        wpnRequiredCLFieldId
      );
      const idpVendor = findCurrentSubmission(
        allSubmissions,
        idpVendorCLFieldId
      );
      const currentIdpVendor = findCurrentSubmission(
        submissions,
        idpVendorCLFieldId
      );
      const idpVendorUrl = findCurrentSubmission(
        allSubmissions,
        idpVendorUrlCLFieldId
      );
      const currentIdpVendorUrl = findCurrentSubmission(
        submissions,
        idpVendorUrlCLFieldId
      );

      var generalSecCompCheck: Boolean =
        (wpnRequired?.answer === 'Yes' && idpVendor === undefined) ||
        (wpnRequired?.answer === '' &&
          idpVendor?.answer === 'Other - Requires ICB approval' &&
          (idpVendorUrl === undefined || idpVendorUrl === '')) ||
        (currentIdpVendor?.answer === 'Other - Requires ICB approval' &&
          (idpVendorUrl === undefined || idpVendorUrl === '')) ||
        (idpVendor?.answer === 'Other - Requires ICB approval' &&
          currentIdpVendorUrl?.answer === '');

      // Return the result
      return generalSecCompCheck;
    },
    [findCurrentSubmission]
  );

  // Used to disable fields at location level
  // Currently being used only for dropdowns ('select')
  const fieldsToBeDisabled = useCallback(() => {
    const disableFields =
      allSubmissions
        ?.filter((s) => s.locationId === locationId && s.answer)
        .reduce((acc: string[], sub) => {
          const f = fieldConfig[sub.fieldId];
          if (f && f.fieldsToBeDisabled) {
            acc.push(...f.fieldsToBeDisabled);
          }
          return acc;
        }, []) || [];

    if (
      isEnableGenSecComplete(
        submissions,
        allSubmissions,
        wpnRequiredCLFieldId,
        idpVendorCLFieldId,
        idpVendorUrlCLFieldId
      )
    ) {
      disableFields.push(generalSectionCompleteCLFieldId);
    }

    const noOfBeds = allSubmissions?.find(
      (s) => s.fieldId === noOfBedsCLFieldId
    )?.answer;
    const noOfRooms = allSubmissions?.find(
      (s) => s.fieldId === noOfRoomsCLFieldId
    )?.answer;

    if (
      !(noOfBeds && Number(noOfBeds) > 0 && noOfRooms && Number(noOfRooms) > 0)
    ) {
      disableFields.push(coverageSectionCompleteCLFieldId);
    }
    return disableFields;
  }, [allSubmissions, locationId, isEnableGenSecComplete, submissions]);

  const findSubmissionAnswer = useCallback(
    (submissions: any[], fieldId: any, answer: string) => {
      return submissions.some(
        (s: { fieldId: any; answer: any }) =>
          s.fieldId === fieldId && s.answer === answer
      );
    },
    []
  );

  const resetSubmissions = (
    fieldConfigData: {
      fieldsToBeDisabled: string[];
      fieldsToBeEnabled: string[];
      isGeneralField?: boolean | undefined;
    },
    locationId: string,
    allSubmissions: any[] | undefined
  ) => {
    if (!fieldConfigData.fieldsToBeDisabled.length) return [];

    return (
      allSubmissions?.filter(
        (s: { locationId: null; fieldId: any; answer: any }) =>
          (fieldConfigData.isGeneralField
            ? s.locationId === null
            : s.locationId === locationId) &&
          fieldConfigData.fieldsToBeDisabled.includes(s.fieldId) &&
          s.answer
      ) || []
    );
  };

  const updateLocationBasedOnFirewall = useCallback(
    (
      submissions: Partial<ISubmission>[],
      isFirewallIncludedWithOrderFieldId: string | undefined,
      currentLocation: ILocation | undefined
    ) => {
      if (isFirewallIncludedWithOrderFieldId && currentLocation) {
        const isFirewallIncludedWithOrder = findSubmissionAnswer(
          submissions,
          isFirewallIncludedWithOrderFieldId,
          'Yes, Fortinet firewall'
        );
        if (currentLocation.selectedSolution && isFirewallIncludedWithOrder) {
          updateLocation({ ...currentLocation, selectedSolution: '' });
        }
      }
    },
    [updateLocation, findSubmissionAnswer]
  );

  const updateGeneralSectionData = useCallback(
    (
      submissions: any[],
      generalSectionData: ISubmission | undefined,
      hospitality: Partial<ISubmission> | undefined,
      regularMNE: Partial<ISubmission> | undefined,
      campusLiving: Partial<ISubmission> | undefined,
      currentFocusId: string,
      currentSelectId: string | undefined
    ) => {
      if (
        ((hospitality?.answer !== 'Yes' &&
          (currentFocusId === hospitality?.fieldId ||
            currentSelectId === hospitality?.fieldId)) ||
          (regularMNE?.answer !== 'Yes' &&
            (currentFocusId === regularMNE?.fieldId ||
              currentSelectId === regularMNE?.fieldId)) ||
          (campusLiving?.answer !== 'Yes' &&
            (currentFocusId === campusLiving?.fieldId ||
              currentSelectId === campusLiving?.fieldId))) &&
        generalSectionData?.answer === 'Yes'
      ) {
        generalSectionData.answer = '';
        const filteredSubmissions = submissions.filter(
          (item: { fieldId: any }) =>
            item.fieldId !== generalSectionData.fieldId
        );
        return [...filteredSubmissions, generalSectionData];
      }
      return submissions;
    },
    []
  );

  const updateGeneralSectionComplete = useCallback(
    (
      submissions: any[],
      currentSelectId: string | undefined,
      generalSectionComplete: ISubmission | undefined
    ) => {
      if (
        !currentSelectId ||
        ![
          wpnRequiredCLFieldId,
          idpVendorCLFieldId,
          idpVendorUrlCLFieldId,
        ].includes(currentSelectId)
      ) {
        return submissions;
      }
      if (
        isEnableGenSecComplete(
          submissions,
          allSubmissions,
          wpnRequiredCLFieldId,
          idpVendorCLFieldId,
          idpVendorUrlCLFieldId
        ) &&
        generalSectionComplete
      ) {
        generalSectionComplete.answer = '';
        const filteredSubmissions = submissions.filter(
          (item: { fieldId: any }) =>
            item.fieldId !== generalSectionComplete?.fieldId
        );
        return [...filteredSubmissions, generalSectionComplete];
      }

      return submissions;
    },
    [allSubmissions, isEnableGenSecComplete]
  );

  const isAutobuildCheck = useCallback(
    (
      hospitality: Partial<ISubmission> | undefined,
      currentSelectId: string | undefined
    ) => {
      return (
        hospitality?.answer === 'Yes' &&
        (currentFocusId === hospitality.fieldId ||
          currentSelectId === hospitality.fieldId)
      );
    },
    [currentFocusId]
  );

  const isNonAutobuildCheck = useCallback(
    (
      hospitality: Partial<ISubmission> | undefined,
      currentSelectId: string | undefined
    ) => {
      return (
        hospitality?.answer === '' &&
        (currentFocusId === hospitality.fieldId ||
          currentSelectId === hospitality.fieldId)
      );
    },
    [currentFocusId]
  );

  const updateCoverageSectionComplete = useCallback(
    (
      submissions: any[],
      currentSelectId: string | undefined,
      coverageSectionComplete: ISubmission | undefined
    ) => {
      const isRelevantField = (fieldId: string) =>
        [noOfBedsCLFieldId, noOfRoomsCLFieldId].includes(fieldId);

      if (!currentSelectId || !isRelevantField(currentSelectId)) {
        return submissions;
      }
      let noOfBeds, noOfRooms;
      let disableCoverageSectionComplete = true;

      if (currentSelectId === noOfBedsCLFieldId) {
        noOfBeds = findCurrentSubmission(
          submissions,
          noOfBedsCLFieldId
        )?.answer;
        noOfRooms = findCurrentSubmission(
          allSubmissions,
          noOfRoomsCLFieldId
        )?.answer;
      } else if (currentSelectId === noOfRoomsCLFieldId) {
        noOfRooms = findCurrentSubmission(
          submissions,
          noOfRoomsCLFieldId
        )?.answer;
        noOfBeds = findCurrentSubmission(
          allSubmissions,
          noOfBedsCLFieldId
        )?.answer;
      }

      if (
        noOfBeds &&
        Number(noOfBeds) > 0 &&
        noOfRooms &&
        Number(noOfRooms) > 0
      ) {
        disableCoverageSectionComplete = false;
      }

      if (
        disableCoverageSectionComplete &&
        coverageSectionComplete?.answer === 'Yes'
      ) {
        coverageSectionComplete.answer = '';
        const filteredSubmissions = submissions?.filter(
          (item) => item.fieldId !== coverageSectionComplete.fieldId
        );

        return [...filteredSubmissions, coverageSectionComplete];
      }

      return submissions;
    },
    [allSubmissions, findCurrentSubmission]
  );

  const updateLocationsBasedOnHospitality = useCallback(
    (
      hospitality: Partial<ISubmission> | undefined,
      currentSelectId: string | undefined,
      currentEstimate: IEstimate | undefined,
      campusLiving: Partial<ISubmission> | undefined
    ) => {
      if (
        (isAutobuildCheck(hospitality, currentSelectId) ||
          isAutobuildCheck(campusLiving, currentSelectId)) &&
        currentEstimate?.locations
      ) {
        const allLocations = currentEstimate.locations.map((l: any) => ({
          ...l,
          isAutobuild: false,
        }));
        updateMultipleLocations(allLocations);
        return true;
      }

      return false;
    },
    [updateMultipleLocations, isAutobuildCheck]
  );

  const updateLocationsBasedOnRegularMNE = useCallback(
    (currentEstimate: IEstimate | undefined, allFields: any[] | undefined) => {
      if (currentEstimate?.locations) {
        const allLocations = currentEstimate.locations.map((l) => {
          const hasNoAutobuildOption = currentEstimate.submissions?.some(
            (s) =>
              s.locationId === l.id &&
              s.answer &&
              allFields?.some(
                (field) =>
                  field.id === s.fieldId &&
                  field.noAutobuildOptions?.some((autoBuild: string) =>
                    s.answer?.includes(autoBuild)
                  )
              )
          );
          return { ...l, isAutobuild: !hasNoAutobuildOption };
        });
        updateMultipleLocations(allLocations);
        return true;
      }
      return false;
    },
    [updateMultipleLocations]
  );

  const saveSubmissions = useCallback(
    (submissions: Partial<ISubmission>[]) => {
      if (!submissions.length) return;

      const currentSelectId = submissions[0]?.fieldId;

      updateLocationBasedOnFirewall(
        submissions,
        isFirewallIncludedWithOrderFieldId,
        currentLocation
      );

      const fieldConfigData = fieldConfig[submissions[0]?.fieldId!];
      if (fieldConfigData && locationId) {
        const submissionsToBeReseted = resetSubmissions(
          fieldConfigData,
          locationId,
          allSubmissions
        );
        if (submissionsToBeReseted.length) {
          updateSubmissions(
            submissionsToBeReseted.map((s: any) => ({ ...s, answer: '' }))
          );
        }
      }

      const hospitality = submissions.find((p) => p.fieldId === hospitalityId);
      const regularMNE = submissions.find((p) => p.fieldId === regularMNEId);
      const campusLiving = submissions?.find(
        (s) => s.fieldId === campusLivingFieldId
      );

      const generalSectionData = allSubmissions?.find(
        (p) => p.fieldId === generalSectionId
      );
      const coverageSectionComplete = allSubmissions?.find(
        (s) => s.fieldId === coverageSectionCompleteCLFieldId
      );
      const generalSectionComplete = allSubmissions?.find(
        (s) =>
          s.fieldId === generalSectionCompleteCLFieldId &&
          s.locationId === currentLocation?.id
      );

      if (
        !updateLocationsBasedOnHospitality(
          hospitality,
          currentSelectId,
          currentEstimate,
          campusLiving
        )
      ) {
        if (
          isNonAutobuildCheck(hospitality, currentSelectId) ||
          isNonAutobuildCheck(campusLiving, currentSelectId) ||
          (regularMNE?.answer !== '' &&
            (currentFocusId === regularMNE?.fieldId ||
              currentSelectId === regularMNE?.fieldId))
        )
          updateLocationsBasedOnRegularMNE(currentEstimate, allFields);
      }

      submissions = updateGeneralSectionData(
        submissions,
        generalSectionData,
        hospitality,
        regularMNE,
        campusLiving,
        currentFocusId,
        currentSelectId
      );
      submissions = updateGeneralSectionComplete(
        submissions,
        currentSelectId,
        generalSectionComplete
      );
      submissions = updateCoverageSectionComplete(
        submissions,
        currentSelectId,
        coverageSectionComplete
      );

      updateSubmissions(submissions);
    },
    [
      currentLocation,
      currentEstimate,
      updateSubmissions,
      updateCoverageSectionComplete,
      updateGeneralSectionComplete,
      updateGeneralSectionData,
      updateLocationsBasedOnRegularMNE,
      allFields,
      isFirewallIncludedWithOrderFieldId,
      currentFocusId,
      allSubmissions,
      locationId,
      updateLocationsBasedOnHospitality,
      isNonAutobuildCheck,
      updateLocationBasedOnFirewall,
    ]
  );

  // Sort fields by order
  const sortedFields = useMemo(() => {
    return fields.sort((a, b) => a.order - b.order);
  }, [fields]);

  /* * * * * * * * * * * * *
   *                       *
   * INTERACTION HANDLERS  *
   *                       *
   * * * * * * * * * * * * */

  // Set focusId for current field
  const handleFocus = useCallback(
    (fieldId: string) => () => {
      setCurrentFocusId(fieldId);
    },
    []
  );

  const findSubmissionDuplicates = useCallback(
    (config: { submissions: ISubmission[]; fieldValues: IFieldValues }) => {
      const { submissions, fieldValues } = config;
      const fieldEntries = Object.entries(fieldValues);
      const submissionsToDelete = fieldEntries.reduce(
        (acc: ISubmission[], [fieldId]) => {
          const subMatches = submissions
            .filter((sub) => sub.fieldId === fieldId)
            .sort((a, b) => {
              return collatorSort(a.createdAt, b.createdAt);
            });
          const mostRecentSub = subMatches.at(-1);
          acc.push(...subMatches.filter((s) => s.id !== mostRecentSub?.id));
          return acc;
        },
        []
      );
      return submissionsToDelete;
    },
    []
  );

  // Change handler for updating inputs
  const onFieldChange = useCallback(
    (id: string, newValue: ISubmission['answer'], callback?: () => void) => {
      const newFieldValues = { ...fieldValues, [id]: newValue };

      // Check if its Hospitality or Campus Living Form
      const { installNRCId, mneGuestRoomId, guestRoomDesignId } =
        formId === '751f9001-3062-43e8-9782-e18634b55a20'
          ? mneHospitalityCoverageFields
          : mneCampusLivingCoverageFields;

      if (
        newFieldValues[mneGuestRoomId] &&
        newFieldValues[mneGuestRoomId] !== null &&
        newFieldValues[mneGuestRoomId] !== ''
      ) {
        let newVal = '0';
        if (newFieldValues[guestRoomDesignId] === 'Yes') {
          newVal = newFieldValues[mneGuestRoomId] || '0';
        } else {
          newVal = Math.round(
            parseInt(newFieldValues[mneGuestRoomId] || '0') / 2
          ).toString();
        }
        newFieldValues[installNRCId] = newVal;
      } else if (
        newFieldValues[mneGuestRoomId] !== null &&
        newFieldValues[mneGuestRoomId] === '' &&
        newFieldValues[installNRCId] !== null &&
        newFieldValues[installNRCId] !== ''
      ) {
        newFieldValues[installNRCId] = '';
      }
      const fieldsToUpdate = getFieldsToUpdate(newFieldValues);

      const submissionsToSave = fieldsToUpdate.reduce(
        (acc: Partial<ISubmission>[], [fieldId]) => {
          const subMatches = submissions
            .filter((sub) => sub.fieldId === fieldId)
            .sort((a, b) => {
              return collatorSort(a.createdAt, b.createdAt);
            });
          const mostRecentSub = subMatches.at(-1);
          if (mostRecentSub) {
            const newSub: Partial<ISubmission> = {
              ...mostRecentSub,
              formId: undefined,
              answer: newFieldValues[fieldId] ?? null,
              editedBy: userId,
            };
            acc.push(newSub);
          } else {
            acc.push({
              estimateId,
              locationId: getApiLocationId(),
              fieldId,
              answer: newFieldValues[fieldId] ?? null,
              editedBy: userId,
            });
          }
          return acc;
        },
        []
      );

      saveSubmissions(submissionsToSave);

      if (callback) {
        callback();
      }

      trackSelectAction(
        `Submission Change (Field Id: ${id}, Answer: ${newValue})`,
        { opType: 'inputFieldUpdate' }
      );
    },
    [
      fieldValues,
      getFieldsToUpdate,
      saveSubmissions,
      trackSelectAction,
      submissions,
      userId,
      estimateId,
      getApiLocationId,
      formId,
    ]
  );

  const debounceFieldChange = useDebouncedCallback(onFieldChange, 350);

  const onImport = useCallback(
    (importSubmissions: ISubmission[]) => {
      const importedSubmissions = importSubmissions.reduce(
        (acc: Partial<ISubmission>[], { fieldId, answer }) => {
          const sub = submissions.find((sub) => sub.fieldId === fieldId);
          if (sub) {
            const newSub: Partial<ISubmission> = {
              ...sub,
              formId: undefined,
              answer,
              editedBy: userId,
            };
            acc.push(newSub);
          } else {
            acc.push({
              estimateId,
              locationId: getApiLocationId(),
              fieldId,
              answer,
              editedBy: userId,
            });
          }
          return acc;
        },
        []
      );
      const importedIds = new Set(importedSubmissions.map((s) => s.id));
      const removedSubmissions = submissions.reduce(
        (acc: Partial<ISubmission>[], s) => {
          if ((!formId || s.formId === formId) && !importedIds.has(s.id)) {
            const newSub: Partial<ISubmission> = {
              ...s,
              formId: undefined,
              answer: '',
              editedBy: userId,
            };
            acc.push(newSub);
          }
          return acc;
        },
        []
      );
      const updatedSubmissions = [
        ...importedSubmissions,
        ...removedSubmissions,
      ];
      debounceFieldChange.cancel();
      updateSubmissions(updatedSubmissions);
    },
    [
      debounceFieldChange,
      estimateId,
      formId,
      getApiLocationId,
      submissions,
      updateSubmissions,
      userId,
    ]
  );

  const onCopy = useCallback(
    (copy1Submissions: ISubmission[], loctions: ILocation[]) => {
      const sourceFormId = new Set(copy1Submissions.map((s) => s.formId!));

      const copiedSubmissions = copy1Submissions.reduce(
        (acc: Partial<ISubmission>[], { fieldId, answer }) => {
          loctions.forEach((l) => {
            const sub = currentEstimate?.submissions!.find(
              (sub) => sub.fieldId === fieldId && sub.locationId === l.id
            );
            if (sub) {
              const newSub: Partial<ISubmission> = {
                ...sub,
                formId: undefined,
                answer,
                editedBy: userId,
              };
              acc.push(newSub);
            } else {
              acc.push({
                estimateId,
                locationId: l.id,
                fieldId,
                answer,
                editedBy: userId,
              });
            }
          });

          return acc;
        },
        []
      );
      const importedIds = new Set(copiedSubmissions.map((s) => s.id));
      const removedSubmissions = currentEstimate?.submissions!.reduce(
        (acc: Partial<ISubmission>[], s) => {
          if (
            !importedIds.has(s.id) &&
            s.formId &&
            sourceFormId.has(s.formId) &&
            loctions.find((l) => l.id === s.locationId)
          ) {
            const newSub: Partial<ISubmission> = {
              ...s,
              formId: undefined,
              answer: '',
              editedBy: userId,
            };
            acc.push(newSub);
          }
          return acc;
        },
        []
      );
      const updatedSubmissions = [...copiedSubmissions, ...removedSubmissions!];
      debounceFieldChange.cancel();

      const maxChunkSize = 2500;
      let index = 0;

      //breaking down updatedSubmissions into multiple chunks
      //to avoid PAYLOAD TOO LARGE ERROR
      while (index < updatedSubmissions.length) {
        const nextChunkSize = updatedSubmissions.length - index;
        const currentChunkSize = Math.min(nextChunkSize, maxChunkSize);

        const chunk = updatedSubmissions.slice(index, index + currentChunkSize);
        copySubmissions(chunk);

        index += currentChunkSize;
      }

      if (
        currentLocation?.selectedSolution ||
        currentLocation?.mnePaymentOption ||
        currentLocation?.enePaymentOption
      ) {
        let locToUpdate = loctions as ILocation[];
        let isLocationUpdate = false;
        if (currentLocation?.selectedSolution) {
          isLocationUpdate = true;
          locToUpdate = locToUpdate.map((l) => {
            return { ...l, selectedSolution: currentLocation.selectedSolution };
          });
        }
        if (currentLocation?.mnePaymentOption && questionType === MNEFORMS) {
          isLocationUpdate = true;
          locToUpdate = locToUpdate.map((l) => {
            return {
              ...l,
              mnePaymentOption: currentLocation.mnePaymentOption,
            };
          });
        }
        if (currentLocation.enePaymentOption && questionType === ENEFORMS) {
          isLocationUpdate = true;
          locToUpdate = locToUpdate.map((l) => {
            return {
              ...l,
              enePaymentOption: currentLocation.enePaymentOption,
            };
          });
        }

        isLocationUpdate && updateMultipleLocations(locToUpdate);
      }

      const copyHistoryEntries: IPostCopyCloneHistory[] = loctions.map((l) => {
        return {
          sourceEstimateId: currentEstimate?.id!,
          sourceLocationId: currentLocation?.id!,
          targetEstimateId: l.estimateId,
          targetLocationId: l.id,
          formIds: Array.from(sourceFormId.values()),
          actionType: 'COPY',
          triggeredBy: userId,
        };
      });
      postCopyCloneHistory(copyHistoryEntries);
    },
    [
      debounceFieldChange,
      estimateId,
      copySubmissions,
      userId,
      currentEstimate,
      currentLocation,
      updateMultipleLocations,
      postCopyCloneHistory,
      questionType,
    ]
  );

  const generalSectionCheck = useMemo(() => {
    if (
      allSubmissions?.find((s) => s.fieldId === hospitalityId)?.answer ===
        'Yes' ||
      allSubmissions?.find((s) => s.fieldId === regularMNEId)?.answer ===
        'Yes' ||
      allSubmissions?.find((s) => s.fieldId === campusLivingFieldId)?.answer ===
        'Yes'
    ) {
      return false;
    }
    return true;
  }, [allSubmissions]);

  /* * * * * * * * * *
   *                 *
   * RENDER METHODS  *
   *                 *
   * * * * * * * * * */

  // Parse html string into field label
  const renderLabel = useCallback((field: IFieldInput) => {
    // "defaultValue" string prevents incomplete marker from temporarily rendering
    const {
      label,
      tooltip,
      subtext,
      required,
      value = 'defaultValue',
      inputName,
      id,
      helpText,
    } = field;
    const isComplete = !!value || !required || inputName === 'Notes';
    const nonHospitalityEndpoints = inputName === 'nonHospitalityEndPoints';

    return (
      <div
        className="mne-field__label"
        style={{
          flexDirection:
            inputName === 'installNRCstoadd' ||
            'nonHospitalityEndPoints' ||
            id === advancedEdgeFeaturesFieldId
              ? 'row'
              : 'column',
        }}
      >
        <div
          className="mne-field__label-question"
          style={nonHospitalityEndpoints ? { marginBottom: '1.2rem' } : {}}
        >
          {labelsToShowAsNotes.includes(field.id) ? (
            <KiteAlert
              id={id}
              level="page"
              type="caution"
              description={label}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: label }}></span>
          )}

          {!isComplete && <div className="mne-field__incomplete-marker"></div>}
        </div>
        {tooltip && id !== advancedEdgeFeaturesFieldId && (
          <KiteTooltip className="mne-field__tooltip">{tooltip}</KiteTooltip>
        )}

        {helpText && id === advancedEdgeFeaturesFieldId && (
          <KiteTooltip className="mne-field__tooltip advance-edge">
            <div
              style={{
                maxHeight: '250px',
                maxWidth: '370px',
                overflow: 'auto',
              }}
            >
              <span
                className="edge-feature-tooltip"
                dangerouslySetInnerHTML={{
                  __html: helpText ? helpText : '',
                }}
              ></span>
            </div>
          </KiteTooltip>
        )}

        {subtext && <span className="mne-field__subtext">{subtext}</span>}
      </div>
    );
  }, []);

  // Map input type to component
  // `field` argument requires mapping submission value to field ojbect (IFieldInput)
  const renderInput = useCallback(
    (field: IFieldInput, isDisabled?: boolean) => {
      switch (field.inputType) {
        case 'date':
          return (
            <UCQDate
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              dateType="date"
            />
          );
        case 'time':
          return (
            <UCQDate
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              dateType="time"
            />
          );
        case 'file':
          return (
            <UCQUpload
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              uploadType="tco"
            />
          );
        case 'select':
          return (
            <UCQSelect
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              isDisabled={fieldsToBeDisabled().includes(field.id)}
            />
          );
        case 'check-box':
          return (
            <MNECheckbox
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              isrequired={field.required}
              isGeneralDisabled={generalSectionCheck}
              isDisabled={fieldsToBeDisabled().includes(field.id)}
            />
          );

        case 'text':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text"
            />
          );
        case 'text-area':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text-area"
            />
          );
        case 'select-quantity':
          return (
            <MNESelectQuantity
              fieldInput={field}
              onFieldChange={debounceFieldChange}
            />
          );
        case 'multiple-selection':
          return (
            <MNEMultipleSelection
              fieldInput={field}
              onFieldChange={debounceFieldChange}
            />
          );
        case 'select-general-edge':
          return (
            <MNESelectEdge
              fieldInput={field}
              onFieldChange={debounceFieldChange}
            />
          );
        case 'select-general-switch':
          return (
            <MNESelectSwitch
              fieldInput={field}
              onFieldChange={debounceFieldChange}
            />
          );

        case 'number-text':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="number"
            />
          );
        case 'image':
          return (
            <UCQUpload
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              uploadType="image"
            />
          );

        case 'text-copy':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="text"
              isCopy={true}
            />
          );
        case 'number-copy':
          return (
            <UCQBasicInput
              fieldInput={field}
              onFieldChange={debounceFieldChange}
              inputType="number"
              isCopy={true}
            />
          );
        default:
          return null;
      }
    },
    [debounceFieldChange, generalSectionCheck, fieldsToBeDisabled]
  );

  // Generate array of functional form field components
  const fieldComponents = useMemo(
    () =>
      sortedFields.map((field) => {
        const { header, label, order, id, inputType, inputName } = field;

        if (inputType === 'distro') {
          return (
            <UCQDesignFlowLayout
              key={id}
              onFieldChange={onFieldChange}
              fieldId={id}
            />
          );
        }

        // If order is not whole integer, it is a sub question & won't display number next to label
        const isSubQuestion = order && order % 1 !== 0;
        const formattedLabel =
          isSubQuestion || order === 100 || noLabelForms.includes(formId!)
            ? label
            : `${!isCpw ? order + '. ' : ''}${label}`;
        const isRule = ruleKeys.includes(id); // Check if field relies on a rule
        const canShow = isRule ? ruleMap[id] : true; // Check field map to get current rule state (true/false)
        const value = fieldValues[id]; // Submission value for field
        const fieldInput = { ...field, label: formattedLabel, value }; // Converted field w/ formatted label and submission value

        return canShow ? (
          <>
            {fieldInput.helpText &&
              fieldInput.id !== advancedEdgeFeaturesFieldId && (
                <div style={{ display: 'grid', gridColumn: '1/-1' }}>
                  <h3>{fieldInput.helpText}</h3>
                </div>
              )}

            <div
              key={id}
              className="mne-field"
              onFocus={handleFocus(id)}
              onBlur={handleFocus('')}
              style={{
                display: 'grid',
                gridTemplateRows: 'auto',
                alignItems: inputType === 'check-box' ? 'start' : 'end',
                gridColumn:
                  inputType === 'select-quantity' ||
                  inputType === 'multiple-selection'
                    ? 'span 2'
                    : inputName === 'Notes' ||
                      inputType === 'select-general-edge' ||
                      inputType === 'select-general-switch' ||
                      inputType === 'field-label' ||
                      header
                    ? '1/-1'
                    : '',
                ...(label === 'Add Phone Drops to Inside Wiring Price' ||
                id === '0faa6355-7a2b-44f8-aca9-78576e78cabf'
                  ? { gridColumnStart: '1' }
                  : {}),
                ...(label === 'SE Provided Ethernet Drops' ||
                id === mneSEEthernetDrop ||
                id === eneSEEthernetDrop
                  ? { display: 'none' }
                  : {}),
              }}
            >
              {header ? (
                <div style={{ display: 'inline-flex' }}>
                  {header && <h3 className="mne-field__header">{header}</h3>}
                  <div style={{ marginLeft: '50px' }}>
                    {renderInput(fieldInput)}
                  </div>
                </div>
              ) : (
                <div className="mne-field-input">
                  {fieldInput.inputType !== 'check-box' &&
                    renderLabel(fieldInput)}

                  {renderInput(fieldInput)}
                </div>
              )}
            </div>
            {fieldInput.inputName === 'MNEVisioTemplate' && (
              <div>
                {' '}
                {React.createElement(
                  'a',
                  {
                    href: `https://chalk.charter.com/pages/viewpage.action?spaceKey=MSIP&title=MSIP+HLD+Templates`,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                  formId === 'c6f45363-a1d6-479c-8559-37830748e515'
                    ? 'Click here for the latest MSIP HLD Template'
                    : 'MSIP HLD Templates'
                )}
              </div>
            )}
          </>
        ) : null;
      }),
    [
      sortedFields,
      isCpw,
      ruleKeys,
      ruleMap,
      fieldValues,
      formId,
      handleFocus,
      renderLabel,
      renderInput,
      onFieldChange,
    ]
  );

  /* * * * * * *
   *           *
   *  EFFECTS  *
   *           *
   * * * * * * */

  // Reset field values if initFieldValues changes (new form, submission mutation, etc.)
  useEffect(() => {
    if (!debounceFieldChange.isPending() && checkIsDirty(fieldValues)) {
      const subsToDelete = findSubmissionDuplicates({
        submissions,
        fieldValues: initFieldValues,
      });
      if (subsToDelete.length) {
        deleteSubmissions(subsToDelete.map((s) => s.id));
      }
      setFieldValues(() => initFieldValues);
    }
  }, [
    checkIsDirty,
    debounceFieldChange,
    deleteSubmissions,
    fieldValues,
    findSubmissionDuplicates,
    initFieldValues,
    submissions,
  ]);

  return {
    completePercent,
    currentFocusId,
    fieldComponents,
    fieldValues,
    fieldsToUpdate: getFieldsToUpdate(fieldValues),
    isDirty: checkIsDirty(fieldValues),
    requiredFieldIds,
    requiredFieldValues,
    requiredSubmissions,
    onImport,
    onCopy,
    updateSubmissionsError,
    copySubmissionsError,
    generalSectionCheck,
  };
};
