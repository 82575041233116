import {
  INewEstimate,
  INewLocation,
  INewScenario,
  ISalesforceLocation,
  TOpportunityStatus,
  IEstimateInfo,
} from 'types';
import { parseSfAddressData } from './parseSfAddressData';

interface IEstimateObj extends IEstimateInfo {
  userId: string;
  userEmail: string;
  salesforceLocations?: ISalesforceLocation[];
  oppStatus: TOpportunityStatus;
  ownerPID: string;
  serviceLocationRegion: string;
}

export const createEstimate = ({
  oppId,
  estimateName,
  customerName,
  oppStatus,
  userId,
  userEmail,
  serviceLocationRegion,
  ownerPID,
  salesforceLocations = [],
}: IEstimateObj) => {
  const allLocations: INewLocation[] = [];

  if (salesforceLocations.length) {
    salesforceLocations.forEach((l: ISalesforceLocation, index: number) => {
      const { street, city, state, postalCode } = parseSfAddressData(
        l.shippingAddress
      );

      const locationName = l.serviceLocationName || `location__${index}`;
      allLocations.push({
        '#id': `location__${index}`,
        isEzPass: false,
        createdBy: userId,
        name: locationName,
        address1: street,
        address2: '',
        city,
        state,
        zipcode: postalCode,
        isAutobuild: true, // default
        sfLocationId: l.serviceLocationId,
        sfBillingSystem: l.billingSystem,
        sfServiceAreaNumber: l.serviceAreaNumber,
      });
    });
  }

  const scenarios: INewScenario[] = [
    {
      createdBy: userId,
      name: 'Scenario 1',
      primary: true,
      order: 0,
      term: '36',
      locations: allLocations.map((loc: INewLocation) => ({
        '#ref': loc['#id'],
      })),
    },
  ];

  const estimate: INewEstimate = {
    name: estimateName,
    customerName,
    status: oppStatus,
    createdBy: userId,
    creatorEmail: userEmail,
    sfOpportunityId: oppId,
    archived: false, // default
    locations: allLocations,
    scenarios: scenarios,
    serviceLocationRegion: serviceLocationRegion,
    salesTeam: { [ownerPID]: 'Owner' },
  };

  return estimate;
};
