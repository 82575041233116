// Packages
import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

// Redux

// Components
import { KiteButton, KiteModal } from '@kite/react-kite';

// Hooks

// Utils

// Types

// Styles
import './SalesBOMModal.scss';
import EnterpriseLogo from '../../images/spectrum_business_logo.svg';
import { useGenerateISWPricing, useQueryData } from 'hooks';
import { useReactToPrint } from 'react-to-print';
import { ISubmission } from 'types';
import { eneSwitchFieldId, formatLocationAddress, mneSwitchFieldId, eneDeviceMountsAbove24FtFieldId, mneDeviceMountsAbove24FtFiedId, eneDeviceMountsFieldId, mneDeviceMountsFieldId, mneEdgeFieldId } from 'utils';

export interface ISalesBOMModalProps {
  formName: string;
  /** Submissions */
  formSubmissions: ISubmission[];
  /** Button/Modal should be disabled */
  disabled?: boolean;
}
/** Sales BOM modal with button */
const SalesBOMModal = ({
  formName = '',
  formSubmissions = [],
  disabled = false,
}: ISalesBOMModalProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const label = 'Sales BOM';
  const [salesBOMModalOpen, setSalesBOMModalOpen] = useState(false);
  const mainContentRef = useRef<HTMLDivElement | null>(null);
  const { currentLocation } = useQueryData();

  const { numberofSEdrops } = useGenerateISWPricing(formSubmissions, formName === 'MNE Discovery Questions/Notes' ? 'MNE' : 'ENE')

  const deviceMount =
    formName === 'MNE Discovery Questions/Notes'
      ? Boolean(
        formSubmissions.find(
          (s) => s.fieldId === mneDeviceMountsFieldId
        )?.answer ||
        formSubmissions.find(
          (s) => s.fieldId === mneDeviceMountsAbove24FtFiedId
        )?.answer
      )
      : formName === 'ENE Discovery Questions/Notes'
        ? Boolean(
          formSubmissions.find(
            (s) => s.fieldId === eneDeviceMountsFieldId
          )?.answer ||
          formSubmissions.find(
            (s) => s.fieldId === eneDeviceMountsAbove24FtFieldId
          )?.answer
        )
        : false;

  const countTotalDevice = ((subs: any) => {
    return subs.reduce((acc: number, { answer }: any) => {
      if (answer) {
        const answers = JSON.parse(answer);
        return answers.length;
      }
      return 0;
    }, 0);
  });

  const switchDevicesCount = ((subs: any) => {
    return subs.reduce((acc: number, { answer }: any) => {
      if (answer) {
        const answers = JSON.parse(answer);
        answers.forEach((a: string[]) => {
          if (a[1] && a[0]) {
            acc += Number(a[1]);
          }
        });
      }
      return acc;
    }, 0);
  });

  const edgeSwitchDevicesCount =
    formName === 'MNE Discovery Questions/Notes'
      ? (countTotalDevice(
        formSubmissions.filter(
          (s) => s.fieldId === mneEdgeFieldId))
        + switchDevicesCount(formSubmissions.filter(
          (s) => s.fieldId === mneSwitchFieldId
        ))
      )
      : formName === 'ENE Discovery Questions/Notes'
        ? switchDevicesCount(
          formSubmissions.filter(
            (s) => s.fieldId === eneSwitchFieldId
          )
        )
        : 0;

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================

  // =============================================
  // Interaction Handlers
  // =============================================

  const toggleSalesBOMModal = useCallback(() => {
    setSalesBOMModalOpen(!salesBOMModalOpen);
  }, [salesBOMModalOpen]);

  const handlePrint = useReactToPrint({
    content: () => mainContentRef.current,
  });

  // =============================================
  // Render Methods
  // =============================================

  // =============================================
  // Effects
  // =============================================

  // =============================================
  // Return
  // =============================================
  return (
    <div className="sales-bom-modal">
      <KiteButton
        className={classNames({
          'sales-bom-modal__toggle': true,
          'sales-bom-modal__toggle--disabled': disabled,
        })}
        type="primary"
        maxWidth="min-content"
        minWidth="min-content"
        onClick={toggleSalesBOMModal}
        disabled={disabled}
      >
        {label}
      </KiteButton>
      <KiteModal
        canShow={salesBOMModalOpen}
        onHide={toggleSalesBOMModal}
        title={label}
        ctaCopy="Download"
        ctaAction={() => handlePrint()}
        secondaryCtaCopy="Cancel"
        secondaryCtaAction={toggleSalesBOMModal}
      >
        <div className="sales-bom-pdf" ref={mainContentRef}>
          <div className="sales-bom-modal__header">
            <img src={EnterpriseLogo} alt="Spectrum Enterprise logo" />
            <h2>Inside wiring summary for:</h2>
            <p>{currentLocation && formatLocationAddress(currentLocation)}</p>
          </div>
          <div className="sales-bom-modal__content">
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Field Services</td>
                  <td>Site Survey</td>
                  <td className="center-align">1</td>
                </tr>
                {numberofSEdrops > 0 && (
                  <tr>
                    <td>Inside Wiring</td>
                    <td>Standard Ethernet Drops</td>
                    <td>{numberofSEdrops}</td>
                  </tr>
                )}
                {numberofSEdrops > 0 && (
                  <tr>
                    <td></td>
                    <td>&nbsp;&nbsp;CAT 6 Enternet Cable</td>
                    <td>up to {numberofSEdrops * 300} Ft.</td>
                  </tr>
                )}
                {numberofSEdrops > 0 && (
                  <tr>
                    <td></td>
                    <td>&nbsp;&nbsp;Professional Drop Installation</td>
                    <td>{numberofSEdrops}</td>
                  </tr>
                )}
                {numberofSEdrops > 0 && (
                  <tr>
                    <td></td>
                    <td>&nbsp;&nbsp;Ethernet Connectors</td>
                    <td>{numberofSEdrops * 2}</td>
                  </tr>
                )}
                {edgeSwitchDevicesCount > 0 && (
                  <tr>
                    <td></td>
                    <td>Ethernet Jumper(s)</td>
                    <td>{edgeSwitchDevicesCount}</td>
                  </tr>
                )}
                {deviceMount && (
                  <tr>
                    <td>Additional Equipment</td>
                    <td>Scissor Lift Rental</td>
                    <td>1</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </KiteModal>
    </div>
  );
};

export default SalesBOMModal;