// External Packages
import React, { useEffect, useState } from 'react';
import { KiteLoader } from '@kite/react-kite';

// Components
import Routes from '../../../routes/Routes';
import Unauthorized from '../../../pages/Unauthorized/Unauthorized';

// Hooks
import { useAnalytics } from 'hooks';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Utils
import { getAuthorization } from './getAuthorization';
import { storeHeaderLinks } from 'redux/headerLinkDataSlice';

const KeyCloakService = () => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { analytics, trackPageView, trackLoginStart } = useAnalytics();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const [authorization, setAuthorization] = useState(null);

  // =============================================
  // Use Effect
  // =============================================
  useEffect(() => {
    const getData = async () => {
      trackLoginStart('ssoAuth');
      await getAuthorization(setAuthorization, analytics, dispatch);
    };

    analytics && getData();
  }, [analytics, dispatch, trackLoginStart]);

  // =============================================
  // Early Return
  // =============================================
  if (!authorization) {
    return (
      <div className="display-loader">
        <KiteLoader />
      </div>
    );
  } else if (authorization.user) {
    dispatch(
      storeHeaderLinks(
        [
          {
            label: 'Contact Support',
            url: 'https://charter.tfaforms.net/4837678',
          },
          { label: 'Feedback', url: 'feedback' },
        ].concat(
          authorization.isAdmin
            ? [{ label: 'Admin', url: '/admin/product-pricing/data/dfi-speed' }]
            : []
        )
      )
    );

    return (
      <Routes
        userName={authorization.fullName}
        userEmail={authorization.user}
        pId={authorization.pId}
        analytics={analytics}
        isPartnerLinkUser={false}
        isAdmin={authorization.isAdmin}
      />
    );
  }

  // =============================================
  // Return
  // =============================================
  return <Unauthorized trackPageView={trackPageView} />;
};

export default KeyCloakService;
