// Packages
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

// Components
import { KiteButton, KiteSelect, KiteTabs } from '@kite/react-kite';
import { EditableTable } from 'components';

// Hooks
import { useQueryData, useUpdateProductFamily } from 'hooks';

// Utils
import {
  generateTableData,
  generateUniqueNrcTableData,
  generateNrcTableData,
  TablePriceType,
} from 'utils';

// Types
import {
  IProductFamily,
  TPriceType,
  IPrice,
  IProduct,
  TTermLengthMonths,
  TNrcTableData,
  TPricingTableData,
} from 'types';

// Styles
import './AdminProductTable.scss';
import { usePostAdminHistories } from 'hooks/apiHooks/adminHistories';

interface IAdminProductTableProps {
  productFamily: IProductFamily;
  handleToast: (config: { type: string; title: string; text: string }) => void;
}

export interface IAdminTableData {
  pricing: TPricingTableData;
  nrc: TNrcTableData;
  uniqueNrc: IProduct[];
}

const AdminProductTable = ({
  productFamily,
  handleToast,
}: IAdminProductTableProps) => {
  // =============================================
  // State/Refs/Hooks
  // =============================================
  const { userId } = useQueryData();
  const [isEditing, setIsEditing] = useState(false);
  const [priceType, setPriceType] = useState<TPriceType>('rate');
  const [selectedRateCard, setSelectedRateCard] = useState('');

  const { postAdminHistory } = usePostAdminHistories();

  const initializeTableData = useCallback(
    (productFamily: IProductFamily, priceType: TPriceType) => {
      return {
        pricing: generateTableData(productFamily.products, priceType),
        nrc: generateNrcTableData(productFamily),
        uniqueNrc: generateUniqueNrcTableData(productFamily),
      };
    },
    []
  );

  const originalTableData = useMemo(
    () => initializeTableData(productFamily, priceType),
    [initializeTableData, priceType, productFamily]
  );

  // Table Data
  const [tableData, setTableData] = useState(originalTableData);

  // Store price changes locally
  const [pricesToUpdate, setPricesToUpdate] = useState<IPrice[]>([]);
  const [productsToUpdate, setProductsToUpdate] = useState<IProduct[]>([]);
  const [nrcToUpdate, setNrcToUpdate] = useState({
    list: productFamily.rateNrc,
    discount: productFamily.discountNrc,
  });

  const [isFamilyNrcUpdated, setIsFamilyNrcUpdated] = useState(false);

  const onUpdateSuccess = useCallback(() => {
    handleToast({
      type: 'confirm',
      title: 'Success',
      text: 'Changes Saved.',
    });
  }, [handleToast]);

  const {
    updateProductFamily,
    updateProductFamilyLoading,
    updateProductFamilyError,
  } = useUpdateProductFamily(onUpdateSuccess);

  // =============================================
  // Helpers (Memo, CB, vars)
  // =============================================
  const familyRateCards = useMemo(() => {
    return productFamily.products[0].prices
      .reduce((acc: string[], price) => {
        if (price.rateCard && !acc.includes(price.rateCard)) {
          acc.push(price.rateCard);
        }
        return acc;
      }, [])
      .sort((n1, n2) => {
        if (n1.includes('1000+ Seats')) {
          return n2.includes('1000+ Seats w. MNE / ENE') ? -1 : 1;
        }
        if (n2.includes('1000+ Seats')) {
          return n1.includes('1000+ Seats w. MNE / ENE') ? 1 : -1;
        }
        if (n1.includes('500-999 Seats')) {
          return n2.includes('500-999 Seats w. MNE / ENE') ? -1 : 1;
        }
        if (n2.includes('500-999 Seats')) {
          return n1.includes('1000+ Seats') ? 1 : -1;
        }
        if (n1.includes('1-499 Seats')) {
          return n2.includes('1-499 Seats w. MNE / ENE') ? -1 : 1;
        }
        if (n2.includes('1-499 Seats')) {
          return n1.includes('1-499 Seats w. MNE / ENE') ? 1 : -1;
        }
        return 0;
      });
  }, [productFamily.products]);

  const formatInputPrice = useCallback((value: string) => {
    if (!value) {
      return '';
    }
    const [dollars = '0', cents = '0'] = value.split('.');
    return [dollars, cents.slice(0, 2)].join('.');
  }, []);

  const checkIsDirty = useCallback(
    (originalData: IAdminTableData, currentData: IAdminTableData) => {
      const getPricingValues = (pricing: IAdminTableData['pricing']) => {
        const values = Object.values(pricing).flat();
        return values.map((v) => v.price);
      };
      const getNrcValues = (nrc: IAdminTableData['nrc']) => {
        return Object.values(nrc).flat();
      };
      const getUniqueNrcValues = (uniqueNrc: IAdminTableData['uniqueNrc']) => {
        const values = Object.values(uniqueNrc).flat();
        const prices = values.map((v) => [v.rateNrc, v.rateMrc]).flat();
        return prices;
      };
      const original = [
        getPricingValues(originalData.pricing),
        getNrcValues(originalData.nrc),
        getUniqueNrcValues(originalData.uniqueNrc),
      ];
      const current = [
        getPricingValues(currentData.pricing),
        getNrcValues(currentData.nrc),
        getUniqueNrcValues(currentData.uniqueNrc),
      ];

      return !original.every((data, i) =>
        current[i].every((value, j) => {
          const ogValue = data[j];
          if (!ogValue && typeof value !== 'undefined' && isNaN(value)) {
            return true;
          }
          return ogValue === value;
        })
      );
    },
    []
  );

  const generateUpdatedProductFamily = useCallback(
    (
      updatedProducts: IProduct[],
      updatedPrices: IPrice[],
      updatedNrc: { [key: string]: number }
    ) => {
      const { products: originalProducts } = productFamily;
      // For each product in the family, organize price data
      const products = originalProducts.reduce((acc: IProduct[], prod) => {
        const { prices: originalPrices } = prod;
        // Check if there's an updated value for each existing price
        // If yes, use new value; if no, use old value
        const prices = originalPrices.map((price) => {
          const updatedPrice = updatedPrices.find(
            (updatedPrice) => updatedPrice.id === price.id
          );
          if (updatedPrice) {
            return updatedPrice;
          }
          return price;
        });
        // Find product to update
        const targetProduct = updatedProducts.find(
          (updatedProd) => updatedProd.id === prod.id
        );
        // Get new prices for target product
        const addedPrices = updatedPrices.filter(
          (price) =>
            price.id.includes('new-price') && price.productId === prod.id
        );
        const newPrices = [...prices, ...addedPrices];
        const updatedProduct = targetProduct
          ? {
              ...targetProduct,
              prices: newPrices,
            }
          : { ...prod, prices: newPrices };
        acc.push(updatedProduct);

        return acc;
      }, []);

      const adminProductIds = new Set(updatedPrices.map((p) => p.productId));
      updatedProducts.forEach((p) => adminProductIds.add(p.id));

      const adminProductsData =
        Array.from(adminProductIds).map(
          (p) => originalProducts.find((op) => op.id === p)?.name
        ) || [];

      const text = [];

      updatedPrices.length &&
        text.push(
          `Term Pricing - ${
            priceType.charAt(0).toUpperCase() + priceType.slice(1)
          }`
        );

      updatedProducts.length && text.push('Month to Month / NRC Pricing');

      isFamilyNrcUpdated && text.push('Term NRC');

      const adminData = {
        updatedBy: userId,
        activity: `${productFamily.name} (${text.join(', ')})  ${
          adminProductsData.length ? ' - ' + adminProductsData.join(', ') : ''
        }`,
      };

      postAdminHistory(adminData!);

      return {
        ...productFamily,
        products: products,
        rateNrc: updatedNrc.list,
        discountNrc: updatedNrc.discount,
      };
    },
    [productFamily, userId, postAdminHistory, priceType, isFamilyNrcUpdated]
  );

  // =============================================
  // Interaction Handlers
  // =============================================

  const handleProductSave = useCallback(async () => {
    updateProductFamily(
      generateUpdatedProductFamily(
        productsToUpdate,
        pricesToUpdate,
        nrcToUpdate
      )
    );

    setPricesToUpdate([]);
    setProductsToUpdate([]);
    setIsFamilyNrcUpdated(false);
  }, [
    updateProductFamily,
    generateUpdatedProductFamily,
    productsToUpdate,
    pricesToUpdate,
    nrcToUpdate,
  ]);

  const handleProductCancel = useCallback(() => {
    handleToast({
      type: 'caution',
      title: 'Cancelled',
      text: 'All changes reverted',
    });
    setTableData(initializeTableData(productFamily, priceType)); // use init callback instead of original data
    setPricesToUpdate([]);
    setIsEditing(false);
  }, [handleToast, initializeTableData, priceType, productFamily]);

  const handlePriceTypeChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setPriceType(value as TPriceType);
    },
    []
  );

  const handleRateCardChange = useCallback((tab: string) => {
    setSelectedRateCard(tab);
  }, []);

  const onPricingChange = useCallback(
    (productName: string, term: TTermLengthMonths) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const { pricing } = tableData;
        const productPrices = pricing[productName];

        const formattedInput = formatInputPrice(value);
        const price = parseFloat(formattedInput);

        const priceValueIndex = productPrices.findIndex((price) => {
          if (selectedRateCard) {
            return price.term === term && price.rateCard === selectedRateCard;
          }
          return price.term === term;
        });

        const newPrice: IPrice =
          priceValueIndex > -1
            ? {
                ...productPrices[priceValueIndex],
                price,
                updatedBy: userId,
              }
            : {
                id: `new-price${v4()}`,
                createdAt: '', // Don't need real values bc they will be updated
                updatedAt: '', // When product is upserted
                updatedBy: userId,
                productId: productPrices[0].productId,
                rateCard: selectedRateCard,
                term,
                type: priceType,
                price,
              };

        if (priceValueIndex > -1) {
          productPrices.splice(priceValueIndex, 1, newPrice);
        } else {
          productPrices.push(newPrice);
        }

        const newPricing = { ...pricing, [productName]: productPrices };

        setTableData({
          ...tableData,
          pricing: newPricing,
        });
        setPricesToUpdate([
          ...pricesToUpdate.filter((price) => price.id !== newPrice.id),
          newPrice,
        ]);
      },
    [
      formatInputPrice,
      tableData,
      selectedRateCard,
      userId,
      priceType,
      pricesToUpdate,
    ]
  );

  const onNrcChange = useCallback(
    (property: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { nrc } = tableData;
      const { value } = e.target;
      const price = parseFloat(formatInputPrice(value));
      const newNrc = { ...nrc, [property]: price };

      setIsFamilyNrcUpdated(true);

      setTableData({
        ...tableData,
        nrc: newNrc,
      });
      setNrcToUpdate(newNrc);
    },

    [formatInputPrice, tableData, isFamilyNrcUpdated]
  );

  const onUniqueNrcChange = useCallback(
    (id: string, property: keyof IProduct) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const { uniqueNrc } = tableData;
        if (!uniqueNrc) return;
        const { value } = e.target;

        const existingProduct = uniqueNrc.find((p) => p.id === id);

        if (!existingProduct) return;
        const updatedProduct = {
          ...existingProduct,
          [property]: parseFloat(value),
        };
        const newTableData = uniqueNrc.filter((p) => p.id !== id);
        newTableData.push(updatedProduct);
        newTableData.sort((a, b) => a.order - b.order);

        setTableData({
          ...tableData,
          uniqueNrc: newTableData,
        });

        setProductsToUpdate([
          ...productsToUpdate.filter((p) => p.id !== updatedProduct.id),
          updatedProduct,
        ]);
      },
    [tableData, productsToUpdate]
  );

  // =============================================
  // Render Methods
  // =============================================
  const priceSelectionOptions = useMemo(() => {
    return Object.entries(TablePriceType).map(([key, value]) => {
      return (
        <option key={key} value={key}>
          {value}
        </option>
      );
    });
  }, []);

  const controlButtons = useMemo(() => {
    if (isEditing) {
      const disabled = !checkIsDirty(originalTableData, tableData);
      return (
        <>
          <KiteButton type="outline" size="small" onClick={handleProductCancel}>
            Cancel
          </KiteButton>
          <KiteButton
            size="small"
            onClick={handleProductSave}
            className="admin-product-table__button--save"
            loading={updateProductFamilyLoading}
            disabled={disabled}
          >
            Save
          </KiteButton>
        </>
      );
    } else {
      return (
        <KiteButton
          type="standalone-link"
          leftIcon="edit"
          size="small"
          onClick={() => setIsEditing(true)}
          className="admin-product-table__button--edit"
        >
          Edit
        </KiteButton>
      );
    }
  }, [
    isEditing,
    checkIsDirty,
    originalTableData,
    tableData,
    handleProductCancel,
    handleProductSave,
    updateProductFamilyLoading,
  ]);

  // =============================================
  // Effects
  // =============================================
  useEffect(() => {
    setTableData(initializeTableData(productFamily, priceType));
    setIsEditing(false);
  }, [productFamily, priceType, initializeTableData]);

  useEffect(() => {
    setSelectedRateCard(
      familyRateCards.find((rc) => rc === selectedRateCard) ||
        familyRateCards[0] ||
        ''
    );
  }, [familyRateCards, selectedRateCard]);

  useEffect(() => {
    if (!updateProductFamilyLoading && updateProductFamilyError) {
      setIsEditing(false);
    }
  }, [updateProductFamilyError, updateProductFamilyLoading]);

  // =============================================
  // Return
  // =============================================
  return (
    <div className="admin-product-table">
      <div className="admin-product-table__header-wrapper">
        <h3 className="admin-product-table__product-name">
          {productFamily.name}
        </h3>

        <div className="admin-product-table__button-container">
          {controlButtons}
        </div>
      </div>

      <KiteSelect
        id="price type select"
        name="Price Type Select"
        value={priceType}
        onChange={handlePriceTypeChange}
      >
        {priceSelectionOptions}
      </KiteSelect>
      {familyRateCards.length > 1 && (
        <KiteTabs
          className="admin-product-table__tabs"
          tabs={familyRateCards}
          currentTab={selectedRateCard}
          onSelectTab={handleRateCardChange}
        />
      )}
      <EditableTable
        onNrcChange={onNrcChange}
        onPricingChange={onPricingChange}
        onUniqueNrcChange={onUniqueNrcChange}
        rateCard={selectedRateCard}
        tableData={tableData}
        isEditable={isEditing}
      />
    </div>
  );
};

export default AdminProductTable;
